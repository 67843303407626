import Vue from "vue";
import Router from "vue-router";
import store from "./store";

import PageNotFound from './pages/PageNotFound.vue';
import Login from './pages/Login.vue';
import PasswordForgot from './pages/PasswordForgot.vue';
import PasswordReset from './pages/PasswordReset.vue';

import Map from "./pages/Map.vue";
import ObjectsPage from "./pages/Objects.vue";

import WhoWasThere from "./pages/WhoWasThere.vue";
// import PointsPage from "./pages/Points.vue";

import DevicesPage from "./pages/Devices.vue";

import ReportsPage from "./pages/ReportsPage.vue";
import EmsPage from "./pages/EmsPage.vue";
import MileageByDay from "./components/report/MileageByDay.vue";
import TripSheet from "./components/report/TripSheet.vue";
import SmartTemp from "./components/report/SmartTemp.vue";
import OverSpeed from "./components/report/OverSpeed.vue";
import SensorStatus from "./components/report/SensorStatus.vue";
import RequestVideoEvidence from "./components/ems/RequestVideoEvidence.vue";
import EvidenceLibrary from "./components/ems/EvidenceLibrary.vue";
import FuelReport from "./components/report/FuelReport.vue";

Vue.use(Router);
const router = new Router({
    mode: "history",
    linkExactActiveClass: "active",
    routes: [{
            path: '/',
            name: 'login',
            component: Login,
            meta: { authorize: false }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: PasswordForgot,
            meta: { authorize: false }
        },
        {
            path: '/reset-password/:token',
            name: 'reset-password',
            component: PasswordReset,
            meta: { authorize: false }
        },
        {
            path: "/",
            component: Map,
            children: [{
                    path: "objects",
                    name: "objects",
                    component: ObjectsPage,
                    meta: {
                        authorize: true,
                        permission: 'sm.objects.index',
                        next: 'trip-sheet'
                    }
                },
                {
                    path: "who-was-there",
                    name: "who-was-there",
                    component: WhoWasThere,
                    meta: { authorize: true }
                },
                // {
                //     path: "points",
                //     name: "points",
                //     component: PointsPage,
                //     meta: {
                //         authorize: true,
                //         permission: 'sm.geozones.index',
                //         next: 'objects'
                //     }
                // },
            ],
        },
        {
            path: "/devices",
            name: "devices",
            component: DevicesPage,
            meta: { authorize: true }
        },
        {
            path: "/reports",
            component: ReportsPage,
            children: [
                {
                    path: "trip-sheet",
                    name: "trip-sheet",
                    component: TripSheet,
                    meta: {
                        authorize: true,
                        permission: 'sm.reports.trip_sheet',
                        next: 'objects'
                    }
                },
                {
                    path: "mileage-by-day",
                    name: "mileage-by-day",
                    component: MileageByDay,
                    meta: {
                        authorize: true,
                        permission: 'sm.reports.mileage_by_day',
                        next: 'trip-sheet'
                    }
                },
                {
                    path: "smart-temp",
                    name: "smart-temp",
                    component: SmartTemp,
                    meta: {
                        authorize: true,
                        permission: 'sm.reports.temperature',
                        next: 'trip-sheet'
                    }
                },
                {
                    path: "over-speed",
                    name: "over-speed",
                    component: OverSpeed,
                    meta: {
                        authorize: true,
                        permission: 'sm.reports.over_speed',
                        next: 'trip-sheet'
                    }
                },
                {
                    path: "sensor-status",
                    name: "sensor-status",
                    component: SensorStatus,
                    meta: {
                        authorize: true,
                        permission: 'sm.reports.sensor_status',
                        next: 'trip-sheet'
                    }
                },
                {
                    path: "fuel-report",
                    name: "fuel-report",
                    component: FuelReport,
                    meta: {
                        authorize: true,
                        permission: 'sm.reports.fuel_card_full_report',
                        next: 'trip-sheet'
                    }
                },
            ],
        },
        {
            path: "/ems",
            component: EmsPage,
            children: [
                {
                    path: "request-video-evidence",
                    name: "ems",
                    component: RequestVideoEvidence,
                    meta: {
                        authorize: true,
                    }
                },
                {
                    path: "evidence-library",
                    name: "evidence-library",
                    component: EvidenceLibrary,
                    meta: {
                        authorize: true,
                    }
                },
            ],
        },
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            component: PageNotFound
        }
    ],
});

router.beforeEach((to, from, next) => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const isAuthenticated = user ? user.auth : store.getters.isAuthenticated;
    if (to.meta.authorize && !isAuthenticated) {
        next({ name: 'login' });
    } else if (!to.meta.authorize && isAuthenticated) {
        if (user.user.customer_module == 'subzero') {
            next({ name: 'devices' });
        } else {
            next({ name: 'objects' });
        }
    } else {
        if (to.meta.permission && user.user.permission[to.meta.permission]) {
            next();
        } else {
            next({ name: to.meta.next });
        }
    }
});

export default router;
