<template>
  <div>
    <div 
      v-if="!allowDownload && allowMy" 
      class="px-3 alert alert-warning shadow-sm my-4 mx-3"
      >
        <i class="fas fa-exclamation-triangle mr-1"></i>
        {{ getTranslationByKey('ems.download_not_allowed_alert') }}
    </div>
    <div v-if="allowRequest">
      <h6 class="my-3 px-3">
        <i class="fas fa-film mr-1"></i>
        {{ getTranslationByKey('ems.evidence_download_form') }}
      </h6>
      <div
        v-for="(criteria, index) in collapseCriteria"
        :key="index"
        class="mb-2 px-3"
      >
        <transition name="fade-up">
          <custom-collapse
            v-if="criteria.show"
            :index="index"
            :item="criteria"
            @handleCollapseOnClick="handleCollapseOnClick"
          >
            <div class="p-3">
              <vehicleSelect
                v-if="criteria.type === 'select-object'"
                :index="index"
                @onSelectedVehicle="onSelectedVehicle"
              />
  
              <DownloadType
                v-if="criteria.type === 'download-type'"
                :index="index"
                @onSelectedType="onSelectedType"
                @onVideoListReceived="onVideoListReceived"
                :collectedData="collectedData"
              />
  
              <!-- v-if="criteria.type === 'guided-or-simple'" -->
              <GuidedOrSimple
                v-if="criteria.type === 'blind' || criteria.type === 'guided'"
                :downloadType="collectedData.type"
                :index="index"
                @onSelectedBlind="onSelectedBlind"
                @onSelectedGuide="onSelectedGuide"
                :collectedData="collectedData"
              />
  
              <Description
                v-if="criteria.type === 'description'"
                :index="index"
                @onDescriptionAdded="onDescriptionAdded"
              />
            </div>
          </custom-collapse>
        </transition>
      </div>
      <div class="d-flex justify-content-center m-3" v-if="requestVideoButton">
        <button class="btn btn-primary w-25 py-3" @click="requestVideo">
          <i
            class="fas mr-2"
            :class="getRequestedVideosLoading ? 'fa-spinner fa-spin' : 'fa-check'"
          ></i>
          {{ getTranslationByKey('ems.request_video') }}
        </button>
      </div>
    </div>
    <RequestedVideoWidget
      v-if="allowMy" 
      :requestedVideos="getRequestedVideos"
      :allowDownload="allowDownload"
    />
  </div>
</template>

<script>
import CustomCollapse from "../global/CustomCollapse.vue";
import VehicleSelect from "./VehicleSelect.vue";
import DownloadType from "./DownloadType.vue";
import GuidedOrSimple from "./GuidedOrSimple.vue";
import Description from "./Description.vue";
import helper from '../../helper';
import Swal from 'sweetalert2';
import RequestedVideoWidget from "./RequestedVideoWidget.vue";
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    CustomCollapse,
    VehicleSelect,
    DownloadType,
    GuidedOrSimple,
    Description,
    RequestedVideoWidget,
  },
  data() {
    return {
      collapseCriteria: [
        {
          type: "select-object",
          icon: "fa-car",
          open: true,
          show: true,
          selectedValue: null,
        },
        {
          type: "download-type",
          icon: "fa-download",
          open: false,
          show: false,
          selectedValue: null,
        },
        {
          type: "guided-or-simple",
          icon: "fa-file-video",
          open: false,
          show: false,
          selectedValue: null,
          valueToTranslate: null,
        },
        {
          type: "description",
          icon: "fa-comment-dots",
          open: false,
          show: false,
          selectedValue: null,
        },
      ],
      visibleCollapseIndex: [0],
      collectedData: {},
      requestVideoButton: false,
    };
  },
  computed: {
    ...mapGetters([
      'getRequestedVideos',
      'getRequestedVideosLoading',
      'getVideoLists',
      'getTranslationByKey',
      'settings',
      'user',
      'isRequestCompleted'
    ]),
    allowRequest() {
      return this.user && this.user.permission['sm.ems.library.video.request'];
    },
    allowDownload() {
      return this.user && 
      this.user.permission['sm.ems.library.video.download']
    },
    allowMy() {
      return this.user && this.user.permission['sm.ems.library.video.view.my'];
    },
  },
  async mounted() {
    if (this.allowMy) {
      await this.getRequestedVideosOnLoad();
    }
  },
  methods: {
    ...mapActions(['requestVideos', 'WSReceiveVideo', 'getRequestedVideosOnLoad']),
    onSelectedVehicle(payload) {
      this.showNextCollapse(payload[0].index);
      this.collapseCriteria[payload[0].index].selectedValue = payload[0].name;
      this.collectedData.object = payload[0].name;
      this.collectedData.objectId = payload[0].objectId;
    },
    onSelectedType(payload) {
      // blind
      if (payload.type == '2') {
        this.collapseCriteria[payload.index + 1].type = 'blind';
        this.collapseCriteria[payload.index + 1].valueToTranslate = null
      }
      // guided
      if (payload.type == 1) {
        this.collapseCriteria[payload.index + 1].type = 'guided';
        this.collapseCriteria[payload.index + 1].valueToTranslate = null;
      }
      this.showNextCollapse(payload.index);
      this.collapseCriteria[payload.index].selectedValue = payload.typeName;

      this.collectedData.type = payload.type;
      this.collectedData.typeName = payload.typeName;
    },
    onVideoListReceived(payload) {
      this.videoList = payload;
    },
    onSelectedBlind(payload) {
      this.showNextCollapse(payload.index);
      this.collapseCriteria[payload.index].selectedValue = payload.name;
      this.collapseCriteria[payload.index].valueToTranslate = payload.valueToTranslate;
      this.collapseCriteria[payload.index].mic = payload.micId;

      this.collectedData.period = payload.periodRange;
      this.collectedData.sources = payload.sourceValues;
      this.collectedData.micId = payload.micId
    },
    onSelectedGuide(payload) {
      this.showNextCollapse(payload.index);
      this.collapseCriteria[payload.index].selectedValue = payload.name;

      this.collapseCriteria[payload.index].valueToTranslate = payload.valueToTranslate;
      this.collapseCriteria[payload.index].mic = payload.micId;

      this.collectedData.period = payload.minMaxDatetime;
      this.collectedData.sources = payload.sourceValues;
      this.collectedData.micId = payload.micId
    },
    onDescriptionAdded(payload) {
      this.collectedData.description = payload.description;
      this.collapseCriteria[payload.index].selectedValue = payload.description;
      this.showNextCollapse(payload.index);
    },
    showNextCollapse(i) {
      if (i === this.collapseCriteria.length - 1) {
        this.requestVideoButton = true;
        this.collapseCriteria[this.collapseCriteria.length - 1].open = false;
        return;
      }
      const collapseIndex = this.collapseCriteria.findIndex(
        (item, index) => index === i
      );
      const next = collapseIndex + 1;

      this.collapseCriteria[next].show = true;
      this.collapseCriteria[next].open = true;
      this.collapseCriteria[collapseIndex].open = false;

      // save visible collapse index for latter use
      if (!this.visibleCollapseIndex.includes(next)) {
        this.visibleCollapseIndex.push(next);
      }
    },
    handleCollapseOnClick(index) {
      let collapseIndex = this.collapseCriteria.findIndex(
        (item, i) => i === index
      );
      let current = this.collapseCriteria[collapseIndex];
      current.open = true;

      // hide all accordions comes after
      const elementsAfter = this.visibleCollapseIndex.slice(collapseIndex + 1);
      elementsAfter.forEach((index) => {
        this.collapseCriteria[index].show = false;
        this.collapseCriteria[index].selectedValue = null;
      });

      if (elementsAfter.length >= 0) {
        this.requestVideoButton = false;
      } else {
        this.requestVideoButton = true;
      }
    },
    async requestVideo() {
      this.collectedData.period.startTime = helper.dateToUTC(this.collectedData.period.startTime)
      this.collectedData.period.endTime = helper.dateToUTC(this.collectedData.period.endTime)

      const calculatedTimeInMinutes = 
      helper.calculateDiffTimeInMinutes(
        this.collectedData.period.startTime,
        this.collectedData.period.endTime
      )
      let videoMaxlength;

      if ("ems.video_request.length_max" in this.settings) {
        videoMaxlength = this.settings['ems.video_request.length_max'];
      } else {
        videoMaxlength = 60; // 60 minutes by default
      }

      if (calculatedTimeInMinutes > videoMaxlength) {
        let chunks = helper.calculateChunks(calculatedTimeInMinutes, videoMaxlength);
        let msg = this.getTranslationByKey('ems.video_split_notification')
          .replace(':split_count', chunks)
          .replace(' :max_video_length', videoMaxlength);
    
        Swal.fire({
          title: msg,
          text: this.getTranslationByKey('ems.confirm_download'),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.getTranslationByKey('ems.button_confirm'),
          cancelButtonText: this.getTranslationByKey('ems.button_cancel'),
          customClass: {
            cancelButton: 'bg-light text-dark',
            popup: 'py-3'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.requestVideos(this.collectedData);
            this.WSReceiveVideo();
            setTimeout(() => { this.handleNextRequest() }, 500);
          }
        });
      } else {
        await this.requestVideos(this.collectedData);
        this.WSReceiveVideo();
        setTimeout(() => { this.handleNextRequest() }, 500);
      }
    },
    handleNextRequest() {
      if (this.isRequestCompleted) {
        Swal.fire({
          html: this.getTranslationByKey('ems.popup_after_video_request_text'),
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.getTranslationByKey('ems.button_clear_form'),
          cancelButtonText: this.getTranslationByKey('ems.button_download_different'),
          allowOutsideClick: false,
          customClass: {
            cancelButton: 'bg-light text-dark',
            popup: 'py-3',
          }
        }).then((result) => {
          // reset form
          if (result.isConfirmed) {
            this.handleCollapseOnClick(0)
            this.collapseCriteria.forEach(item => item.selectedValue = null)
            this.collectedData = {}
          }
          // go to calendar period selection
          if (!result.isConfirmed) {
            const calendarIndex = this.collapseCriteria.findIndex(item => item.type === "blind" || item.type === "guided");
            this.handleCollapseOnClick(calendarIndex)
          }
        });
      }
    }
  }
};
</script>
