<template>
    <div class="m-3">
        <h6>{{ getTranslationByKey('ems.requested_videos_title') }}</h6>
        <table class="table table-sm small text-left">
            <thead>
                <tr>
                    <th>{{ getTranslationByKey('reports.criterias.object') }}</th>
                    <th>{{ getTranslationByKey('reports.card_transactions_table.table_source') }}</th>
                    <th>{{ getTranslationByKey('reports.criterias.period') }}</th>
                    <th>{{ getTranslationByKey('reports.table.duration') }}</th>
                    <th>{{ getTranslationByKey('reports.table.loaded_Size') }}</th>
                    <th>{{ getTranslationByKey('reports.table.size') }}</th>
                    <th>{{ getTranslationByKey('reports.table.requested_at') }}</th>
                    <th>{{ getTranslationByKey('reports.table.status') }}</th>
                    <th>{{ getTranslationByKey('reports.table.action') }}</th>
                </tr>
            </thead>
            <tbody v-if="requestedVideos.length > 0">
                <RequestedVideoRow 
                    v-for="row in requestedVideos" 
                    :key="row.id" 
                    :rowValue="row"
                    :allowDownload="allowDownload"
                />
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="9" class="text-center py-3 font-weight-600 border-bottom">
                        {{ getTranslationByKey('history.no_data') }} 
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import RequestedVideoRow from './RequestedVideoRow.vue';
import { mapGetters } from 'vuex'
    export default {
        props: {
            requestedVideos: {
                type: Array
            },
            allowDownload: {
                type: Boolean
            }
        },
        components: {
            RequestedVideoRow
        },
        data() {
            return {

            }
        },
        computed: {
            ...mapGetters(['getTranslationByKey'])
        }
    }
</script>

<style lang="scss" scoped>
th {
    padding-block: 10px;
}
</style>