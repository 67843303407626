<template>
  <div class="c-modal-overlay" @click.self="closeModal">
    <div class="c-modal objects-modal">
      <div class="c-modal-header shadow-sm">
        <div class="caption">
          <span class="flex-grow-1 pl-1 text-left font-weight-bold">
            <i class="fas fa-columns mr-2"></i>{{ getTranslationByKey('history.modal.columns') }}
          </span>
          <i @click="closeModal" class="fas fa-times close-modal"></i>
        </div>
      </div>
      <div class="c-modal-body v-modal-content">
        <columns-section
          v-for="(category, index) in this.enabledColumns"
          :key="index"
          :category="category"
        >
        </columns-section>
      </div>
      <div class="c-modal-footer">
        <button class="btn w-75 btn-success mr-1" @click="setColumns">
          <i class="fas fa-check mr-1"></i>{{ getTranslationByKey('history.modal.ok') }}
        </button>
        <button class="btn btn-light" @click="closeModal">
          <i class="fas fa-times mr-1 text-danger"></i>{{ getTranslationByKey('history.close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ColumnsSection from "./ColumnsSection.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    enabledColumns: Array,
  },
  components: {
    ColumnsSection,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getTranslationByKey"]),
  },
  created() {
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.closeModal();
      }
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    setColumns() {
      this.$emit("setSelectedColumns", this.enabledColumns);
      localStorage.setItem('historyEnabledColumns', JSON.stringify(this.enabledColumns));
      this.closeModal();
    },
  },
};
</script>
