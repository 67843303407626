<template>
  <div class="d-flex flex-grow-1 align-items-center">
    <!-- mark -->
    <div
        class="object-status-color-mark"
        :style="{ 'background-color': object.status_color }"
    ></div>
    <!-- name -->
    <div class="objects-list-name" @click="panToMap">
      {{ object.priority_name }}
    </div>
    <!-- tachograph -->
    <div
        class="objects-list-tachograph"
        v-show="!object.status_offline && object.can_tachograph"
    >
      <div
          v-for="(img, index) in object.tachograph_img"
          :key="index"
          class="objects-list-tachograph-icon"
      >
        <img
            :src="'/img/icons/16x16/' + img + '.svg'"
            :title="tachographTitle(img)"
            :class="[
            img === 'tachograph-green' ||
            img === 'tachograph-yellow' ||
            img === 'tachograph-red'
              ? 'custom-flag'
              : '',
          ]"
        />
      </div>
    </div>
    <!-- siren -->
    <div
        class="object-list-siren-status"
        v-show="object.beacons || object.siren"
    >
      <img :src="beaconsStatus" :title="beaconsStatusTitle"/>
    </div>
     <!-- VCC battery low-->
    <i 
      class="fa fa-car-battery mr-1 text-danger objects-list-vcc-status"
      v-show="isLowVCCBattery"
      :title="getTranslationByKey('vehicles.tooltip.vcc_battery_low')"
    ></i>
    <!-- computer -->
    <div
        class="objects-list-computer-status"
        v-show="object.pc && !object.status_offline"
    >
      <i class="fas fa-tv" :class="pcStatus" :title="computerStatusTitle"></i>
    </div>
    <!-- status -->
    <div class="objects-list-status-icon">
      <div
          class="d-flex"
          :class="object.status_wrapper_class"
          :title="objectStatusTitle"
      >
        <i class="fas" :class="object.status_icon_class"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  props: {
    object: Object,
  },
  data() {
    return {
      beaconsIcon: "/img/icons/lights.png",
      sirenIcon: "/img/icons/siren.png",
      escortBeaconsIcon: "/img/icons/lights-escort.png",
      escortSirenIcon: "/img/icons/siren-escort.png",
    };
  },
  computed: {
    ...mapGetters(["getTranslationByKey", "settings"]),
    beaconsStatus() {
      if (this.object.escort) {
        return this.object.siren ? this.escortSirenIcon : this.escortBeaconsIcon;
      }
      return this.object.siren ? this.sirenIcon : this.beaconsIcon;
    },
    pcStatus() {
      let statusClass = "pc-off";
      if (this.object.pc_state) {
        statusClass = "text-success";
      }
      return statusClass;
    },
    beaconsStatusTitle() {
      return this.object.siren
          ? this.getTranslationByKey('vehicles.tooltip.siren_status.siren')
          : this.getTranslationByKey('vehicles.tooltip.siren_status.beacons');
    },
    computerStatusTitle() {
      return this.object.pc_state
          ? this.getTranslationByKey('vehicles.tooltip.computer_status.computer_on')
          : this.getTranslationByKey('vehicles.tooltip.computer_status.computer_off');
    },
    objectStatusTitle() {
      let title = this.getTranslationByKey('vehicles.tooltip.object_status.offline');
      switch (this.object.status_wrapper_class) {
        case "status-parking":
          title = this.getTranslationByKey('vehicles.tooltip.object_status.stopped');
          break;
        case "status-stopped":
          title =
              this.getTranslationByKey('vehicles.tooltip.object_status.stopped') +
              this.getTranslationByKey('vehicles.tooltip.object_status.engine_on');
          break;
        case "status-driving":
          title = this.getTranslationByKey('vehicles.tooltip.object_status.driving');
          break;
        case "status-hijack":
          title =
              this.getTranslationByKey('vehicles.tooltip.object_status.moving') +
              this.getTranslationByKey('vehicles.tooltip.object_status.engine_off');
          break;
      }
      return title;
    },
    isLowVCCBattery() {
      return this.object.vcc && this.object.vcc <= this.settings.sm_low_vcc_level;
    }
  },
  methods: {
    ...mapActions(["panTo"]),
    panToMap() {
      this.panTo({
        lat: this.object.lat,
        lon: this.object.lon,
        course: this.object.course,
      });
      this.$audit({object_id: this.object.vid, 'action': 'object_centered'});
    },
    tachographTitle(img) {
      let title = "";
      switch (img) {
        case "tachograph-available":
          title = this.getTranslationByKey('vehicles.tooltip.tachograph_status.available');
          break;
        case "tachograph-driving":
          title = this.getTranslationByKey('vehicles.tooltip.tachograph_status.driving');
          break;
        case "tachograph-green":
          title = this.getTranslationByKey('vehicles.tooltip.tachograph_status.green');
          break;
        case "tachograph-red":
          title = this.getTranslationByKey('vehicles.tooltip.tachograph_status.red');
          break;
        case "tachograph-rest":
          title = this.getTranslationByKey('vehicles.tooltip.tachograph_status.rest');
          break;
        case "tachograph-working":
          title = this.getTranslationByKey('vehicles.tooltip.tachograph_status.working');
          break;
        case "tachograph-yellow":
          title = this.getTranslationByKey('vehicles.tooltip.tachograph_status.yellow');
          break;
      }
      return title;
    },
  },
};
</script>
