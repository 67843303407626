<template>
  <div>
    <transition name="fade-up">
      <reports-criterias
        :criterias="neededCriterias"
        :reportName="getTranslationByKey('reports.side_menu.mileage_by_day.title')"
        :isLoading="loading"
        @collectedCriterias="getData"
        v-show="!hasDataArrived"
      >
      </reports-criterias>
    </transition>
    <transition name="report-fade">
      <div
        class="report-view-wrapper"
        :class="{ 'no-sidebar': reportFullscreen }"
        v-show="hasDataArrived"
      >
        <!-- action buttons -->
        <ReportViewActionBar 
          :noDataFound="noDataFound" 
          :reportFullscreen="reportFullscreen" 
          :excelDownloading="excelDownloading" 
          :pdfDownloading="pdfDownloading"
          :neededCriterisIcon="neededCriterias.icon"
          :reportTitle="getTranslationByKey('reports.side_menu.mileage_by_day.title')"
          @onToggleFullscreen="toggleReportFullscreen"
          @onPrintReport="printReport"
          @onDownloadExcel="downloadExcel"
          @onDownloadPDF="downloadPdf"
          @onReportClose="closeReport"
        />
        <!-- report content -->
        <div class="report-content" ref="printReportContent" :class="reportFullscreen ? 'full-screen': ''">
          <div v-if="!noDataFound">
            <div class="pdf-header">
              <span>
                {{
                  getTranslationByKey('reports.side_menu.mileage_by_day.title') +
                  " " +
                  (reportDataObjects.length > 1
                    ? ", "
                    : reportDataObjects[0].name + ", ") +
                  todayDateForPrint
                }}
              </span>
              <span>SmartMonitor v{{ settings.version }}</span>
            </div>
            <h4 class="pdf-report-name">
              {{ getTranslationByKey('reports.side_menu.mileage_by_day.title') }}
            </h4>
            <!-- report info -->
            <div class="report-info">
              <div>
                <p class="company-name">{{ reportData.customer_name }}</p>
                <p class="report-period">{{ reportData.period }}</p>
              </div>
            </div>
            <!-- table -->
            <div class="table-container">
              <table class="reports-table mileage-table shadow-sm">
                <thead>
                  <tr>
                    <th rowspan="2">{{ getTranslationByKey('reports.table.object') }}</th>
                    <th
                      v-for="(month, index) in reportDataObjects[0].month"
                      :key="index"
                      :colspan="Object.keys(month.days).length"
                    >
                      {{ month.name }}
                    </th>
                    <th rowspan="2">{{ getTranslationByKey('reports.table.workdays') }}</th>
                    <th rowspan="2">{{ getTranslationByKey('reports.table.weekends') }}</th>
                    <th rowspan="2">{{ getTranslationByKey('reports.table.total') }}</th>
                  </tr>
                  <tr>
                    <template v-for="month in reportDataObjects[0].month">
                      <th
                        v-for="(day, index) in month.days"
                        :key="index"
                        :class="{ weekend: !day.is_work_day }"
                      >
                        {{ day.name }}
                      </th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(object, index) in reportDataObjects" :key="index">
                    <td class="font-weight-bold">{{ object.name }}</td>
                    <template v-for="month in object.month">
                      <td
                        v-for="(day, index) in month.days"
                        :key="index"
                        :class="{ weekend: !day.is_work_day }"
                        class="text-right"
                      >
                        {{ mileage(day.data) || "No data" }}
                      </td>
                    </template>
                    <td class="text-right">
                      {{ mileage(object.mileage_work_days) }}
                    </td>
                    <td class="text-right weekend">
                      {{ mileage(object.mileage_weekends) }}
                    </td>
                    <td class="text-right">
                      {{ mileage(object.mileage_total) }}
                    </td>
                  </tr>
                  <tr class="tfoot total-row">
                    <td>{{ getTranslationByKey('reports.table.total') }}:</td>
                    <template
                      v-for="month in reportData.total_mileage_days.month"
                    >
                      <td v-for="(day, index) in month.days" :key="index">
                        <span>{{ mileage(day) }}</span>
                      </td>
                    </template>
                    <td>
                      <span>{{
                        mileage(reportData.total_mileage_work_days)
                      }}</span>
                    </td>
                    <td>
                      <span>{{
                        mileage(reportData.total_mileage_weekends)
                      }}</span>
                    </td>
                    <td>
                      <span>{{ mileage(reportData.total_mileage_total) }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="no-data-found">
            <h3>
              <i class="fab fa-searchengin mr-1"></i
              >{{ getTranslationByKey('reports.table.no_data_found') }}
            </h3>
            <p class="text-muted font-weight-600 small">
              {{ getTranslationByKey('reports.table.other_obj') }}
            </p>
            <button class="btn btn-light" @click="hasDataArrived = false">
              <i class="fas fa-long-arrow-alt-left mr-1"></i
              >{{ getTranslationByKey('reports.table.back') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportsCriterias from "./ReportsCriterias.vue";
import ReportViewActionBar from "./ReportViewActionBar.vue";
import { mapGetters } from "vuex";
import api from "../../api";
import helper from "../../helper";
import moment from "moment";
import { Printd } from "printd";
import { EventBus } from "../../bus";
import { css } from "./print-report-css";

export default {
  components: {
    ReportsCriterias,
    ReportViewActionBar
  },
  data() {
    return {
      neededCriterias: {
        icon: "fas fa-calendar-alt",
        period: true,
        objects: true,
        mileage: true,
        roundedMileage: true,
      },

      collectedData: null,
      loading: false,
      excelDownloading: false,
      pdfDownloading: false,
      hasDataArrived: false,
      reportData: [],
      reportDataObjects: [],
      todayDateForPrint: moment().format("DD-MM-YYYY HH:mm:ss"),
      reportFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(["settings", "getTranslationByKey", "user"]),
    noDataFound() {
      return this.reportData.length == 0;
    },
  },
  methods: {
    getData(criterias) {
      this.collectedData = criterias;
      this.loading = true;
      api
        .get("report/mileage-by-day", {
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
            mileage_by: this.collectedData.mileage,
            roundup: this.collectedData.mileageRounded
          },
        })
        .then((response) => {
          this.reportData = response.data.data;
          this.reportDataObjects = this.reportData.objects || [];
          this.hasDataArrived = true;
          this.loading = false;

          this.$audit({
            action: 'report_mileage_by_day',
            value: {
              from: this.collectedData.periodFrom,
              to: this.collectedData.periodTill,
              mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
              max_speed: this.collectedData.maxSpeed,
              duration: this.collectedData.duration,
              vehicle_ids: this.collectedData.vehiclesIds,
            }
          });
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          console.error(error);
        });
    },
    downloadExcel() {
      this.excelDownloading = true;
      api
        .get("report/export-excel", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
            vehicle_ids: this.collectedData.vehiclesIds,
            roundup: this.collectedData.mileageRounded,
            report: "mileage-by-day",
          },
        })
        .then((response) => {
          this.excelDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.reportDataObjects.length > 1 ? '' : this.reportDataObjects[0].name;
          link.download = `${this.getTranslationByKey('reports.side_menu.mileage_by_day.title')} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.xlsx`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          this.excelDownloading = false;
          console.error(error);
        });
    },
    downloadPdf() {
      this.pdfDownloading = true;
      api
        .get("report/export-pdf", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
            vehicle_ids: this.collectedData.vehiclesIds,
            roundup: this.collectedData.mileageRounded,
            report: "mileage-by-day",
          },
        })
        .then((response) => {
          this.pdfDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.reportDataObjects.length > 1 ? '' : this.reportDataObjects[0].name;
          link.download = `${this.getTranslationByKey('reports.side_menu.mileage_by_day.title')} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.pdf`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          this.pdfDownloading;
          console.error(error);
        });
    },
    mileage(value) {
      if (helper.isSafari()) {
        value = value.toFixed(3);
      }
      if (this.collectedData.mileageRounded == "1") {
        value = `${Math.round(value)} Km`;
      } else {
        value = new Intl.NumberFormat(this.user.language, {
          style: "unit",
          unit: "kilometer",
          minimumFractionDigits: 3,
        }).format(value);
      }

      return value;
    },
    printReport() {
      const cssText = css;
      const d = new Printd();
      d.print(this.$refs.printReportContent, [cssText]);
    },
    closeReport() {
      this.hasDataArrived = false;
      this.reportFullscreen = false;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    toggleReportFullscreen() {
      this.reportFullscreen = !this.reportFullscreen;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
  },
};
</script>
