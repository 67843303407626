<template>
    <div class="calendar-warning-message" :class="activeTimeSection ? 'show': ''">
        <transition name="fade-up">
            <p class="warning-message" :class="firstDayClicked ? 'show-message': ''">
                <i class="fas fa-calendar-alt mr-1 text-success"></i>
                {{ getTranslationByKey('reports.calendar.select_till_period') }}
            </p>
        </transition>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            activeTimeSection: {
                type: Boolean,
                default: true
            },
            firstDayClicked: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters(['getTranslationByKey'])
        }
    }
</script>

<style lang="scss" scoped>

</style>