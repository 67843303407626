<template>
    <div class="row flex-nowrap minor-text align-items-center mt-4">
        <div class="col-md-auto">
            <span class="minor-text criteria-header">
                {{ getTranslationByKey('ems.audio_sources') }}:
            </span>
        </div>
        <div class="col-md-10">
            <div class="d-flex align-items-center gap">
                <div
                    v-for="mic in microphones" :key="mic"
                    class="form-check d-flex align-items-end"
                >
                    <input
                        :disabled="sourceValues.length == 0"
                        type="checkbox"
                        class="form-check-input custom-input"
                        :true-value="mic"
                        :false-value="''"
                        :id="mic"
                        v-model="selectedMic"
                        @change="updateValue($event, mic)"
                    />
                    <label
                        class="form-check-label"
                        :for="mic"
                    >
                        {{ getTranslationByKey(`ems.audio_type.${mic}`)}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        props: {
            microphones: {
                type: Array,
                required: true
            },
            sourceValues: {
                type: Array,
                required: true
            },
            modelValue: String
        },
        data() {
            return {
                selectedMic: ''
            }
        },
        computed: {
            ...mapGetters([
                'getTranslationByKey'
            ])
        },
        methods: {
            updateValue(event, mic) {
                if (event.target.checked) {
                    this.selectedMic = mic;
                } else {
                    this.selectedMic = '';
                }
                this.$emit('input', this.selectedMic);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .gap {
        gap: 20px;
    }
    .custom-input {
        bottom: 2px;
    }
</style>