<template>
  <div id="object-history" class="object-history">
    <div class="history-container">
      <div class="content" infinite-wrapper>
        <div class="columns-header">
          <div class="column-name">#</div>
          <div class="column-name"><i></i></div>
          <div
            v-for="(column, index) in showSelectedColumns"
            :key="index"
            class="column-name"
            :class="{
              position: column.dataName == 'position',
            }"
          >
            <span
              class="text-uppercase"
              :title="column.name"
              v-if="column.enabled"
            >
              {{ column.name }}
            </span>
          </div>
        </div>

        <history-loader :noData="noData" :loadData="loadData"></history-loader>

        <div class="history-data">
          <div
            v-for="(historyRow, index) in historyList"
            :key="index"
            class="history-data-row"
            :style="{ 'background-color': historyRow.status_color + '66' }"
            @mouseover="moveRouteRunner(historyRow.lat, historyRow.lon)"
          >
            <span class="column-data">{{ index + 1 }}</span>
            <div class="column-data">
              <input
                :id="index + 1"
                type="checkbox"
                :checked="historyRow.checkbox"
                @change="setCheckedMarker(index + 1, historyRow)"
              />
            </div>
            <span class="column-data" v-if="enabledDataColumns['tx_date']">{{
              historyRow.tx_date
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['tx_time']">{{
              historyRow.tx_time
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['rx_date']">{{
              historyRow.rx_date
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['rx_time']">{{
              historyRow.rx_time
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['event']">
              {{ getTranslationByKey(`device_info.reason.${historyRow.event}`) }}
            </span>
            <span
              class="column-data position"
              v-if="enabledDataColumns['position']"
            >
              {{ getLocation(historyRow.lat, historyRow.lon) }}
            </span>
            <span class="column-data" v-if="enabledDataColumns['engine']">
              {{
                historyRow.engine
                  ? getTranslationByKey('history.table.on')
                  : getTranslationByKey('history.table.off')
              }}
            </span>
            <span class="column-data" v-if="enabledDataColumns['beacons']">
              {{ historyRow.beacons ? getTranslationByKey('history.table.on') : getTranslationByKey('history.table.off') }}
              <br>
              {{ beaconsInputTranslation(historyRow) }}
            </span>
            <span class="column-data" v-if="enabledDataColumns['siren']">
              {{
                historyRow.siren
                  ? getTranslationByKey('history.table.on')
                  : getTranslationByKey('history.table.off')
              }}
            </span>
            <span class="column-data" v-if="enabledDataColumns['speed_gps']">{{
              historyRow.speed_gps
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['course']">{{
              historyRow.course
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['driven_km']">{{
              historyRow.driven_km
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['vcc']">{{
              historyRow.vcc
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['ibutton_id']">{{
              historyRow.ibutton_id
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['odometer']">
              {{
                historyRow.odometer
                  ? historyRow.odometer.toFixed(3)
                  : historyRow.odometer
              }}
            </span>
            <span
              class="column-data"
              v-if="enabledDataColumns['can_fuel_level_1']"
            >
              {{ historyRow.can_fuel_level_1 }}
            </span>
            <span
              class="column-data"
              v-if="enabledDataColumns['can_fuel_level_2']"
            >
              {{ historyRow.can_fuel_level_2 }}
            </span>
            <span
              class="column-data"
              v-if="enabledDataColumns['can_fuel_consumption']"
              >{{ historyRow.can_fuel_consumption }}</span
            >
            <span class="column-data" v-if="enabledDataColumns['speed_can']">
              {{
                historyRow.speed_can
                  ? historyRow.speed_can.toFixed(3)
                  : historyRow.speed_can
              }}
            </span>
            <span
              class="column-data"
              v-if="enabledDataColumns['can_engine_hours']"
            >
              {{ historyRow.can_engine_hours }}
            </span>
            <span class="column-data" v-if="enabledDataColumns['can_rpm']">{{
              historyRow.can_rpm
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['can_load_1']">{{
              historyRow.can_load_1
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['can_load_2']">{{
              historyRow.can_load_2
            }}</span>
            <span
              class="column-data"
              v-if="enabledDataColumns['can_temperature']"
            >
              {{ historyRow.can_temperature }}
            </span>
            <span
              class="column-data"
              v-if="enabledDataColumns['can_weight_1']"
              >{{ historyRow.can_weight_1 }}</span
            >
            <span
              class="column-data"
              v-if="enabledDataColumns['can_weight_2']"
              >{{ historyRow.can_weight_2 }}</span
            >
            <span
              class="column-data"
              v-if="enabledDataColumns['can_manipulators']"
            >
              {{ historyRow.can_manipulators }}
            </span>
            <span class="column-data" v-if="enabledDataColumns['seal_1']">{{
              historyRow.seal_1
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['seal_2']">{{
              historyRow.seal_2
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['seal_3']">{{
              historyRow.seal_3
            }}</span>
            <span class="column-data" v-if="enabledDataColumns['seal_4']">{{
              historyRow.seal_4
            }}</span>
            <span
              class="column-data"
              :class="{
                outdated: historyRow.temp_0.outdated,
                'low-bat': historyRow.temp_0.low_battery,
              }"
              v-if="enabledDataColumns['temp_0']"
            >
              {{
                checkIfObject(historyRow.temp_0)
                  ? historyRow.temp_0.temperature
                  : historyRow.temp_0
              }}
            </span>

            <span
              class="column-data"
              :class="{
                outdated: historyRow.temp_1.outdated,
                'low-bat': historyRow.temp_1.low_battery,
              }"
              v-if="enabledDataColumns['temp_1']"
            >
              {{
                checkIfObject(historyRow.temp_1)
                  ? historyRow.temp_1.temperature
                  : historyRow.temp_1
              }}
            </span>

            <span
              class="column-data"
              :class="{
                outdated: historyRow.temp_2.outdated,
                'low-bat': historyRow.temp_2.low_battery,
              }"
              v-if="enabledDataColumns['temp_2']"
            >
              {{
                checkIfObject(historyRow.temp_2)
                  ? historyRow.temp_2.temperature
                  : historyRow.temp_2
              }}
            </span>

            <span
              class="column-data"
              :class="{
                outdated: historyRow.temp_3.outdated,
                'low-bat': historyRow.temp_3.low_battery,
              }"
              v-if="enabledDataColumns['temp_3']"
            >
              {{
                checkIfObject(historyRow.temp_3)
                  ? historyRow.temp_3.temperature
                  : historyRow.temp_3
              }}
            </span>

            <span
              class="column-data"
              :class="{
                outdated: historyRow.temp_4.outdated,
                'low-bat': historyRow.temp_4.low_battery,
              }"
              v-if="enabledDataColumns['temp_4']"
            >
              {{
                checkIfObject(historyRow.temp_4)
                  ? historyRow.temp_4.temperature
                  : historyRow.temp_4
              }}
            </span>
          </div>

          <infinite-loading 
            spinner="spiral" 
            @infinite="infiniteHandler" 
            force-use-infinite-wrapper
            ref="InfiniteLoading"
          >
            <div slot="no-more">{{ getTranslationByKey('history.table.end') }}</div>
            <div slot="no-results">{{ getTranslationByKey('history.table.end') }}</div>
          </infinite-loading>
        </div>
      </div>
    </div>

    <history-modal
      @close="closeModal"
      @setSelectedColumns="setSelectedColumns"
      :enabledColumns="selectedColumns"
      v-if="showModal"
    >
    </history-modal>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import HistoryModal from "./HistoryModal.vue";
import HistoryLoader from "../global/HistoryLoader.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    InfiniteLoading,
    HistoryModal,
    HistoryLoader,
  },
  props: {
    historyResponseData: Array,
    historyList: Array,
    noData: Boolean,
    loadData: Boolean,
    showModal: Boolean,
  },
  data() {
    return {
      enabledColumns: [],
      enabledDataColumns: {},
    };
  },
  computed: {
    ...mapGetters(["getTranslationByKey"]),
  
    historyColumnsHeader() {
      // All dataName should be same as columns in DB
      return [
        {
          id: "general",
          name: this.getTranslationByKey('history.table.general'),
          show: true,
          indeterminate: true,
          columns: [
            {
              name: this.getTranslationByKey('history.table.tx_date'),
              dataName: "tx_date",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.tx_time'),
              dataName: "tx_time",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.rx_date'),
              dataName: "rx_date",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.rx_time'),
              dataName: "rx_time",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.event'),
              dataName: "event",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.position'),
              dataName: "position",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.engine'),
              dataName: "engine",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.beacons'),
              dataName: "beacons",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.siren'),
              dataName: "siren",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.speed_gps'),
              dataName: "speed_gps",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.course'),
              dataName: "course",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.driven_km'),
              dataName: "driven_km",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.vcc'),
              dataName: "vcc",
              enabled: false,
            },
          ],
        },
        {
          id: "driver_identification",
          name: this.getTranslationByKey('history.table.driver_identification'),
          show: false,
          indeterminate: false,
          columns: [
            {
              name: this.getTranslationByKey('history.table.ibutton_id'),
              dataName: "ibutton_id",
              enabled: false,
            },
          ],
        },
        {
          id: "can_main",
          name: this.getTranslationByKey('history.table.can_main'),
          show: false,
          indeterminate: true,
          columns: [
            {
              name: this.getTranslationByKey('history.table.odometer'),
              dataName: "odometer",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.can_fuel_level_1'),
              dataName: "can_fuel_level_1",
              enabled: true,
            },
            {
              name: this.getTranslationByKey('history.table.can_fuel_level_2'),
              dataName: "can_fuel_level_2",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_fuel_consumption'),
              dataName: "can_fuel_consumption",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.speed_can'),
              dataName: "speed_can",
              enabled: false,
            },
          ],
        },
        {
          id: "can_extra",
          name: this.getTranslationByKey('history.table.can_extra'),
          show: false,
          indeterminate: false,
          columns: [
            {
              name: this.getTranslationByKey('history.table.can_engine_hours'),
              dataName: "can_engine_hours",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_rpm'),
              dataName: "can_rpm",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_load_1'),
              dataName: "can_load_1",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_load_2'),
              dataName: "can_load_2",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_temperature'),
              dataName: "can_temperature",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_weight_1'),
              dataName: "can_weight_1",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_weight_2'),
              dataName: "can_weight_2",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.can_manipulators'),
              dataName: "can_manipulators",
              enabled: false,
            },
          ],
        },
        {
          id: "wireless_sensors",
          name: this.getTranslationByKey('history.table.wireless_sensors'),
          show: false,
          indeterminate: false,
          columns: [
            {
              name: this.getTranslationByKey('history.table.seal_1'),
              dataName: "seal_1",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.seal_2'),
              dataName: "seal_2",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.seal_3'),
              dataName: "seal_3",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.seal_4'),
              dataName: "seal_4",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.temp_0'),
              dataName: "temp_0",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.temp_1'),
              dataName: "temp_1",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.temp_2'),
              dataName: "temp_2",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.temp_3'),
              dataName: "temp_3",
              enabled: false,
            },
            {
              name: this.getTranslationByKey('history.table.temp_4'),
              dataName: "temp_4",
              enabled: false,
            },
          ],
        },
      ];
    },
    selectedColumns() {
      let enabledColumns = []
      if (localStorage.getItem('historyEnabledColumns') !== null) {
        enabledColumns = JSON.parse(localStorage.getItem('historyEnabledColumns'))
      } else {
        enabledColumns = this.historyColumnsHeader
      }
      return enabledColumns
    },
    showSelectedColumns() {
      let headers = this.selectedColumns;
      let columns = [];
      for (let i = 0; i < headers.length; i++) {
        columns = columns.concat(headers[i].columns);
      }

      return columns;
    },
  },
  watch: {
    historyResponseData: {
      deep: true,
      handler() {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  },
  created() {
    this.getEnabledDataColumns();
    this.emitColumnsForExcel(); // needed for Excel
  },
  methods: {
    ...mapActions([
      "createCheckedMarker",
      "removeCheckedMarker",
      "updateRouteRunner",
    ]),
    setSelectedColumns(columns) {
      this.enabledColumns = columns;
      this.getEnabledDataColumns();
      this.emitColumnsForExcel(); // needed for Excel
    },
    emitColumnsForExcel() {
      this.$emit("columns", this.selectedColumns);
    },
    checkIfObject(item) {
      if (typeof item == "object") {
        return true;
      } else {
        return false;
      }
    },
    getEnabledDataColumns() {
      for (let i = 0; i < this.showSelectedColumns.length; i++) {
        let column = this.showSelectedColumns[i];
        this.enabledDataColumns[column.dataName] = column.enabled;
      }
    },
    getLocation(lat, lon) {
      let location = this.getTranslationByKey('history.no_data');
      if (lat != 0 && lon != 0) {
        location = lat + " " + lon;
      }
      return location;
    },
    setCheckedMarker(id, data) {
      data.checkbox = !data.checkbox;
      if (data.checkbox) {
        this.createCheckedMarker({
          id: id,
          lat: data.lat,
          lon: data.lon,
        });
        this.$emit("marker-checked", { data, id }); // for history excel
      } else {
        this.removeCheckedMarker({ id: id });
        this.$emit("marker-unchecked", id); // for history excel
      }
    },
    moveRouteRunner(lat, lon) {
      if (lat != 0 && lon != 0) {
        this.updateRouteRunner({ lat: lat, lon: lon });
      }
    },
    beaconsInputTranslation(historyRow) {
      if (historyRow.beacons_input != null) {
        return this.getTranslationByKey(`history.table.${historyRow.beacons_input}`);
      }
      return '';
    },
    infiniteHandler($state) {
      if (this.historyList.length < this.historyResponseData.length) {
      
        this.$emit('add-chunk-history-data')
      
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
