<template>
  <div>
    <who-was-there-sidebar></who-was-there-sidebar>
  </div>
</template>

<script>
import WhoWasThereSidebar from "../components/map/WhoWasThereSidebar.vue";

export default {
  components: {
    WhoWasThereSidebar,
  },
};
</script>
