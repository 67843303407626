<template>
  <div id="page-objects" class="page-container">
    <router-view></router-view>
    <div
      id="map-container"
      class="map-container"
      ref="mapContainer"
      :class="{ 'no-sidebar': !showSidebar }"
    >
      <map-toolbar
        v-if="map"
        @triggerWazeEvents="onTriggerWazeEvents"
      ></map-toolbar>

      <object-info-block v-draggable="draggableValue"></object-info-block>

      <map-trace v-if="map"></map-trace>
      <GmapMap
        id="map"
        :center="{ lat: 56.9, lng: 24.1 }"
        map-type-id="roadmap"
        style="width: 100%; height: 100%"
        :class="{ 'map-compact': chartIsOpen }"
        @center_changed="saveCenter"
        @zoom_changed="saveZoom"
        ref="mapRef"
      >
        <div v-if="!wazeDataNotFound">
          <GmapCustomMarker
            :key="index"
            v-for="(m, index) in wazeAlertsData"
            :marker="{ lat: m.location.y, lng: m.location.x }"
            :title="m.title"
          >
            <img
              :src="`/img/waze/${m.icon}`"
              class="waze-icon-on-map"
              alt="img-waze"
            />
          </GmapCustomMarker>
        </div>
      </GmapMap>
    </div>
  </div>
</template>
<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import MapToolbar from "../components/map/MapToolbar.vue";
import ObjectInfoBlock from "../components/map/ObjectInfoBlock.vue";
import MapTrace from "../components/map/MapTrace.vue";
import { Draggable } from "draggable-vue-directive";
import { EventBus } from "../bus";
import { mapActions, mapGetters } from "vuex";
import helper from "../helper";
import api from "../api";

export default {
  components: {
    GmapCustomMarker,
    MapToolbar,
    ObjectInfoBlock,
    MapTrace,
  },
  data() {
    return {
      chartIsOpen: false,
      draggableValue: {
        boundingElement: null,
        // boundingRectMargin: { top:  '40px', left: 0, bottom: 0, right: 0}
      },
      wazeBounds: {},
      wazeAlertsData: [],
      numDeltas: 100,
      wazeDataNotFound: false,
      showWazeEvents: false,
      wazeZoomListener: null,
      wazeDragendListener: null,
    };
  },
  computed: {
    ...mapGetters(["map", "showSidebar"]),
  },
  mounted() {
    this.$refs.mapRef.$mapPromise.then(async (map) => {
      this.createMap(map);
      // Set other map types to google
      const mapTypes = helper.mapTypes();
      for (let mapType in mapTypes) {
        this.map.mapTypes.set(
          mapType,
          new google.maps.ImageMapType(mapTypes[mapType])
        );
      }
      const mapOptions = {
        clickableIcons: true,
        keyboardShortcuts: false,
        mapTypeControl: false,
        scaleControl: true,
        minZoom: 6,
        maxZoom: 19,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        gestureHandling: "greedy", // To allow zoom without Ctrl button
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER,
        },
      };
      const streetViewOptions = {
        addressControl: false,
        clickToGo: false,
        enableCloseButton: true,
        fullScreenControl: false,
        panControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
      };
      this.map.setOptions(mapOptions);
      this.map.getStreetView().setOptions(streetViewOptions);

      this.getUserSavedCenter();
      this.getUserSavedZoom();
    });

    this.draggableValue.boundingElement = this.$refs.mapContainer;

    EventBus.$on("map-show-chart", (payload) => {
      return (this.chartIsOpen = payload);
    });
  },
  methods: {
    ...mapActions(["createMap"]),
    saveCenter() {
      let center = this.map.getCenter();
      localStorage.setItem(
        "mapCenter",
        JSON.stringify({ lat: center.lat(), lng: center.lng() })
      );
    },
    saveZoom() {
      localStorage.setItem("mapZoom", JSON.stringify(this.map.getZoom()));
    },
    getUserSavedCenter() {
      if (localStorage.getItem("mapCenter") !== null) {
        const { lat, lng } = JSON.parse(localStorage.getItem("mapCenter"));
        this.map.setCenter(new google.maps.LatLng(lat, lng));
      }
    },
    getUserSavedZoom() {
      if (localStorage.getItem("mapZoom") !== null) {
        this.map.setZoom(JSON.parse(localStorage.getItem("mapZoom")));
      } else {
        this.map.setZoom(15);
      }
    },
    async getWazeData() {
      if (this.map.getZoom() > 16) return;
      try {
        let bounds = await this.map.getBounds();
        let ne = bounds.getNorthEast();
        let sw = bounds.getSouthWest();

        this.wazeBounds = {
          north: ne.lat(), // bottom
          south: sw.lat(), // top
          west: sw.lng(), // right
          east: ne.lng(), // left
        };
        const response = await api.get("maps/waze-data", {
          params: {
            bottom: this.wazeBounds.north,
            left: this.wazeBounds.east,
            right: this.wazeBounds.west,
            top: this.wazeBounds.south,
            types: ["alerts"],
          },
        });

        if (response.request.status === 200) {
          this.wazeAlertsData = response.data.alerts;
          this.wazeDataNotFound = false;
        } else {
          (this.wazeAlertsData = []), (this.wazeDataNotFound = true);
        }
      } catch (e) {}
    },
    onTriggerWazeEvents(isActive) {
      this.showWazeEvents = isActive;
    },
  },
  watch: {
    showWazeEvents(value) {
      this.showWazeEvents = value;
      if (this.showWazeEvents) {
        this.getWazeData();
        this.wazeZoomListener = this.map.addListener("zoom_changed", () =>
          setTimeout(() => {
            this.getWazeData();
          }, 2000)
        );
        this.wazeDragendListener = this.map.addListener("dragend", () =>
          setTimeout(() => {
            this.getWazeData();
          }, 2000)
        );
      } else {
        this.wazeAlertsData = [];
        google.maps.event.removeListener(this.wazeZoomListener);
        google.maps.event.removeListener(this.wazeDragendListener);
      }
    },
  },
  directives: {
    Draggable,
  },
};
</script>
