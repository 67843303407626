import { EventBus } from "../../bus"
export default {
    state: {
        EMYMarkers: {},
        EMYSingleMarker: {},
        oldSingleMarkerID: null
    },
    actions: {
        createEMYSingleMarker({ commit, getters, state }, { data, map }) {
            let latLon = { lat: data.lat, lng: data.lon };
            const { address } = data.address

            // remove previous marker
            if (Object.keys(state.EMYSingleMarker).length > 0) {
                state.oldSingleMarkerID = Object.keys(state.EMYSingleMarker)[0]
                commit("REMOVE_SINGLE_MARKER", state.oldSingleMarkerID)
            }

            commit('ADD_EMY_SINGLE_MARKER', {latLon, id: data.requestId, map, address, icon: getters.EMYIcon})
        },
        removeEMYSingleMarker({ commit }, id) {
            commit('REMOVE_SINGLE_MARKER', id)
        },
        createEMYMarker({ commit, getters }, { data, map }) {
            let latLon = { lat: data.lat, lng: data.lon };
            const { address } = data.address
        
            commit('ADD_EMY_MARKER', {latLon, id: data.requestId, map, address, icon: getters.EMYIcon})
        },
        removeEMYMarker({ commit }, id) {
            commit("REMOVE_MARKER", id)
        }
    },
    mutations: {
        ADD_EMY_SINGLE_MARKER(state, { latLon, id, map, address, icon }) {
            state.EMYSingleMarker[id] = new google.maps.Marker({
                position: latLon,
                title: address,
                map,
                icon: icon,
                animation: google.maps.Animation.DROP,
                id: id
            })

            let EMYMarker = state.EMYSingleMarker[id]
            let content = EMYMarker.title

            let infoWindow = new google.maps.InfoWindow({content})
            EMYMarker.addListener('click', () => {
                infoWindow.open(map, EMYMarker)
            })


            google.maps.event.addListener(infoWindow, 'closeclick', () => {
                EMYMarker.setMap(null)
                EventBus.$emit('EMY-close-toast', id)

                if (state.EMYSingleMarker.hasOwnProperty(id)) {
                    this.dispatch('removeEMYSingleMarker', id)
                }
            })
        },
        ADD_EMY_MARKER(state, { latLon, id, map, address, icon }) {
            state.EMYMarkers[id] = new google.maps.Marker({
                position: latLon,
                title: address,
                map,
                icon: icon,
                animation: google.maps.Animation.DROP
            })

            state.EMYMarkers[id].address = address
    
            const EMYMarkers = state.EMYMarkers

            for (const key in EMYMarkers) {
                let EMYMarker = EMYMarkers[key]
                let content = EMYMarker.address
                
                let infoWindow = new google.maps.InfoWindow({content})
        
                EMYMarker.addListener('click', () => {
                  infoWindow.open(map, EMYMarker)
                })
        
                google.maps.event.addListener(infoWindow, 'closeclick', () => {
                    EMYMarker.setMap(null)
                    EventBus.$emit('EMY-close-toast', key)

                    if (state.EMYMarkers.hasOwnProperty(key)) {
                        this.dispatch('removeEMYMarker', key)
                    }

                })
            }
        },
        REMOVE_MARKER(state, id) {
            state.EMYMarkers[id].setMap(null)
            delete state.EMYMarkers[id]
        },
        REMOVE_SINGLE_MARKER(state, id) {
            state.EMYSingleMarker[id].setMap(null)
            delete state.EMYSingleMarker[id]
        }
    },
    getters: {
        EMYIcon() {
            return {
                url: '/img/map/EMY-location.png',
                scaledSize: new google.maps.Size(40, 40)
            }
        }
    }
};