<template>
  <div class="page-not-found">
      <img src="/img/not-found.jfif" alt="">
      <div class="text-content">
        <h2>Page not found</h2>
        <span>404</span>
      </div>
      <router-link :to="{name: 'objects'}">
        <button class="btn btn-link px-5 btn-lg back">
          <i class="fas fa-long-arrow-alt-left mr-2"></i>Back
        </button>
      </router-link>
  </div>
</template>

<script>
export default {}
</script>
