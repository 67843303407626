<template>
  <div>
    <h4 class="p-4">Video library</h4>
  </div>
</template>

<script>
import ReportsCriterias from "../report/ReportsCriterias.vue";

export default {
  components: {
    ReportsCriterias,
  },
  data() {
    return {
      
    }
  },
  computed: {
    
  },
  methods: {

  },
};
</script>
