import axios from "axios";
import store from "./store";
import Swal from 'sweetalert2';

const api = axios.create({
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

api.interceptors.request.use(request => {
    request.baseURL = store.getters.config.APP_API_URL;
    
    if (store.getters.isAuthenticated) {
        request.headers.common.Authorization = `Bearer ${store.getters.accessToken}`;
    }

    return request;
});

api.interceptors.response.use((response) => {
        // if (response.status >= 200 && response.status < 300 && response.data.message) {
        //     Swal.fire({
        //         toast: true,
        //         showConfirmButton: false,
        //         position: 'top-end',
        //         width: '25rem',
        //         timer: 10000,
        //         timerProgressBar: true,
        //         showCloseButton: true,
        //         didOpen: (toast) => {
        //             toast.addEventListener('mouseenter', Swal.stopTimer)
        //             toast.addEventListener('mouseleave', Swal.resumeTimer)
        //         },
        //         customClass: {
        //             timerProgressBar: 'progress-success',
        //             popup: 'p-2 d-flex align-items-center',
        //             closeButton: 'order-1',
        //             htmlContainer: 'flex-grow-1'
        //         },
        //         icon: 'success',
        //         color: 'green',
        //         text: response.data.message
        //     })
        // }
        return response
    }, async function (error) {
        if (error.response.status === 401) {
            this.$store.dispatch('removeAllIntervals');
            this.$store.dispatch.killSession()
        }
        return Promise.reject(error);
    }
);

export default api;
