<template>
    <div class="card-transactions mt-5">
        <h6>
            <i class="fas fa-credit-card mr-1"></i> 
            {{ getTranslationByKey('reports.card_transactions_table.table_title') }}
            ({{ transactionTotal }})
        </h6>
        <div class="table-container">
            <table class="reports-table">
                <thead>
                    <tr>
                        <th>{{ getTranslationByKey('reports.card_transactions_table.table_nr') }}</th>
                        <th>{{ getTranslationByKey('reports.card_transactions_table.table_card_nr') }}</th>
                        <th>{{ getTranslationByKey('reports.card_transactions_table.table_description') }}</th>
                        <th>{{ getTranslationByKey('reports.card_transactions_table.table_type_name') }}</th>
                        <th class="text-right">{{ getTranslationByKey('reports.card_transactions_table.table_amount') }}</th>
                        <th class="text-right">{{ getTranslationByKey('reports.card_transactions_table.table_units') }}</th>
                        <th class="text-right">{{ getTranslationByKey('reports.card_transactions_table.table_sum') }}</th>
                        <th>{{ getTranslationByKey('reports.card_transactions_table.table_created_at') }}</th>
                    </tr>
                </thead>
                <tbody v-if="transactions.length">
                    <tr v-for="(transaction, index) in transactions" 
                        :key="transaction.id"
                    >
                        <td>{{ index + 1 }}</td>
                        <td class="d-flex justify-content-center">
                            {{ transaction.card_nr }} ( {{ transaction.source_title }} )
                        </td>
                        <td>{{ transaction.description }}</td>
                        <td>{{ getTypeNameTrans(transaction.category_name) }}</td>
                        <td class="text-right">
                            {{ formatAmount(transaction.amount) }}
                        </td>
                        <td class="text-right">{{ getUnitsTrans(transaction.is_fuel) }}</td>
                        <td class="text-right">{{ formatCurrency(transaction.sum) }}</td>
                        <td>{{ transaction.created_at }}</td>
                    </tr>
                    <tr>
                        <td colspan="6"></td>
                        <td class="font-weight-600 text-right">
                            <span class="total">{{ getTranslationByKey('reports.card_transactions_table.table_total') }}</span>
                            {{ formatCurrency(sumTotal) }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="7" class="text-center">
                            {{ noDataFoundTrans }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            transactions:{
                type: Array,
                required: true
            },
        },
        computed: {
            ...mapGetters(["getTranslationByKey", "user"]),
            
            noDataFoundTrans() {
                return this.getTranslationByKey('reports.table.no_data_found');
            },
            sumTotal() {
                return this.transactions.reduce((total, { sum }) => total + sum, 0);
            },
            transactionTotal() {
                return this.transactions.length;
            }
        },
        methods: {
            formatCurrency(amount) {
                const formatter = new Intl.NumberFormat(this.user.language, {
                    style: 'currency',
                    currency: 'EUR'
                });
                return formatter.format(amount);
            },
            formatAmount(amount) {
                const formatter = new Intl.NumberFormat(this.user.language, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
                return formatter.format(amount);
            },
            getTypeNameTrans(typeName) {
                return typeName === 'fuel' 
                    ? this.getTranslationByKey('reports.card_transactions_table.table_properties.category_name.fuel')
                    : this.getTranslationByKey('reports.card_transactions_table.table_properties.category_name.other');   
            },
            getUnitsTrans(isFuel) {
                if (isFuel === 1) {
                    return this.getTranslationByKey('reports.card_transactions_table.table_properties.unit_name.liters');
                }
                return this.getTranslationByKey('reports.card_transactions_table.table_properties.unit_name.pieces');
            },
        }
    }
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables';
.card-transactions {
    h6 {
        margin-bottom: 15px;
        color: #555 !important;
        text-transform: uppercase;
        font-size: 15px;
    }
    .reports-table {
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        box-shadow: 0 0 20px rgba(0, 0, 0, .15);
        td, th {
          border: 0!important;
          padding: 12px 15px !important;
          font-size: 13px !important;
          font-weight: 600;

        }
        th {
            text-align: center;
        }
        tbody {
            .total {
                color: darken($tableBgHeader, 80%);
            }
            tr {
                border-bottom: 1px solid #ddd;
                &:nth-of-type(even){
                    background-color: #f8f9fa;
                }
                &:last-of-type {
                    border-bottom: 2px solid darken($tableBgHeader, 30%);
                    background: none;
                }
                &:last-of-type:hover {
                    background: none;
                }
            }
        }
    }
}
</style>