<template>
  <div id="map-sidebar" class="who-was-sidebar shadow">
    <div class="search-info-container pt-2">
      <h6 
        class="text-center minor-text" 
        v-text="getTranslationByKey('map.who_was_there_sidebar.search_object')"
      ></h6>
      <!-- period -->
      <div class="who-period-wrapper shadow p-3 rounded">
        <div class="who-period">
          <div class="from">
            <span class="text-muted" v-text="getTranslationByKey('map.who_was_there_sidebar.from')"></span>
            <span v-text="from"></span>
          </div>
          <div class="till">
            <span class="text-muted" v-text="getTranslationByKey('map.who_was_there_sidebar.till')"></span>
            <span v-text="till"></span>
          </div>
        </div>
        <i
          class="fas fa-calendar-alt text-info"
          @click="showCalendar = !showCalendar"
        ></i>
      </div>
      <!-- buttons -->
      <div class="my-3 d-flex test-buttons">
        <button
          class="btn btn-sm btn-success flex-grow-1 mr-1"
          @click="getWhoWasThere"
        >
          <i
            class="fa mr-1"
            :class="[searching ? 'fa-spinner fa-spin' : 'fa-search']"
          ></i>
          {{ getTranslationByKey('map.who_was_there_sidebar.search') }}
        </button>
        <button class="btn btn-sm btn-danger" @click="closeSidebar">
          <i class="fa fa-times mr-1"></i>
          {{ getTranslationByKey('map.who_was_there_sidebar.close') }}
        </button>
      </div>
      <!-- found info -->
      <h6
        class="found-info minor-text mb-3"
        :class="{ 'show-found-info': whoWasThere.length }"
      >
        <span class="mr-1">{{ getTranslationByKey('map.who_was_there_sidebar.found') }}</span
        ><span class="text-success">{{ whoWasThere.length }}</span>
        <span class="ml-1">{{ getTranslationByKey('map.who_was_there_sidebar.objects') }}</span>
      </h6>
      <transition name="fade">
        <div
          class="who-was-there-result-container rounded"
          v-if="whoWasThere.length"
        >
          <ul>
            <li
              v-for="(whoWas, index) in whoWasThere"
              :key="index"
              class="text-muted"
              @click="goToChart(whoWas)"
            >
              {{ whoWas.name }}
            </li>
          </ul>
        </div>
        <p class="text-center text-muted font-weight-600" v-if="noDataFound">
          {{ getTranslationByKey('map.who_was_there_sidebar.no_objects') }} <i class="fas fa-exclamation text-danger"></i>
        </p>
      </transition>
    </div>
    <calendar-range-modal
      :isModalVisible="showCalendar"
      @calendarClosed="calendarClosed"
      @selectedPeriod="selectedPeriod"
      v-if="showCalendar"
    >
    </calendar-range-modal>
    <history-block :object="object" :key="historyBlockKey" v-if="showHistory">
    </history-block>
  </div>
</template>

<script>
import CalendarRangeModal from "../global/CalendarRangeModal.vue";
import HistoryBlock from "../history/HistoryBlock.vue";
import moment from "moment";
import api from "../../api";
import map from "../../mixins/map";
import { EventBus } from "../../bus";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [map],
  components: {
    CalendarRangeModal,
    HistoryBlock,
  },
  data() {
    return {
      showCalendar: false,
      from: moment().subtract(1, "day").format("DD-MM-YYYY HH:mm:ss"),
      till: moment().format("DD-MM-YYYY HH:mm:ss"),
      searching: false,
      whoWasThere: [],
      noDataFound: false,
      shape: null,

      historyBlockKey: 0,
      showHistory: false,
      object: {},
    };
  },
  computed: {
    ...mapGetters(["getTranslationByKey", "getWhoWasChartPeriod", "user"]),
  },
  created() {
    EventBus.$emit("close-history-block");
    EventBus.$on("destroyShape", this.destroyShape);
  },
  mounted() {
    moment.locale(this.user.language)

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (Object.keys(params).length > 0) {
      this.from = moment(params.from).format("DD-MM-YYYY HH:mm:ss")
      this.till = moment(params.till).format("DD-MM-YYYY HH:mm:ss")
      let lat1 = +params.lat1 
      let lon1 = +params.lon1
      let lat2 = +params.lat2
      let lon2 = +params.lon2
      let center = { lat: lat1, lng: lon2 }

      const rectPositionToSave = {
        lat1,
        lat2,
        lon1,
        lon2,
        zoom: JSON.parse(localStorage.getItem('mapZoom')),
        center
      }

      localStorage.setItem('who-was-there-rect-position', JSON.stringify(rectPositionToSave));
      setTimeout(() => {this.shape = this.createRectangleShape()}, 700);
    } else {
      setTimeout(() => {this.shape = this.createRectangleShape()}, 700);
    }
  },
  methods: {
    ...mapActions(["setWhoWasChartPeriod", "resetReportPeriod"]),

    selectedPeriod(periodRange) {
      this.from = periodRange.start.toLocaleString();
      this.till = periodRange.end.toLocaleString();
    },
    calendarClosed(isShown) {
      return (this.showCalendar = isShown);
    },
    closeSidebar() {
      this.destroyShape();
      EventBus.$emit("closeWhoWasThere");
    },
    destroyShape() {
      if (this.shape !== null) {
        this.shape.setMap(null);
        this.shape = null;
      }
    },
    async getWhoWasThere() {
      this.searching = true;
      this.whoWasThere = [];
      const rectangle = this.shape.getBounds();
      const ne = rectangle.getNorthEast()
      const sw = rectangle.getSouthWest();
      await api
        .get("who-was-there", {
          params: {
            from: this.from,
            to: this.till,
            lat1: sw.lat(),
            lat2: ne.lat(),
            lon1: sw.lng(),
            lon2: ne.lng(),
          },
        })
        .then((response) => {
          this.whoWasThere = response.data.data;
          this.searching = false;
          this.whoWasThere.length
            ? (this.noDataFound = false)
            : (this.noDataFound = true);
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          console.error(error);
          this.searching = false;
        });
    },
    goToChart(whoWasThere) {
      this.resetReportPeriod();
      this.showHistory = true;
      let period = [
        moment(whoWasThere.first).format("DD-MM-YYYY HH:mm:ss"),
        moment(whoWasThere.last).format("DD-MM-YYYY HH:mm:ss"),
      ];
      this.setWhoWasChartPeriod(period);
      this.object = {
        vid: whoWasThere.vid,
        reg_no: whoWasThere.reg_no,
        priority_name: whoWasThere.name,
      };
      this.historyBlockKey++;
      this.destroyShape();
      EventBus.$emit("map-show-chart", true);
    },
  },
};
</script>
