<template>
  <div>
    <div id="page-reports-vue" class="page-container reports-container">
      <div class="reports-sidebar">
        <div class="list-unstyled">
          <!-- v-if="showRequestVideoEvidence" -->
          <router-link
            :to="{ name: 'ems' }"
            class="reports-sidebar-group-header"
          >
            <div class="icon-wrapper"><i class="fas fa-car"></i></div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{ getTranslationByKey('ems.video_request_title')}}</span>
              <span class="reports-sidebar-group-subtitle">{{ getTranslationByKey('ems.video_request_description') }}</span>
            </div>
          </router-link>
          <!-- v-if="showEvidenceLibrary" -->
          <router-link
            :to="{ name: 'evidence-library' }"
            class="reports-sidebar-group-header"
            v-if="false"
          >
            <div class="icon-wrapper"><i class="fas fa-calendar-alt"></i></div>
            <div class="content-wrapper d-flex flex-column flex-grow-1">
              <span class="minor-text">{{ getTranslationByKey('ems.video_library_title') }}</span>
              <span class="reports-sidebar-group-subtitle">
                {{ getTranslationByKey('ems.video_library_description') }}
              </span>
            </div>
          </router-link>
        </div>
      </div>
      <transition name="slide-right" v-if="showAll">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["user", "getTranslationByKey"]),
    showAll() {
      return true
      // return this.showRequestVideoEvidence || this.showEvidenceLibrary;
    },
    showRequestVideoEvidence() {
      return (
        this.user.permission &&
        this.user.permission["sm.ems.request_video_evidence"]
      );
    },
    showEvidenceLibrary() {
      return (
        this.user.permission && this.user.permission["sm.ems.evidence_library"]
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
