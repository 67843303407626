export default {
    state: {
        SOSMarker: {},
        oldSOSMarkerID: null
    },
    actions: {
        createSOSMarker({state, commit, getters}, { lat, lon, vid, reg_no }) {
            const latLon = {lat, lng: lon}
            const { map } = getters
            const address = `<span class="text-danger">SOS</span> - ${reg_no}`

            // remove or update marker
            if (Object.keys(state.SOSMarker).length > 0) {
                state.oldSOSMarkerID = Object.keys(state.SOSMarker)[0]
                if (state.oldSOSMarkerID == vid) {
                    commit('UPDATE_SOS_MARKER_POSITION', {latLon, markerID: state.oldSOSMarkerID})
                    return;
                } else {
                    commit("REMOVE_SOS_MARKER", state.oldSOSMarkerID)
                }
            }

            commit('ADD_MARKER', {latLon, id: vid, map, address, icon: getters.SOSIcon})
        }
    },
    mutations: {
        ADD_MARKER(state, { latLon, id, map, address, icon }) {
            state.SOSMarker[id] = new google.maps.Marker({
                position: latLon,
                map,
                icon: icon,
                animation: google.maps.Animation.DROP,
                id: id
            })

            map.setCenter(latLon)
            map.setZoom(14)


            let marker = state.SOSMarker[id]
            let content = address

            let infoWindow = new google.maps.InfoWindow({content})
            marker.addListener('click', () => {
                infoWindow.open(map, marker)
            })


            google.maps.event.addListener(infoWindow, 'closeclick', () => {
                marker.setMap(null)
                delete state.SOSMarker[marker.id]
            })
        },
        REMOVE_SOS_MARKER(state, markerID) {
            state.SOSMarker[markerID].setMap(null)
            delete state.SOSMarker[markerID]
        },
        UPDATE_SOS_MARKER_POSITION(state, {latLon, markerID}) {
            state.SOSMarker[markerID].setPosition(latLon)
        }
    },
    getters: {
        SOSIcon() {
            return {
                url: '/img/map/SOS-location.png',
                scaledSize: new google.maps.Size(50, 50)
            }
        }
    }
}