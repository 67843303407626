<template>
    <div class="video-placeholder" :class="isPIP ? 'pip' : ''">
        <div v-if="isRequested || isNew || isRequestedToClose">
            <i class="fas fa-sync-alt fa-spin"></i>
            <span>{{ placeholderDetails.message }}</span>
        </div>

        <div v-if="isError || unKnownError" class="failed">
            <div class="failed-text-wrapper">
                <i class="fas fa-exclamation-triangle mr-1"></i>
                <span>{{ placeholderDetails.message }}</span> 
            </div>
        </div>

        <div v-if="isClosed">
            <i class="fas fa-video-slash"></i>
            <span>{{ placeholderDetails.message }}</span>
        </div>

        <div v-if="isPIP" class="h-100">
            <i class="far fa-window-restore"></i>
            <span>{{ placeholderDetails.message }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            placeholderDetails: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                closing: false
            }
        },
        computed: {
            isNew() {
                return this.placeholderDetails.status === "new";
            },
            isRequested() {
                return this.placeholderDetails.status === "requested-to-stream";
            },
            isRequestedToClose() {
                return this.placeholderDetails.status === "requested-to-close";
            },
            isClosed() {
                return this.placeholderDetails.status === "closed";
            },
            isError() {
                return this.placeholderDetails.status === "error";
            },
            isPIP() {
                return this.placeholderDetails.status === "pip";
            },
            unKnownError() {
                return this.placeholderDetails.status === 'unknownError';
            }
        },
    }
</script>
<style lang="scss" scoped>
    .video-placeholder {
        height: calc(100% - 35px);
        & > div {
            background: black;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #b1bfcb;
            margin-bottom: 5px;
            font-size: 15px;
            border-radius: 5px;
            gap: 10px;
            padding-inline: 10px;
            text-align: center; 
        }
        .failed {
            background-size: cover;
            position: relative;
            padding: 0;
            img {
                border-radius: 5px;
                width: 100%;
                height: 100%;
            }
            .failed-text-wrapper {
                position: absolute;
                color: #ccc;
                background: rgba(0, 0, 0, .7);
                padding-block: 20px;
                height: 100%;
                width: 100%;
                border-radius: 5px;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 8px;
            }
        }
    }
    .pip {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
</style>
