<template>
  <div>
    <transition name="fade-up">
      <reports-criterias
        :criterias="neededCriterias"
        :reportName="getTranslationByKey('reports.side_menu.trip_sheet.title')"
        :isLoading="loading"
        @collectedCriterias="getData"
        v-show="!hasDataArrived"
      >
      </reports-criterias>
    </transition>
    <transition name="report-fade">
      <div
        class="report-view-wrapper"
        :class="{ 'no-sidebar': reportFullscreen }"
        v-if="hasDataArrived"
      >
        <!-- action buttons -->
        <ReportViewActionBar 
          :noDataFound="noDataFound" 
          :reportFullscreen="reportFullscreen" 
          :excelDownloading="excelDownloading" 
          :pdfDownloading="pdfDownloading"
          :neededCriterisIcon="neededCriterias.icon"
          :reportTitle="getTranslationByKey('reports.side_menu.trip_sheet.title')"
          @onToggleFullscreen="toggleReportFullscreen"
          @onPrintReport="printReport"
          @onDownloadExcel="downloadExcel"
          @onDownloadPDF="downloadPdf"
          @onReportClose="closeReport"
        />
        <!-- content -->
        <div class="report-content" ref="printReportContent" :class="reportFullscreen ? 'full-screen': ''">
          <div v-if="!noDataFound">
            <div class="pdf-header">
              <span>
                {{
                  getTranslationByKey('reports.side_menu.trip_sheet.title') +
                  " " +
                  (objects.length > 1 ? ", " : objects[0].name + ", ") +
                  todayDateForPrint
                }}
              </span>
              <span>SmartMonitor v{{ settings.version }}</span>
            </div>
            <h4 class="pdf-report-name">{{ getTranslationByKey('reports.side_menu.trip_sheet.title') }}</h4>
            <!-- TABLE HEADER -->
            <div class="table-header-big-info">
              <div class="report-info d-flex justify-content-between">
                <div>
                  <p class="company-name">{{ reportData.customer_name }}</p>
                  <p class="report-period">{{ formattedReportPeriod }}</p>
                </div>
                <div>
                  <p class="text-uppercase">
                    {{ reportData.objects[0].reg_no }},
                    {{ reportData.objects[0].make_model }}
                  </p>
                </div>
              </div>
              <div class="object-info-header">
                <!-- Odometer -->
                <div class="info-column">
                  <p>
                    <span>{{ getTranslationByKey('reports.table.odometer_begin') }}:</span>
                    <strong>
                      <span>
                        {{ removeZeros(objects[0].odometer_start) }}
                      </span>
                      <span>{{ getTranslationByKey('reports.table.kilometer') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.odometer_end') }}:</span>
                    <strong>
                      <span>{{ removeZeros(objects[0].odometer_end) }}</span>
                      <span>{{ getTranslationByKey('reports.table.kilometer') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.can_mileage') }}:</span>
                    <strong>
                      <span>{{ removeZeros(objects[0].odometer_mileage) }}</span>
                      <span>{{ getTranslationByKey('reports.table.kilometer') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.gps_mileage') }}:</span>
                    <strong>
                      <span>{{ removeZeros(objects[0].gps_mileage) }}</span>
                      <span>{{ getTranslationByKey('reports.table.kilometer') }}</span>
                    </strong>
                  </p>
                  <!-- <p>
                    <span>{{ getTranslationByKey('reports.table.number_of_trips') }}:</span>
                    <strong>
                      <span></span>
                      <span>{{ objects[0].total_trip_count }}</span>
                    </strong>
                  </p> -->
                  <p>
                    <span>{{ getTranslationByKey('reports.table.driving_days_count') }}:</span>
                    <strong>
                      <span></span>
                      <span>{{ objects[0].driving_days_count }}</span>
                    </strong>
                  </p>
                </div>
                <!-- Fuel level -->
                <div class="info-column">
                  <p>
                    <span>{{ getTranslationByKey('reports.table.fuel_type') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_type }}<span v-if="objects[0].fuel_code">, {{ objects[0].fuel_code }}</span></span>
                      <span></span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.tank_capacity') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_tank_capacity }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.fuel_level_begin') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_start }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.fuel_level_end') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_end }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.fuel_refueled') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_refueled }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                </div>
                <!-- usage -->
                <div class="info-column">
                  <p>
                    <span>{{ getTranslationByKey('reports.table.consumption_monthly_max') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_consumption_max_monthly }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.fuel_usage_norm') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_consumption_per_hour }}</span>
                      <span>{{ getTranslationByKey('reports.table.literhour') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.norm_heater') }}:</span>
                    <strong>
                      <span>{{
                        objects[0].fuel_consumption_webasto_norm
                      }}</span>
                      <span>{{ getTranslationByKey('reports.table.literhour') }}</span>
                    </strong>
                  </p>
                  <p>
                     <span>{{ getTranslationByKey('reports.table.total_fuel_used_norm') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_used_by_norm }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.fuel_used_fact') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_norm }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter100km') }}</span>
                    </strong>
                  </p>
                </div>
                <!-- consumption -->
                <div class="info-column">
                  <p>
                    <span>{{ getTranslationByKey('reports.table.consumption_driving') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_consumption_driving }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.consumption_idle') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_consumption_idle }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.consumption_heater') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_consumption_webasto }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.total_fuel_used') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_consumption_total }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter') }}</span>
                    </strong>
                  </p>
                  <p>
                    <span>{{ getTranslationByKey('reports.table.fuel_consumption_avg') }}:</span>
                    <strong>
                      <span>{{ objects[0].fuel_consumption_avg }}</span>
                      <span>{{ getTranslationByKey('reports.table.liter100km') }}</span>
                    </strong>
                  </p>
                </div>
                <div
                  v-if="settings.sm_report_trip_sheet_signature == true"
                  class="info-column"
                >
                  <div class="pdf-signature">
                    <div class="signature-row">
                      <span>{{ getTranslationByKey('reports.table.driver') }}</span>
                      <span>__________________</span>
                    </div>
                    <div class="signature-row">
                      <span>{{ getTranslationByKey('reports.table.responsible_person') }}</span>
                      <span>__________________</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- TABLE -->
            <template v-for="(day, index) in groupedDays">
              <div class="table-container" :key="index">
                <h5 class="report-date">{{ formatTableDay(day[0].date_start) }}</h5>
                <table  class="reports-table shadow-sm">
                  <thead>
                    <tr>
                      <th>{{ getTranslationByKey('reports.table.from') }}</th>
                      <th class="fixed-width">{{ getTranslationByKey('reports.table.departure') }}</th>
                      <th>{{ getTranslationByKey('reports.table.to') }}</th>
                      <th class="fixed-width">{{ getTranslationByKey('reports.table.arrival') }}</th>
                      <th>{{ getTranslationByKey('reports.table.driven_km') }}</th>
                      <th v-show="collectedData.colsCzAverageSpeed">
                        {{ getTranslationByKey('reports.table.average_speed') }}
                      </th>
                      <th v-show="collectedData.colsCzTripTime">
                        {{ getTranslationByKey('reports.table.trip_time') }}
                      </th>
                      <th v-show="collectedData.colsCzDriveTime">
                        {{ getTranslationByKey('reports.table.driven_time') }}
                      </th>
                      <th v-show="collectedData.colsCzStopTime">
                        {{ getTranslationByKey('reports.table.stop_time') }}
                      </th>
                      <th v-show="collectedData.colsCzParking">
                        {{ getTranslationByKey('reports.table.parking') }}
                      </th>
                      <th v-show="collectedData.colsCzTripReason">
                        {{ getTranslationByKey('reports.table.trip_reason') }}
                      </th>
                      <th v-show="collectedData.colsCzDriver">
                        {{ getTranslationByKey('reports.table.driver') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in day" :key="index"
                      @click="goToHistoryChart(row.date_start, row.time_start, row.time_end)">
                      <!-- start time -->
                      <td>{{ row.time_start }}</td>
                      <!-- location start -->
                      <td>
                        <b v-if="typeof row.location_start === 'object'
                          && row.location_start !== null
                          && row.location_start.geozone"
                          class="d-block">
                          {{row.location_start.geozone.name}}
                        </b>
                        <span v-if="typeof row.location_start === 'object'
                              && row.location_start !== null
                              && row.location_start.address"
                        >
                          {{row.location_start.address.address}}
                        </span>
                        <span v-else class="text-monospace text-secondary">
                          {{ row.location_start.coordinates.join(' ') || 'Not found'}}
                        </span>
                      </td>
                      <!-- end time -->
                      <td>{{ row.time_end }}</td>
                      <!-- location end -->
                      <td>
                        <b v-if="typeof row.location_end === 'object'
                          && row.location_end !== null
                          && row.location_end.geozone"
                          class="d-block">
                          {{row.location_end.geozone.name}}
                        </b>
                        <span v-if="typeof row.location_end === 'object'
                              && row.location_end !== null
                              && row.location_end.address"
                        >
                          {{row.location_end.address.address}}
                        </span>
                        <span v-else class="text-monospace text-secondary">
                          {{ row.location_end.coordinates.join(' ') || 'Not found'}}
                        </span>
                      </td>
                      <!-- driven KM -->
                      <td class="text-right">
                        {{
                          mileageByCan
                            ? formatNum(row.odometer_distance, "kilometer")
                            : formatNum(row.gps_distance, "kilometer")
                        }}
                      </td>
                      <!-- avg speed -->
                      <td v-show="collectedData.colsCzAverageSpeed">
                        {{ row.avg_speed }}
                      </td>
                      <!-- trip time -->
                      <td v-show="collectedData.colsCzTripTime">
                        {{ secondsToTime(row.trip_duration) }}
                      </td>
                      <!-- driven time -->
                      <td v-show="collectedData.colsCzDriveTime">
                        {{ secondsToTime(row.drive_duration) }}
                      </td>
                      <!-- stop time -->
                      <td v-show="collectedData.colsCzStopTime">
                        {{ secondsToTime(row.stop_duration) }}
                      </td>
                      <!-- parking time -->
                      <td v-show="collectedData.colsCzParking">
                        {{ row.parking_duration !== null ? secondsToTime(row.parking_duration) : '' }}
                      </td>
                      <!-- trip reason -->
                      <td v-show="collectedData.colsCzTripReason"></td>
                      <!-- driver name -->
                      <td v-show="collectedData.colsCzDriver">
                        {{ row.driver_name }}
                        <b class="d-block text-muted text-uppercase">{{ row.driver_ibutton }}</b>
                      </td>
                    </tr>
                    <!-- INFO FOOTER -->
                    <tr class="tfoot">
                      <td colspan="3">
                        {{ getTranslationByKey('reports.table.number_of_rows') }}:
                        <span>{{ day.length }}</span>
                      </td>
                      <td>{{ getTranslationByKey('reports.table.total') }}:</td>
                      <td class="text-right font-weight-bold">{{ totalDriven(day) }}</td>
                      <!-- total average speed -->
                      <td v-show="collectedData.colsCzAverageSpeed"></td>
                      <!-- total trip time -->
                      <td v-show="collectedData.colsCzTripTime">
                        <span>{{ totalTime(day, 'trip_duration') }}</span>
                      </td>
                      <!-- total driven time -->
                      <td v-show="collectedData.colsCzDriveTime">
                        <span>{{ totalTime(day, 'drive_duration') }}</span>
                      </td>
                       <!-- total stop time -->
                      <td v-show="collectedData.colsCzStopTime">
                        <span>{{ totalTime(day, 'stop_duration') }}</span>
                      </td>
                      <!-- total parking time -->
                      <td v-show="collectedData.colsCzParking">
                        <span>{{ totalParkingTime(day) }}</span>
                      </td>
                      <!-- trip reason -->
                      <td v-show="collectedData.colsCzTripReason"></td>
                      <!-- driver -->
                      <td v-show="collectedData.colsCzDriver"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <!-- Card transactions -->
            <CardTransactions 
              v-if="collectedData.transactions"
              :transactions="transactionsData"
            />
          </div>
          <div v-else class="no-data-found">
            <h3>
              <i class="fab fa-searchengin mr-1"></i
              >{{ getTranslationByKey('reports.table.no_data_found') }}
            </h3>
            <p class="text-muted font-weight-600 small">
              {{ getTranslationByKey('reports.table.other_obj') }}
            </p>
            <button class="btn btn-light" @click="hasDataArrived = false">
              <i class="fas fa-long-arrow-alt-left mr-1"></i
              >{{ getTranslationByKey('reports.table.back') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportsCriterias from "./ReportsCriterias.vue";
import ReportViewActionBar from "./ReportViewActionBar.vue";
import CardTransactions from "./CardTransactions.vue";
import api from "../../api";
import { mapGetters, mapActions } from "vuex";
import helper from "../../helper";
import { EventBus } from "../../bus";
import moment from "moment";
import { Printd } from "printd";
import { css } from "./print-report-css";

export default {
  components: {
    ReportsCriterias,
    ReportViewActionBar,
    CardTransactions
  },
  data() {
    return {
      neededCriterias: {
        icon: "fas fa-car",
        period: true,
        object: true,
        columnsCz: true,
        mileage: true,
        minTrip: true,
        minStop: true,
        transactions: true
      },
      collectedData: null,
      loading: false,
      pdfDownloading: false,
      excelDownloading: false,
      hasDataArrived: false,
      reportData: [],
      objects: [],
      todayDateForPrint: moment().format("DD-MM-YYYY HH:mm:ss"),
      reportFullscreen: false,
      error: null,
      groupedDays: null,
      days: [],
      transactionsData: []
    };
  },
  computed: {
    ...mapGetters(["settings",  "getTranslationByKey", "user"]),
    noDataFound() {
      return this.reportData.length == 0;
    },
    mileageByCan() {
      return this.collectedData.mileage === "1";
    },
    formattedReportPeriod() {
      moment.locale(this.user.language)
      return `${moment(this.reportData.timestamp_from * 1000).format("LLLL")} -
      ${moment(this.reportData.timestamp_till * 1000).format("LLLL")}`
    },
  },
  methods: {
    ...mapActions(["setReportPeriod", "setReportRoute"]),

    goToHistoryChart(startDate, startTime, endTime) {
      const formattedStartDate = moment(startDate, "YYYY-MM-DD").format('DD-MM-YYYY')
      let period = [
        moment(`${formattedStartDate} ${startTime}`, "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        moment(`${formattedStartDate} ${endTime}`, "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        ),
      ];
      this.setReportPeriod(period);
      this.setReportRoute("trip-sheet");
      this.$router.push({ name: "objects" }).catch(() => {});
      EventBus.$emit("openHistoryBlock", {
        vid: this.collectedData.vehiclesIds[0],
        priority_name: this.objects[0].name,
      });
      // exit report full screen to show navbar in objects page
      this.reportFullscreen = false;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    async getData(criterias) {
      this.collectedData = criterias;
      this.error = null
      try {
        this.loading = true;
        const response = await api
          .get("report/trip-sheet-v2", {
            params: {
              from: moment(this.collectedData.periodFrom, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
              to: moment(this.collectedData.periodTill, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
              mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
              min_stop_time: this.collectedData.minTripTime,
              min_parking_time: this.collectedData.minStopTime,
              vehicle_ids: this.collectedData.vehiclesIds,
              transactions: this.collectedData.transactions
            },
          })
        this.reportData = response.data.data;
        this.objects = this.reportData.objects;
        this.transactionsData = this.reportData.transactions.length ? this.reportData.transactions : [];

        this.groupedDays = this.objects[0].points.reduce((acc, point) => {
          const key = point.date_start
          const curGroup = acc[key] ?? []

          return {...acc, [key]: [...curGroup, point]}
        }, {})

        // hide last row [parking_duration]
        for (let prop in this.groupedDays) {
          let row = this.groupedDays[prop]
          row[row.length - 1].parking_duration = null
        }
        this.hasDataArrived = true;
        this.loading = false;
      } catch (e) {
        this.handleErrorMixin(e)
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    downloadExcel() {
      this.excelDownloading = true;
      api
        .get("report/export-excel", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
            min_stop_time: this.collectedData.minTripTime,
            min_parking_time: this.collectedData.minStopTime,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "trip-sheet",
            // criteria
            avg_speed: this.collectedData.colsCzAverageSpeed,
            trip_duration: this.collectedData.colsCzTripTime,
            drive_duration: this.collectedData.colsCzDriveTime,
            stop_duration: this.collectedData.colsCzStopTime,
            parking_duration: this.collectedData.colsCzParking,
            trip_reason: this.collectedData.colsCzTripReason,
            driver_name: this.collectedData.colsCzDriver,
            transactions: this.collectedData.transactions
          },
        })
        .then((response) => {
          this.excelDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName =
            this.objects.length > 1 ? "" : this.objects[0].name;
          link.download = `${
            this.getTranslationByKey('reports.side_menu.trip_sheet.title')
          } ${objectName} ${moment(
            this.collectedData.periodFrom,
            "DD-MM-YYYY HH:mm:ss"
          ).format("YYYYMMDD HHmmss")} - ${moment(
            this.collectedData.periodTill,
            "DD-MM-YYYY HH:mm:ss"
          ).format("YYYYMMDD HHmmss")}.xlsx`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error);
          this.excelDownloading = false;
          console.error(error);
        });
    },
    downloadPdf() {
      this.pdfDownloading = true;
      api
        .get("report/export-pdf", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
            min_stop_time: this.collectedData.minTripTime,
            min_parking_time: this.collectedData.minStopTime,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "trip-sheet",
            // criteria
            avg_speed: this.collectedData.colsCzAverageSpeed,
            trip_duration: this.collectedData.colsCzTripTime,
            drive_duration: this.collectedData.colsCzDriveTime,
            stop_duration: this.collectedData.colsCzStopTime,
            parking_duration: this.collectedData.colsCzParking,
            trip_reason: this.collectedData.colsCzTripReason,
            driver_name: this.collectedData.colsCzDriver,
          },
        })
        .then((response) => {
          this.pdfDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName =
            this.objects.length > 1 ? "" : this.objects[0].name;
          link.download = `${
            this.getTranslationByKey('reports.side_menu.trip_sheet.title')
          } ${objectName} ${moment(
            this.collectedData.periodFrom,
            "DD-MM-YYYY HH:mm:ss"
          ).format("YYYYMMDD HHmmss")} - ${moment(
            this.collectedData.periodTill,
            "DD-MM-YYYY HH:mm:ss"
          ).format("YYYYMMDD HHmmss")}.pdf`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          this.pdfDownloading = false;
          console.error(error);
        });
    },
    printReport() {
      const cssText = css;
      const d = new Printd();
      d.print(this.$refs.printReportContent, [cssText]);
    },
    formatNum(value, unit) {
      return helper.formatNum(value, unit)
    },
    secondsToTime(seconds) {
      return moment.utc(seconds * 1000).format('HH:mm:ss');
    },
    formatTableDay(date) {
      return moment(date, "YYYY-MM-DD").format("LL")
    },
    totalDriven(day) {
      const odometerOrGPS = this.mileageByCan ? 'odometer_distance' : 'gps_distance'
      const total = day.reduce((total, item) => {
        return total + item[odometerOrGPS]
      }, 0)
      return helper.formatNum(total, "kilometer")
    },
    totalTime(day, prop) {
      const total = day.reduce((total, item) => {
        return total + item[prop]
      }, 0)
      return this.secondsToTime(total)
    },
    totalParkingTime(day) {
      // const startTimeToSeconds = moment(day[0].time_start, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds')
      const total = day.reduce((total, item) => { return total + item['parking_duration'] }, 0)
      return this.secondsToTime(total)
    },
    closeReport() {
      this.hasDataArrived = false;
      this.reportFullscreen = false;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    toggleReportFullscreen() {
      this.reportFullscreen = !this.reportFullscreen;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    removeZeros(n) {
      return helper.removeZeros(n)
    }
  },
};
</script>
