<template>
  <a
    :href="downloadLink"
    class="btn btn-sm btn-success td-element d-flex align-items-center justify-content-center"
  >
    <i v-if="downloadLoader" class="fa fa-spinner fa-spin"></i>
    <span v-else>{{ getTranslationByKey('ems.action.download') }}</span>
  </a>
</template>

<script>
import api from "../../api";
import {mapGetters} from "vuex";

export default {
  props: {
    rowValue: Object,
  },
  data() {
    return {
      downloadLoader: false,
    };
  },
  computed: {
    ...mapGetters([
      'getTranslationByKey',
      'accessToken'
    ]),
    downloadLink() {
      return `${this.rowValue.urlDownload}?token=${this.accessToken}`
    }
  }
};
</script>
