<template>
  <div class="page-container devices-page">
    <div class="device-sidebar shadow" ref="deviceSidebar">
      <!-- filter -->
      <div class="filter-frost shadow-sm">
        <input
            type="text"
            :placeholder="getTranslationByKey('history.find')"
            v-model="filterText"
            ref="filterInput"
        />
        <i class="fas fa-filter filter-icon"></i>
        <i
            class="fa fa-times remove-filter-icon"
            v-show="filterText.length"
            @click="removeFilter"
        >
        </i>
      </div>
      <!-- loader -->
      <div class="text-center my-3" v-if="!objects.length">
        <i class="fa fa-spinner fa-spin text-primary"></i>
      </div>
      <ul class="list-unstyled devices-list">
        <li v-for="object in filterdObjects" :key="object.vid">
          <input
              type="checkbox"
              :id="object.vid"
              :value="object.vid"
              v-model="checkedObjects"
              @change="getObjectChart(object.vid, $event)"
          />
          <label :for="object.vid">{{ object.priority_name }}</label>
        </li>
      </ul>
    </div>
    <!-- chart container -->
    <transition name="fade">
      <div class="device-chart-container" v-if="checkedObjects.length > 0">
        <h2 class="device-name">{{ objectName }}</h2>
        <div id="chart-wrapper" class="chart-wrapper shadow mb-3">
          <history-loader :loadData="loading"></history-loader>
        </div>
        <!-- History -->
        <div class="history-header mb-3">
          <p><i class="fas fa-history mr-1"></i>{{ getTranslationByKey('history.history') }}</p>
          <date-picker type="datetime"
                       v-model="chartPeriod"
                       range
                       format="DD-MM-YYYY HH:mm:ss"
                       :lang="datePickerLang"
                       :show-time-panel="showTimeRangePanel"
                       :open.sync="open"
                       :confirm="true"
                       range-separator=" - "
                       @close="handleRangeClose"
          >
            <template v-slot:footer>
              <button class="mx-btn mx-btn-text" @click="toggleTimeRangePanel">
                {{ showTimeRangePanel 
                  ? getTranslationByKey('history.select_date') 
                  : getTranslationByKey('history.select_time') 
                }}
              </button>
            </template>
          </date-picker>
        </div>
        <div id="history-chart-wrapper"
             class="history-chart-wrapper shadow mb-4" v-if="chartWrapper == 'history-chart-wrapper'">
          <history-loader :loadData="loading"></history-loader>
        </div>
      </div>
    </transition>
    <p class="select-device" v-if="!checkedObjects.length">
      {{ getTranslationByKey('history.select_device') }}
    </p>
  </div>
</template>

<script>
import highcharts from "highcharts";
import moment from "moment";
import api from "../api";
import {mapGetters} from "vuex";
import HistoryLoader from '../components/global/HistoryLoader.vue'

export default {
  components: {
    HistoryLoader
  },
  data() {
    return {
      objects: [],
      data: {0: [], 1: [], 2: [], 3: [], 4: []},
      checkedObjects: [],
      filterText: '',
      objectName: '',

      loading: false,
      isObjectChecked: false,
      datePickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
        }
      },
      chartWrapper: 'chart-wrapper',
      chartPeriod: [moment().subtract(1, "days").toDate(), moment().toDate()],
      showTimeRangePanel: false,
      open: false,
    }
  },
  computed: {
    ...mapGetters(["getTranslationByKey"]),
    filterdObjects() {
      const filtered = this.objects.filter((obj) => {
        return (
            obj.priority_name
                .toLowerCase()
                .indexOf(this.filterText.toLowerCase()) > -1
        );
      });
      return filtered;
    },
  },
  created() {
    this.getObjects();

    const test = false;
    if (test) {
      setInterval(() => {
        this.getObjectChart();
      }, 1000 * 1 * 60); // 1min
    }
  },
  mounted() {
    this.$refs.filterInput.focus();
  },
  methods: {
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleRangeClose() {
      if (this.chartPeriod[0].getDate() === this.chartPeriod[1].getDate() &&
          this.chartPeriod[0].getHours() === 0) {
        this.chartPeriod[0] = moment(this.chartPeriod[0]).startOf('day').toDate();
        this.chartPeriod[1] = moment(this.chartPeriod[1]).endOf('day').toDate();
      }
      this.showTimeRangePanel = false;
      this.chartWrapper = 'history-chart-wrapper';
      this.getObjectChart(this.checkedObjects[0]);
    },
    async getObjects() {
      api.get("objects")
          .then((response) => {
            this.objects = response.data.data;
          })
          .catch((error) => {
            this.handleErrorMixin(error)
            console.error(error);
          });
    },
    async getObjectChart(objectId, event) {
      if (event) {
        this.isObjectChecked = event.target.checked
        // reset to default
        this.chartWrapper = 'chart-wrapper'
        this.chartPeriod = [moment().subtract(1, "days").toDate(), moment().toDate()]
      }
      // to select only one checkbox
      if (this.checkedObjects.length > 1) {
        this.checkedObjects.splice(0, 1);
      }
      this.objectName = this.objects.find(
          (obj) => obj.vid == objectId
      ).priority_name;

      this.data = {0: [], 1: [], 2: [], 3: [], 4: []};
      this.loading = true;
      if (this.isObjectChecked) {
        await api.get("history", {
              params: {
                from: moment(this.chartPeriod[0]).format("YYYY-MM-DD HH:mm:ss"),
                to: moment(this.chartPeriod[1]).format("YYYY-MM-DD HH:mm:ss"),
                vehicle_id: objectId,
              },
            })
            .then((response) => {
              this.loading = false;
              const responseData = response.data.data;

              responseData.forEach((item) => {
                let timestamp = moment(item.tx_date + " " + item.tx_time).valueOf();

                if (item.temp_0.temperature) {
                  this.data[0].push({
                    x: timestamp,
                    y: parseFloat(item.temp_0.temperature),
                  });
                }
                if (item.temp_1.temperature) {
                  this.data[1].push({
                    x: timestamp,
                    y: parseFloat(item.temp_1.temperature),
                  });
                }
                if (item.temp_2.temperature) {
                  this.data[2].push({
                    x: timestamp,
                    y: parseFloat(item.temp_2.temperature),
                  });
                }
                if (item.temp_3.temperature) {
                  this.data[3].push({
                    x: timestamp,
                    y: parseFloat(item.temp_3.temperature),
                  });
                }
                if (item.temp_4.temperature) {
                  this.data[4].push({
                    x: timestamp,
                    y: parseFloat(item.temp_4.temperature),
                  });
                }
              });
              this.drawChart();
            })
            .catch((error) => {
              this.handleErrorMixin(error)
              console.error(error);
            });
      }

    },
    removeFilter() {
      this.filterText = "";
      this.$refs.deviceSidebar.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    drawChart() {
      let series = [];
      for (let i = 0; i < Object.keys(this.data).length; i++) {
        const data = this.data[i];
        if (data.length) {
          series.push({
            type: "spline",
            title: "&deg;C",
            name: "Temp " + i,
            lineWidth: 1,
            connectNulls: true,
            data: data,
          });
        }
      }

      highcharts.chart(this.chartWrapper, {
        chart: {
          zoomType: "x",
        },
        title: {
          text: "",
        },
        time: {
          useUTC: false,
        },
        xAxis: {
          type: "datetime",
          gridLineWidth: 1,
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: true,
          },
          series: {
            boostThreshold: 10000,
            turboThreshold: 0,
          },
        },
        series: series,
      });
    },
  },
};
</script>
