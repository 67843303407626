<template>
  <div
    id="objects-sidebar"
    class="page-objects-sidebar bg-white"
    :class="{ 'no-sidebar': !showSidebar }"
  >
    <button class="btn btn-sm d-flex menu-trigger" @click="toggleSidebar">
      <i class="fas fa-exchange-alt"></i>
    </button>
    <div class="objects-list-wrapper">
      <object-toolbar></object-toolbar>
      <div class="objects-list shadow noselect" ref="objectsList">
        <div
          v-for="object in objectsList"
          :key="object.vid"
          class="objects-item-wrapper"
        >
          <!-- object list item -->
          <div
            class="objects-list-item"
            :class="{ 'is-closed': !object.showCard }"
          >
            <div class="objects-card-header">
              <!-- object card header -->
              <object-info-header :object="object"></object-info-header>
              <!-- dropdown arrow -->
              <div
                class="show-obj-info-button"
                @click="toggleAccordion(object, $event)"
              >
                <i
                  class="fas fa-chevron-down arrow-down"
                  :class="{ show: object.showCard }"
                ></i>
              </div>
            </div>
          </div>
          <!-- object card content -->
          <object-info-body
            :object="object"
            v-show="object.showCard"
          ></object-info-body>
        </div>
        <!-- / v-for -->
        <div class="objects-loading" v-if="loading">
          <i class="fas fa-spinner fa-spin mr-2 text-primary"></i>
          <span class="small font-italic font-weight-bold text-muted">{{
            getTranslationByKey('vehicles.loading')
          }}</span>
        </div>
      </div>
      <!-- / objects-list container -->

      <!-- No objects -->
      <div
        class="no-filtered-objects"
        v-if="!objectsList.length && loadingStatus == false"
      >
        <button class="btn btn-outline-dark" @click="openObjectFilter">
          <i class="fas fa-filter"></i>
          {{ getTranslationByKey('vehicles.objects') }}
        </button>
        {{ getTranslationByKey('vehicles.select_objects') }}
      </div>
    </div>
  </div>
</template>

<script>
import ObjectToolbar from "./ObjectToolbar.vue";
import ObjectInfoHeader from "./ObjectInfoHeader.vue";
import ObjectInfoBody from "./ObjectInfoBody.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "../../bus";
import api from "@/api";
import EMYToastCustom from "../global/EMYToastCustom.vue";
export default {
  components: {
    ObjectToolbar,
    ObjectInfoHeader,
    ObjectInfoBody,
    EMYToastCustom
  },
  data() {
    return {
      EMYMarkers: {},
      EMYInfoWindow: {}
    };
  },
  computed: {
    ...mapGetters([
      "settings",
      "filteredVehicles",
      "all",
      "loadingStatus",
      "getTranslationByKey",
      "user",
      "showSidebar",
      "map",
      "getUid",
      "accessToken"
    ]),
    objectsList() {
      return this.filteredVehicles || this.all;
    },
    loading() {
      return this.loadingStatus;
    },
    objectsIds() {
      const objects = this.filteredVehicles || this.all;
      return objects.map((object) => object.vid);
    },
    isEMYNotificationSingle() {
      return this.settings.sm_notification_dialog === 'single'
    },
    userTokenId() {
      return parseInt(this.accessToken.split("|")[0]);
    }
  },
  async created() {
    this.$pusher.subscribe(`presence-lobby`);
    const userChannels = this.$pusher.subscribe(`private-user.${this.user.uid}`);

    this.WSForceLogout(userChannels);

    if (this.settings.is_emy) {
      this.WSEMYDataSubscription();
      this.WSCloseEMYPopup(userChannels);
    }
    EventBus.$emit("destroyShape");
  },
  mounted() {
    /* emitted from MapToolbar when switch on share
      location button to remove EMYInfoWindow and marker if any
    */
    EventBus.$on("close-EMY-infoWindow", () => {
       if (Object.keys(this.EMYMarkers).length !== 0) {
        this.EMYRemoveMarkerAndInfoWindow();
      }
    });

    let unwatchObjectIds = this.$watch('objectsIds', newVal => {
      this.WSObjectsSubscription(this.objectsIds)
      unwatchObjectIds()
    })
  },
  methods: {
    ...mapActions([
      "addInterval",
      "toggleAppSidebar",
      "createAddressMarker",
      "panTo",
      "createEMYSingleMarker",
      "removeEMYSingleMarker",
      "createEMYMarker",
      "removeEMYMarker",
      "WSObjectsSubscription",
      "killSession"
    ]),
    ...mapMutations([
      "setAuth",
      "SET_USER_SESSION_TIMEOUT",
      "SHOW_HIDE_SESSION_POPUP"
    ]),
    toggleSidebar() {
      this.toggleAppSidebar();
      EventBus.$emit("resizeChart");
    },
    toggleAccordion(object, e) {
      object.showCard = !object.showCard;
      let element = e.target;
      let topPos = element.getBoundingClientRect().top + window.scrollY;
      let objectListContainer = this.$refs.objectsList;

      if (topPos > objectListContainer.offsetHeight - 20) {
        setTimeout(() => {
          objectListContainer.scrollBy({
            top: element.closest(".objects-item-wrapper").clientHeight,
            behavior: "smooth",
          });
        }, 200);
      }
      this.$audit({ object_id: object.vid, action: "object_card_toggle" });
    },
    WSEMYDataSubscription() {
      let chanel = this.$pusher.subscribe(`private-emy.${this.getUid}`)
      chanel.bind('location', data => {
        if (data) {
          const { location } = data
          const latLon = { lat: location.lat, lng: location.lon };
          if (this.isEMYNotificationSingle) {
            let singleMarker = this.$store.state.EMYMarkers.EMYSingleMarker

            if (Object.keys(singleMarker).length > 0) {
              let oldSingleMarkerID = Object.keys(singleMarker)[0]
              this.$toast.dismiss(oldSingleMarkerID)
              this.createEMYSingleMarker({data: location, map: this.map})
              this.EMYToastMessages(location)
              this.EMYGoToPoint(latLon)
              return;
            }
            this.createEMYSingleMarker({data: location, map: this.map})
          } else {
            this.createEMYMarker({data: location, map: this.map})
          }

          this.EMYToastMessages(location)
          this.EMYGoToPoint(latLon)

        }
      })
    },
    async EMYToastMessages(data) {
      if (data) {
        const content = {
          component: EMYToastCustom,
          props: {
            data,
            transGoToPoint: this.getTranslationByKey('map.go_to_point'),
            transClose: this. getTranslationByKey('vehicles.close')
          },
          listeners: {
            goToPoint: (latLon, id) => {
              if (this.$route.name !== 'objects') {
                this.$router.push({ name: 'objects'})
              }
              this.EMYGoToPoint(latLon)
            }
          }
        }
        const toastOption = {
          position: 'top-right',
          icon: false,
          closeButton: false,
          timeout: false,
          closeOnClick: false,
          toastClassName: 'emy-custom-toast',
          id: data.requestId,
          onClose: (id) => {
            if (this.isEMYNotificationSingle) {
              this.EMYRequestToClosePopup(data.requestId)
              this.removeEMYSingleMarker(data.requestId)
            } else {
              this.EMYRequestToClosePopup(data.requestId)
              this.removeEMYMarker(data.requestId)
            }
          }
        }
        this.$toast(content, toastOption);
      }
    },
    async EMYGoToPoint(latLon, id) {
      this.map.setCenter(latLon)
      this.map.setZoom(14)
    },
    async EMYRequestToClosePopup(requestId) {
      try {
        const response = await api.delete(`notification/${requestId}`)
      } catch (e) {
        this.handleErrorMixin(e)
      }
    },
    WSCloseEMYPopup(channel) {
      channel.bind("popup-close", (data) => {
        this.$toast.dismiss(data.popup.id);
      });
    },
    WSForceLogout(channel) {
      channel.bind("force-logout-by-token", (data) => {
        const { token_id } = data;
        if (this.userTokenId === token_id) {
          this.killSession();
        } 
      });

      channel.bind('notify-logout-by-token', (data) => {
        const { token_id,  timeout_in_sec } = data;
        if (this.userTokenId === token_id) {
          this.SHOW_HIDE_SESSION_POPUP(true)
          this.SET_USER_SESSION_TIMEOUT(timeout_in_sec);
        }
      })
    },
    EMYRemoveMarkerAndInfoWindow() {
      this.EMYMarkers.setMap(null);
      this.EMYMarkers = {};
      this.EMYInfoWindow.close();
    },
    openObjectFilter() {
      EventBus.$emit("open-object-filter");
    },
  },
};
</script>
