<template>
  <div class="user-edit-info info-window">
    <!-- header -->
    <div class="sticky d-flex align-items-center justify-content-between border-0 mb-1 px-2 shadow-sm">
      <i 
          class="fa fa-chevron-circle-right close-slide-container-icon"
          @click="closeComponent"
      ></i>
      
      <h5 class="slide-heading flex-grow-1 text-center">
          <i class="fas fa-cogs mr-1 text-success"></i>
          {{ getTranslationByKey('navbar.user_dropdown.preferences.preferences')  }}
      </h5>
    </div>
    <!-- Pass change -->
    <fieldset class="personal-info mt-3" v-if="showPasswordChange">
      <legend class="bg-white rounded shadow-sm">
        <i class="fa fa-key mr-1"></i>
        {{ getTranslationByKey('navbar.user_dropdown.preferences.change_pass') }}
      </legend>
      <form action="" @submit.prevent="changePass">
        <!-- old pass -->
        <div class="form-group">
          <label for="old-pass" class="minor-text-small">
            {{ getTranslationByKey('navbar.user_dropdown.preferences.old_pass') }}
          </label>
          <input
              id="old-pass"
              :type="showOldPass ? 'text': 'password'"
              class="form-control form-control-sm"
              v-model="oldPassword"
          />
          <i class="fa show-hide-pass" 
            :class="[showOldPass ? 'fa-eye-slash': 'fa-eye', oldPassword ? 'show-icon': '']"
            @click="showOldPass = !showOldPass"
          ></i>
        </div>
        <!-- new pass -->
        <div class="form-group">
          <label for="new-pass" class="minor-text-small">
            {{ getTranslationByKey('navbar.user_dropdown.preferences.new_pass') }}
          </label>
          <input
              id="new-pass"
              :type="showNewPass ? 'text': 'password'"
              class="form-control form-control-sm"
              v-model="newPassword"
          />
          <i class="fa show-hide-pass" 
            :class="[showNewPass ? 'fa-eye-slash': 'fa-eye', newPassword ? 'show-icon': '']"
            @click="showNewPass = !showNewPass"
          ></i>
        </div>
        <!-- repeat pass -->
        <div class="form-group">
          <label for="retype-pass" class="minor-text-small">
            {{ getTranslationByKey('navbar.user_dropdown.preferences.re_pass') }}
          </label>
          <input
              id="retype-pass"
              :type="showRepeatPass ? 'text': 'password'"
              class="form-control form-control-sm"
              v-model="repeatPassword"
          />
          <i class="fa show-hide-pass" 
            :class="[showRepeatPass ? 'fa-eye-slash': 'fa-eye', repeatPassword ? 'show-icon': '']"
           
            @click="showRepeatPass = !showRepeatPass"
          ></i>
        </div>
        <button class="btn btn-info btn-block mt-3"
          type="submit"
        >
          <i class="fa mr-1" :class="[passLoader ? 'fa-spinner fa-spin': 'fa-check']"></i>
          {{ getTranslationByKey('navbar.user_dropdown.preferences.change_pass') }}
        </button>
      </form>
    </fieldset>

    <!-- Language -->
    <fieldset class="time-lang">
      <legend class="lang-info bg-white rounded shadow-sm">
        <i class="fas fa-globe-americas mr-1"></i>
        {{ getTranslationByKey('navbar.user_dropdown.preferences.language') }}
      </legend>
      <!-- Interface lang -->
      <form action="" @submit.prevent="setLang">
        <h5 class="mb-1 font-weight-bold">
          {{ getTranslationByKey('navbar.user_dropdown.preferences.interface_lang') }}
        </h5>
        <div class="mb-2">
          <div class="d-flex justify-content-between">
            <div
                v-for="(lang, index) in languagesAvaliable"
                :key="index"
                class="custom-control custom-radio mr-2"
            >
              <input
                  :id="`${lang}-label`"
                  type="radio"
                  :value="lang"
                  class="custom-control-input js-interface-lang"
                  v-model="user.language"
                  @change="switchLang(lang)"
              />
              <label class="custom-control-label" :for="`${lang}-label`">
                <img :src="`/img/flags/${lang}.png`"/>
              </label>
            </div>
          </div>
        </div>
        <button class="btn btn-info btn-block mt-3"
        >
          <i class="fa mr-1" :class="[langLoader ? 'fa-spinner fa-spin': 'fa-check']"></i>
          {{ getTranslationByKey('navbar.user_dropdown.preferences.change_lang') }}
        </button>
      </form>
    </fieldset>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import api from "../../api";

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
      passLoader: false,
      langLoader: false,
      showOldPass: false,
      showNewPass: false,
      showRepeatPass: false
    };
  },
  computed: {
    ...mapGetters(["settings", "getTranslationByKey", "user"]),
    languagesAvaliable() {
      return this.settings.sm_language_available.split(",");
    },
    showPasswordChange() {
      return !this.settings.is_vp;
    }
  },
  methods: {
    async switchLang(lang) {
      localStorage.setItem('selectedLanguage', lang);
    },
    ...mapActions(["getTranslations"]),
    async changePass() {
      this.passLoader = true
      if (this.oldPassword === '') {
        this.passLoader = false
        return;
      }
      try {
        const response = await api.patch('user', {
          old_password: this.oldPassword,
          new_password: this.newPassword,
          repeat_password: this.repeatPassword,
        })
        this.langLoader = false
        this.$emit('close-component')
        setTimeout(() => {window.location.reload()}, 2000)
      } catch(e) {
        this.passLoader = false
        this.handleErrorMixin(e)
      }
    },
    async setLang() {
      this.langLoader = true
      let lang = localStorage.getItem('selectedLanguage')
      try {
        const response = await api.patch('user', {
          language: lang
        })

        this.langLoader = false
        await this.getTranslations(lang);
        this.$emit('close-component')
      } catch(e) {
        this.langLoader = false
        this.handleErrorMixin(e)
      }
    },
    closeComponent() {
      this.$emit("close-component");
    },
  },
};
</script>
