<template>
    <div class="collapse-wrapper shadow-sm border">
        <header 
            class="collapse-header px-3" 
            :class="item.open ? 'border-bottom' : ''" 
            @click="$emit('handleCollapseOnClick', index)"
        >
            <span class="small font-weight-600 mr-2">
                <i class="fas mr-2 text-muted" :class="item.icon"></i>
                {{ translateHeader }}
            </span>
            <span 
                v-if="item.selectedValue != null"
                class="small text-primary text-monospace text-truncate"
                :class="item.type === 'description' ? 'description-width': ''"
            >
                {{ handleSelectedValue }} {{ item.valueToTranslate ? translateSelectedCameras: '' }} 
                {{ item.mic ? getTranslationByKey(`ems.audio_type.${item.mic}`): '' }}
            </span>
        </header>
        <main :class="item.open ? 'collapse-content open': 'collapse-content'">
            <slot></slot>
        </main>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        props: {
            item: {
                type: Object
            },
            index: {
                type: Number
            }
        },
        computed: {
            ...mapGetters(['getTranslationByKey', 'getGuidedShowProgress']),
            translateHeader() {
                let translatedHeader;
                switch(this.item.type) {
                    case 'select-object':
                        translatedHeader = this.getTranslationByKey('reports.criterias.object');
                        break;
                    case 'download-type':
                    translatedHeader = this.getTranslationByKey('ems.download_type');
                        break;
                    case 'blind':
                        translatedHeader = this.getTranslationByKey('ems.period');
                        break;
                    case 'guided':
                        if (this.getGuidedShowProgress) {
                            translatedHeader = this.getTranslationByKey('ems.requesting_data');
                        } else {
                            translatedHeader = this.getTranslationByKey('ems.period');
                        }
                        break;
                    case 'description':
                        translatedHeader = this.getTranslationByKey('reports.card_transactions_table.table_description');
                        break;
                    default:
                }
                return translatedHeader;
            },
            translateSelectedCameras() {
                let transCameras;
                switch(this.item.type) {
                    case 'blind':
                    case 'guided':
                        transCameras = this.item.valueToTranslate.map(v => this.getTranslationByKey(`ems.source_type.${v}`)).join(',');
                        break;
                    default:
                }
                return transCameras;
            },
            handleSelectedValue() {
                if (this.item.type == 'download-type') {
                    return this.getTranslationByKey(`ems.type_${this.item.selectedValue}`)
                } else {
                    return this.item.selectedValue
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .collapse-wrapper {
        border-radius: 5px;
        .collapse-header {
            padding: 15px;
            cursor: pointer;
            border-radius: 6px 6px 0 0;
            display: flex;
            align-items: baseline;

            .description-width {
                width: 150px;
            }
        }
        .collapse-content {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s ease-out;

            &.open {
                opacity: 1;
                max-height: 9000px;
            }
        }
    }
</style>