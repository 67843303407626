<template>
  <div
    class="chart-loading"
    v-show="loadData || noData"
    :class="{ 'no-data': noData }"
  >
    <i
      class="fas mr-2"
      :class="{
        'fa-spinner': loadData,
        'fa-exclamation': noData,
        'fa-spin': loadData,
      }"
    ></i>
    <p class="text-muted mb-0">
      {{ loadData ? getTranslationByKey('history.loading_data') : getTranslationByKey('history.no_data') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    loadData: Boolean,
    noData: Boolean,
  },
  computed: {
    ...mapGetters(["getTranslationByKey"]),
  },
};
</script>
