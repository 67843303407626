<template>
    <div>
      <nav
        class="navbar navbar-expand navbar-light app-bar justify-content-end"
        :class="{ 'no-nav': hideNav }"
      >
        <div id="navbarSupportedContent" class="collapse navbar-collapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item small app-bar-menu-tab" v-if="showObjects">
              <router-link :to="{ name: 'objects' }" class="nav-link">
                <i class="fa fa-car mr-1"></i>
                {{ getTranslationByKey("navbar.nav_menu.objects") }}
              </router-link>
            </li>
            <li
              v-if="user.customer_module == 'subzero'"
              class="nav-item small app-bar-menu-tab"
            >
              <router-link :to="{ name: 'devices' }" class="nav-link">
                <i class="fas fa-tablet-alt mr-1"></i>
                {{ getTranslationByKey('navbar.nav_menu.devices') }}
              </router-link>
            </li>
            <!-- <li class="nav-item small app-bar-menu-tab" v-if="showGeozones">
              <router-link :to="{ name: 'points' }" class="nav-link">
                <i class="fas fa-map-marked-alt mr-1"></i>
                {{ getTranslationByKey('navbar.nav_menu.points') }}
              </router-link>
            </li> -->
            <li class="nav-item small app-bar-menu-tab" v-if="showReports">
              <router-link
                :to="{
                  name:
                    user.customer_module == 'subzero'
                      ? 'smart-temp'
                      : 'trip-sheet',
                }"
                class="nav-link"
              >
                <i class="fas fa-chart-line mr-1"></i>
                {{ getTranslationByKey('navbar.nav_menu.reports') }}
              </router-link>
            </li>

            <li class="nav-item small app-bar-menu-tab" v-if="showEms">
              <router-link
                  :to="{name: 'ems'}"
                  class="nav-link"
              >
                <i class="fas fa-film mr-1"></i>
                <span v-html="getTranslationByKey('navbar.nav_menu.ems')"></span>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- <div class="mr-3">
          <button class="btn btn-light btn-sm text-secondary" @click="showHideStreamContainer">
            <span v-text="streamCount" v-if="streamCount > 0"
              class="stream-count text-success"
            ></span>
            <i class="fas fa-video text-success mr-1"></i>
            {{ getTranslationByKey('navbar.nav_menu.video_stream') }}
          </button>
        </div> -->
        <!-- user drowpdown inf -->
        <div class="user-info-dropdown">
          <div
            class="user-info"
            @click="toggleDropdown"
            v-click-outside="hideDropdownMenu"
          >
            <div class="username-company">
              <div class="text-white user-row-info">
                <i class="fa fa-user fa-sm"></i>
                <span id="app-bar-username">{{ userName }}</span>
              </div>
  
              <div
                class="user-departament user-row-info mt-1"
                v-if="settings.sm_hide_company_name"
              >
                <i class="fas fa-house-user fa-sm"></i>
                <span id="user-client-name">{{ companyName }}</span>
              </div>
            </div>
            <i
              class="fas fa-caret-down ml-3 text-white"
              :class="{ 'rotate-180': isShown }"
            ></i>
          </div>
          <transition name="fadeDrop">
            <div class="user-dropdown-menu shadow" v-if="isShown">
              <span
                class="dropdown-item menu-list"
                :class="{ active: userInfoComponent == 'UserPreferences' }"
                @click="chooseComponent('UserPreferences')"
              >
                <i class="fa fa-cogs mr-2"></i>
                <span>{{ getTranslationByKey('navbar.user_dropdown.preferences.preferences') }}</span>
              </span>
  
              <span
                class="dropdown-item menu-list"
                :class="{ active: userInfoComponent == 'Loginas' }"
                v-if="showLoginAs"
                @click="chooseComponent('Loginas')"
              >
                <i class="fas fa-users mr-2"></i>
                <span>{{ getTranslationByKey('navbar.user_dropdown.login_as.login_as') }}</span>
              </span>
  
              <!-- log out -->
              <span
                id="app-bar-logout"
                class="dropdown-item app-bar-menu-tab menu-list"
                @click="logout"
              >
                <i class="fas fa-sign-out-alt mr-2"></i>
                <span>
                  {{ getTranslationByKey('navbar.user_dropdown.logout') }}
                </span>
              </span>
            </div>
          </transition>
        </div>
      </nav>
      <!--/navbar -->
      <transition name="slide-right">
        <keep-alive>
          <component
            :is="userInfoComponent"
            @close-component="userInfoComponent = ''"
          ></component>
        </keep-alive>
      </transition>
      <!-- stream -->
      <StreamVideoContainer 
          :class="{'show': showVideoContainer && streamCount > 0}"
          :streamCount="streamCount"
          :showVideoContainer="showVideoContainer"
          @close-stream-container="showVideoContainer = !showVideoContainer"
      />
    </div>
  </template>
  
  <script>
  import StreamVideoContainer from '../components/object/StreamVideoContainer.vue'
  import UserPreferences from "../components/user/UserPreferences.vue";
  import Loginas from "../components/user/Loginas.vue";
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import { EventBus } from "../bus";
  import clickOutside from "vue-click-outside";
  
  export default {
    components: {
    UserPreferences,
    Loginas,
    StreamVideoContainer
},
    data() {
      return {
        userInfoComponent: "",
        isShown: false,
        hideNav: false, // for report full screen
        showVideoContainer: false,
        videoContainer: '',
      };
    },
    computed: {
      ...mapGetters(["settings", "user", "storeStream", "getTranslationByKey"]),
      streamCount() {
        let stream = 0
        if (this.storeStream.length) {
          stream += this.storeStream.length
        }
        return stream
      },
      userName() {
        return `${this.user.name} ${this.user.surname ? this.user.surname : ''}`
      },
      companyName() {
        return this.user.customer_name;
      },
      showObjects() {
        return this.user.permission && this.user.permission["sm.objects.index"];
      },
      showGeozones() {
        return this.user.permission && this.user.permission["sm.geozones.index"];
      },
      showReports() {
        return this.user.permission && this.user.permission["sm.reports.index"];
      },
      showEms() {
        let emsPermission = Object.keys(this.user.permission)
         .some((key) => key.startsWith("sm.ems") == true
        );

        if (!emsPermission && this.$route.name == 'ems') {
          this.$router.push('/objects')
        }

        return emsPermission;
      },
      showLoginAs() {
        return this.user.permission && this.user.permission["sm.system.login_as"];
      },
    },
    mounted() {
      EventBus.$on("report-fullscreen", (payload) => {
        this.hideNav = payload;
      });
      EventBus.$on('show-videos-container', () => {
        this.showVideoContainer = true
      })
    },
    methods: {
      ...mapActions(["logout"]),
      ...mapMutations(["setGoogleAPI"]),
      toggleDropdown() {
        this.isShown = !this.isShown;
      },
      hideDropdownMenu() {
        this.isShown = false;
      },
      chooseComponent(component) {
        this.hideDropdownMenu();
        return (this.userInfoComponent = component);
      },
      closeComponent() {
        this.userInfoComponent = ''
        this.objectStreamInfo = []
      },
      showHideStreamContainer() {
        this.showVideoContainer = !this.showVideoContainer
      },
    },
    directives: {
      clickOutside,
    },
  };
  </script>
  