
<template>
    <div class="custom-search-wrapper">
        <input
            type="text"
            class="flex-grow-1 border w-100 rounded shadow-sm"
            :placeholder="placeholder"
            :value="value"
            @input="updateValue($event.target.value)"
            ref="searchInput"
        />
        <i class="fas fa-search text-muted"></i>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'SearchInput',
        props: ['value', 'placeholder'],
        computed: {
            ...mapGetters(["getTranslationByKey"])
        },
        mounted() {
            this.$refs.searchInput.focus();
        },
        methods: {
            updateValue(value) {
                this.$emit("input", value)
            }
        }
    }
</script>

<style lang="scss" scoped>
.custom-search-wrapper {
    position: relative;
    height: 35px;
    font-size: 14px;
    input {
        padding-left: 30px;
        height: 100%;
    }
    i {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translate(-10px, -50%);
    }
}
</style>