import { mapGetters } from "vuex";

export default {
    data() {
        return {
            rectangle: null,
            bounds: null
        }
    },
    computed: {
        ...mapGetters(["map"]),
    },
    methods: {
        createRectangleShape() {
            let mapContainer = document.getElementById("map-container");
            let mapWidth = mapContainer.clientWidth ;
            let mapHeight = mapContainer.clientHeight;

            const x1 = mapWidth / 3;
            const y1 = mapHeight - mapHeight / 3;
            const x2 = mapWidth - mapWidth / 3;
            const y2 = mapHeight / 3;

            const savedRectPosition = JSON.parse(localStorage.getItem('who-was-there-rect-position'))

            if (savedRectPosition) {
                this.map.setZoom(savedRectPosition.zoom)
                this.map.setCenter(new google.maps.LatLng(savedRectPosition.center.lat, savedRectPosition.center.lng))
                this.bounds = {
                    south: savedRectPosition.lat1, // lat1
                    north: savedRectPosition.lat2, // lat2
                    west: savedRectPosition.lon1, // lon1
                    east: savedRectPosition.lon2 // lon2
                }
            } else {
                const latLon = this.pixelPointToLatLon(x1, y1);
                const latLon2 = this.pixelPointToLatLon(x2, y2);
                this.bounds = {
                    south: latLon.lat(), // lat1
                    north: latLon2.lat(), // lat2
                    west: latLon.lng(), // lon1
                    east: latLon2.lng(), // lon2
                }
            }
            
            this.rectangle = new google.maps.Rectangle({
                map: this.map,
                strokeColor: "#c00000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillColor: "#ffc0c0",
                fillOpacity: 0.5,
                draggable: true,
                editable: true,
                bounds: this.bounds
            });
           
            // save rectangle in local store while bounds change
            this.rectangle.addListener('bounds_changed', this.saveRectOnBoundsChange)
            
            return this.rectangle;
        },
        pixelPointToLatLon(x, y) {
            const map = this.map;
            const mapBoundsLatLon1 = map.getBounds().getSouthWest();
            const mapBoundsLatLon2 = map.getBounds().getNorthEast();
            const projection = map.getProjection();
            const LatLon1 = projection.fromLatLngToPoint(mapBoundsLatLon1);
            const LatLon2 = projection.fromLatLngToPoint(mapBoundsLatLon2);
            const zoom = Math.pow(2, map.getZoom());
            return projection.fromPointToLatLng(
                new google.maps.Point(x / zoom + LatLon1.x, y / zoom + LatLon2.y)
            );
        },
        saveRectOnBoundsChange() {
            const ne = this.rectangle.getBounds().getNorthEast()
            const sw = this.rectangle.getBounds().getSouthWest();
            const rectPositionToSave = {
            lat1: sw.lat(),
            lat2: ne.lat(),
            lon1: sw.lng(),
            lon2: ne.lng(),
            zoom: JSON.parse(localStorage.getItem('mapZoom')),
            center: JSON.parse(localStorage.getItem('mapCenter'))
            }

            localStorage.setItem('who-was-there-rect-position', JSON.stringify(rectPositionToSave));
        }
    },
};