<template>
  <div>
    <div class="calendar-chart shadow-sm" id="chart-calendar" ref="chartContainer"></div>
    <div id="pointer-info" class="pointer-info shadow"></div>
  </div>
</template>

<script>
import highcharts from "highcharts";
import boost from "highcharts/modules/boost";
import moment from 'moment';
import {mapGetters, mapMutations} from "vuex";

boost(highcharts);

    export default {
        props: {
            selectedDate: {
                type: Date
            },
            selectedObjectId: {
                type: Number
            },
            trans: {
                type: Object
            },
        },
        computed: {
            ...mapGetters([
                "user",
                "getVideoLists",
                "getHistoryData",
                "getSpeedToChart",
                "getUserTimezone",
                "getGuidedAvailableCameras",
                "getTranslationByKey"
            ]),
        },
        data() {
            return {
                chart: null,
                minMaxDate: {},
                chartSelectedData: {},
            }
        },
        watch: {
            selectedDate: {
                deep: true,
                immediate: false,
                handler() {
                    const momentDate = moment(this.selectedDate);
                    // this.SET_SPEED();


                    const [year, month, day] = [momentDate.year(), momentDate.month(), momentDate.date()];
                    // this.minMaxDate.min = Date.UTC(year, month, day, 0, 0);
                    // this.minMaxDate.max = Date.UTC(year, month, day, 23, 59);

                    this.minMaxDate.min = momentDate.clone().startOf('day').local().toDate().valueOf()
                    this.minMaxDate.max = momentDate.clone().endOf('day').local().toDate().valueOf()


                    // let speed;
                    // if (this.getVideoLists.length > 0) {
                    //     speed = this.getSpeedToChart.map(item => {
                    //         // TODO: Set user timezone
                    //         let parsedDate = Date.parse(item.txdt);
                    //         let dateWithTimeZone = new Date(parsedDate + 3 * 3600 * 1000)
                    //         let milliseconds = dateWithTimeZone.getTime()
                        
                    //         return [
                    //             milliseconds,
                    //             item.speed_gps
                    //         ]
                    //     })
                    // }
                    
                    let cameras = this.formatVideoListData(this.getVideoLists)
    
                    // this.camerasChartLine = [];

                    // for (const availableCam of this.getGuidedAvailableCameras) {
                    //     this.camerasChartLine.push({
                    //         name: this.getTranslationByKey(`ems.source_type.${availableCam.id}`),
                    //         id: availableCam.id,
                    //         type:'spline',
                    //         visible: availableCam.visible,
                    //         data: this.filterAndFormatCameraLineChart(cameras, availableCam.id)
                    //     })
                    // }

                    this.drawChart(cameras);
                },
            },
        },
        mounted() {
            highcharts.setOptions({
                lang: {
                    resetZoom: this.getTranslationByKey('ems.chart_reset_zoom_btn')
                }
            })
        },
        methods: {
            ...mapMutations(["SET_SPEED"]),
            drawChart(cameras, speed) {
                this.chart = highcharts.chart('chart-calendar', {
                boost: {
                    enabled: true
                },
                chart: { 
                    type: 'spline',
                    zoomType: 'x',
                    height: 220,
                    events: {
                      load: function () {
                        var chart = this;
                        var pointerInfo = document.getElementById('pointer-info');

                        chart.container.addEventListener('mousemove', function (e) {
                            const containerRect = chart.container.getBoundingClientRect();

                            const x = e.clientX - containerRect.left;
                            const y = e.clientY - containerRect.top;

                            const tooltipWidth = pointerInfo.offsetWidth;

                            let tooltipLeft = x + 10;
                            let tooltipTop = y + 10;

                            // Check if tooltip goes off the right edge of the container
                            if (tooltipLeft + tooltipWidth > chart.container.offsetWidth) {
                                tooltipLeft = x - tooltipWidth;

                                // Check if the new position goes off the left edge
                                if (tooltipLeft < 0) {
                                    tooltipLeft = 0;
                                }
                            } 
                            pointerInfo.style.left = `${tooltipLeft}px`;
                            pointerInfo.style.top = `${tooltipTop}px`;

                            var xValue = chart.xAxis[0].toValue(x);
                            var date = new Date(xValue);
                        
                            var hours = date.getHours();
                            var minutes = date.getMinutes();
                            var seconds = date.getSeconds();
                            var timestamp = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                            pointerInfo.innerHTML = `<i class="fas fa-clock text-muted"></i>${timestamp}`;
                            pointerInfo.style.visibility = 'visible';
                        });

                        // Hide the pointer info when the mouse leaves the chart area
                        chart.container.addEventListener('mouseleave', function () {
                          pointerInfo.style.visibility = 'hidden';
                        });
                      }
                    }
                },
                time: {
                    useUTC: false,
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: '',
                    align: 'center',
                    x: 10,
                    style: {
                        color: '#007bff',
                        letterSpacing: 2,
                        fontSize: '14px'
                    }
                },
                xAxis: {
                    allowDecimals: true,
                    type: 'datetime',
                    min: this.minMaxDate.min,
                    max: this.minMaxDate.max,
                    tickInterval: () => this.chart.xAxis[0].max - this.chart.xAxis[0].min,
                    gridLineWidth: 1,
                    events: {
                        afterSetExtremes: (data) => this.getValues(data),
                    },
                    plotBands: cameras,
                },
                yAxis: {
                    min: 0,
                    max: 10,
                    tickInterval: 10,
                    labels: {
                        enabled: false
                    },
                    visible: false

                },
                tooltip: {
                  enabled: false,
                  pointFormat: '',
                },
                plotOptions: {
                    area: {
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        },
                    }
                },
                series: [{
                    name: '',
                    type: 'spline',
                    color: '#0072c6',
                    showInLegend: false,
                    data: speed,
                    marker: {
                        enabled: true
                    },
                    marker: {
                        symbol: 'square'
                    },
                    tooltip: {
                        pointFormatter: function() {
                            return moment(this.x).format("YYYY-MM-DD HH:mm:ss");
                        },
                    }
                },
                //...camerasChartLine
                ]
            });

            },
            formatVideoListData(list) {
                let data = [];
                list.forEach(item => {
                    item.timeList.forEach(time => {
                        data.push({
                            id: item.id,
                            name: item.name,
                            from: time.from * 1000,
                            to: time.till * 1000,
                            color: '#c2ffc0',
                        })
                    })
                })
                return data;
            },
            filterAndFormatCameraLineChart(cameras, cameraId) {
                return cameras.filter(cam => cam.id == cameraId)
                    .map(c => {
                        return [
                            [c.from, this.handleLevel(cameraId)],
                            [c.to, this.handleLevel(cameraId)],
                            [c.to + 1, null]
                        ]
                    }).flat()
            },
            handleLevel(cameraId) {
                let m = 0;
                switch(cameraId) {
                    case "front":
                        m = 10;
                        break;
                    case 'inner1':
                        m = 15;
                        break;
                    case 'inner2':
                        m = 20;
                        break;
                    case 'left':
                        m = 25;
                        break;
                    case 'rear':
                        m = 30;
                        break;
                    case 'right':
                        m = 35;
                        break;
                }
                return m;
            },
            getValues(data) {
                const { min, max } = data;
                const utcDateFrom = moment.unix(min / 1000);
                const utcDateTill = moment.unix(max / 1000);

                // let sources = []

                // this.chart.series.forEach(function(sr) {
                //     if (sr.visible && sr.options.id) {
                //         sources.push(sr.options.id);
                //     }
                // });
                this.chartSelectedData.start = utcDateFrom.format('DD-MM-YYYY HH:mm:ss');
                this.chartSelectedData.end = utcDateTill.format('DD-MM-YYYY HH:mm:ss');
                this.chartSelectedData.startTime = utcDateFrom;
                this.chartSelectedData.endTime = utcDateTill;

                // sources = sources.filter(item => item !== 'Speed');

                const values = {
                    minMaxDatetime: this.chartSelectedData,
                }

                this.chart.setTitle({
                    text: `${this.chartSelectedData.start} - ${this.chartSelectedData.end}`,
                })
                
                this.$emit('guidedChartData', values);
            }
        }
    }
</script>

<style lang="scss" scoped>
.calendar-chart {
    border: 1px solid #cbd5e0;
    margin-top: 15px;
    padding-top: 12px;
    border-radius: 10px;
    width: 100% !important;
}
.pointer-info {
  position: absolute;
  visibility: hidden;
  background: #fff;
  padding: 5px;
  padding-inline: 10px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
