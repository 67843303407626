<template>
    <div class="d-flex align-items-center">
        <div class="custom-control custom-radio mr-3">
            <input
                id="guided-download"
                type="radio"
                value="1"
                class="custom-control-input"
                v-model="downloadType"
                @change="handleOptionChange"
            />
            <label class="custom-control-label small" for="guided-download">
                <span>{{ getTranslationByKey('ems.download_select_advanced')}}</span>
            </label>
        </div>
        <div class="custom-control custom-radio mr-3">
            <input
                id="blind-download"
                type="radio"
                value="2"
                class="custom-control-input"
                v-model="downloadType"
                @change="handleOptionChange"
            />
            <label class="custom-control-label small" for="blind-download">
                <span>{{ getTranslationByKey('ems.download_select_quick') }}</span>
            </label>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import api from "../../api";

    export default {
        props: {
            index: {type: Number},
            collectedData: {type: Object},
        },
        computed: {
          ...mapGetters([
            "user",
            "getTranslationByKey"
          ]),
        },
        data() {
            return {
                downloadType: null,
            }
        },
        methods: {
            ...mapActions(['WSReceiveVideoLists', 'requestVideoList']),
            handleOptionChange() {
                const data = {
                    index: this.index,
                    typeName: this.downloadType === "1" ? 'advanced' : 'quick',
                    type: this.downloadType
                };
                if (this.downloadType === "1" && this.videoList == undefined) {
                    this.requestVideoList(this.collectedData.objectId);
                    this.WSReceiveVideoLists(this.collectedData.objectId);
                }
                this.$emit('onSelectedType', data)
            },
        }
    }
</script>

<style lang="scss" scoped>
.custom-control-label::before {
    top: 2px;
}
.custom-control-label::after {
    top: 0.9px;
}

</style>