<template>
    <div class="no-connection-wrapper d-flex align-items-center justify-content-center">
      <div class="content d-flex flex-column align-items-center shadow">
        <p class="my-2 font-weight-600 text-monospace text-secondary text-uppercase">
          {{ msg }}
        </p>

        <button class="btn btn-primary btn-block mt-3"
          v-if="login" 
          @click="goToLogin"
        >
          <span class="h6 text-monospace">{{ login_text }}</span>
        </button>

        <button class="btn btn-primary btn-block mt-3"
          v-else
          @click="retry" 
        >
          <span class="h6 text-monospace">
            <i class="fas fa-redo-alt mr-1" :class="{'fa-spin': retryLoading}"></i> 
            Retry 
          </span>
        </button>
      </div>
    </div>
</template>

<script>
  import {mapActions} from 'vuex';
    export default {
      props: {
        msg: String,
        retryLoading: Boolean,
        login: Boolean,
        login_text: String
      },
      data() {
        return {
        }
      },
      methods: {
        ...mapActions(["setUser"]),
        retry() {
          this.$emit('retry')
        },
        goToLogin() {
          this.setUser({ auth: false, token: null, user: {} })
          window.location.reload()
        }
      }
    }
</script>

<style lang="scss" scoped>
.no-connection-wrapper {
  height: 100vh;
  width: 100%;
  background: #0072c6;
  position: absolute;
  top: 0;
  z-index: 9999;
  .content {
    width: 500px;
    padding: 30px 20px 20px 20px;
    background: #fff;
    border-radius: 6px;
  }
}
</style>