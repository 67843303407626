<template>
    <div class="report-action-buttons p-2 shadow">
        <div class="buttons">
            <button
                class="btn btn-light fullscreen-btn"
                :class="{ disabled: noDataFound }"
                @click="toggleReportFullscreen"
            >
                <i
                class="fas"
                :class="[
                    reportFullscreen
                    ? 'fa-compress-arrows-alt'
                    : 'fa-expand-arrows-alt',
                ]"
                ></i>
            </button>
            <button
                class="btn btn-light"
                :class="{ disabled: noDataFound }"
                @click="printReport"
            >
                <i class="fas fa-print text-success"></i>
            </button>
            <button
                class="btn btn-light"
                :class="{ disabled: noDataFound }"
                @click="downloadExcel"
            >
                <i
                class="fas text-muted"
                :class="[
                    excelDownloading
                    ? 'fa-spinner fa-spin loading'
                    : 'fa-file-excel',
                ]"
                >
                </i>
            </button>
            <button
                v-if="pdfButton"
                class="btn btn-light"
                :class="{ disabled: noDataFound }"
                @click="downloadPdf"
            >
                <i
                class="fas text-danger"
                :class="[
                    pdfDownloading ? 'fa-spinner fa-spin loading' : 'fa-file-pdf',
                ]"
                >
                </i>
            </button>
        </div>
        <h2 class="report-name d-flex align-items-center ml-n5">
            <img v-if="neededCriterisImg" :src="neededCriterisImg" alt="" />
            <i :class="neededCriterisIcon"></i>
            {{ reportTitle }}
        </h2>
        <button class="btn btn-light close-report" @click="closeReport">
            <i class="fa fa-times"></i>
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        props: {
            reportTitle: {
                type: String
            },
            noDataFound: {
                type: Boolean
            },
            reportFullscreen: {
                type: Boolean
            },
            excelDownloading:{
                type: Boolean
            },
            pdfDownloading: {
                type: Boolean
            },
            neededCriterisIcon: {
                type: String
            },
            neededCriterisImg: {
                type: String
            },
            pdfButton: {
                type: Boolean,
                default: true
            }
        },
        computed: {
            ...mapGetters(["getTranslationByKey"]),
        },
        methods: {
            toggleReportFullscreen() {
                this.$emit('onToggleFullscreen')
            },
            printReport() {
                this.$emit('onPrintReport')
            },
            downloadExcel() {
                this.$emit('onDownloadExcel')
            },
            downloadPdf() {
                this.$emit('onDownloadPDF')
            },
            closeReport() {
                this.$emit('onReportClose')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>