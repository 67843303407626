<template>
    <div class="stream-content-row">
        <StreamHeader 
            :stream="streamInfoRow" 
        />
        <StreamPlaceholder 
            v-if="showHidePlaceholder"
            :placeholderDetails="placeholderDetails"
        />
        <StreamCustomVideo 
            v-else 
            :stream="streamInfoRow" 
            :videoRef="`video-${streamInfoRow.id}`" 
            :containerRef="`container-${streamInfoRow.id}`" 
        />
    </div>
  </template>
  
  <script>
    import StreamHeader from './StreamHeader.vue';
    import StreamPlaceholder from './StreamPlaceholder.vue';
    import StreamCustomVideo from './StreamCustomVideo.vue';

    import { mapGetters, mapMutations } from 'vuex';

    export default {
        props: {
            streamInfoRow: Object,
        },
        components: {
            StreamHeader,
            StreamPlaceholder,
            StreamCustomVideo,
        },
        computed: {
            ...mapGetters(["getTranslationByKey"]),
            showHidePlaceholder() {
                return this.streamInfoRow.status_name !== 'streaming';
            },
            placeholderDetails() {
                let details = {
                    title: this.streamInfoRow.title
                };
          
                switch(this.streamInfoRow.status_name) {

                    case "new":
                    case "requested-to-stream": 
                        let withOrWithoutAudio = `${this.streamInfoRow.is_audio_muted 
                            ? this.getTranslationByKey('vehicles.stream.without_audio') 
                            : this.getTranslationByKey('vehicles.stream.with_audio')}`;
                        details.message = `${this.getTranslationByKey('vehicles.stream.connection_message').replace(':source_type', this.getTranslationByKey(`vehicles.stream.source_type.${this.streamInfoRow.source_type}`))} ${withOrWithoutAudio}`;
                        details.status = this.streamInfoRow.status_name;
                        details.closeLink = this.streamInfoRow.link;
                        break;

                    case "requested-to-close":
                        details.message = "Requested to close";
                        details.status = this.streamInfoRow.status_name;
                        details.closeLink = this.streamInfoRow.link;
                        break;

                    case "closed":
                        details.message = "Video closed";
                        details.status = this.streamInfoRow.status_name;
                        details.closeLink = this.streamInfoRow.link;
                        this.removeStream(this.streamInfoRow.id);
                        break;

                    case "error":
                        details.message = `${this.streamInfoRow.message_key
                            ? this.getTranslationByKey(this.streamInfoRow.message_key)
                            : this.streamInfoRow.message}`;
                        details.status = this.streamInfoRow.status_name;
                        details.closeLink = this.streamInfoRow.link;
                        details.streamId = this.streamInfoRow.id;
                        break;

                    default:
                        details.message = "Something went wrong";
                        details.status = 'unknownError';
                        details.closeLink = this.streamInfoRow.link;
                        break;
                }
                return details;
            },
        },
        methods: {
            ...mapMutations(['removeStream']),
        }
    }
</script>
  