
<template>
    <div class="c-modal-overlay">
        <div class="c-modal session-killer-modal bg-white">
            <div class="c-modal-body">
                <div class="content">
                    <i class="far fa-clock text-danger"></i>
                    <div class="">
                        <h6>
                            {{ getTranslationByKey('auth.session_about_to_expire') }}
                        </h6>
                        <p class="mb-0 text-muted font-weight-bold" v-html="transRedirectingToLogin"></p>
                    </div>
                </div>
            </div>
            <div class="c-modal-footer">
                <button class="btn btn-success w-100 mr-2 py-2" @click="extendSessionLifeTime">
                    <i
                        v-if="loading"
                        class="fa mr-2"
                        :class="sendToContinue ? 'fa-check': 'fa-spinner fa-spin'"
                    ></i>
                    <span>{{ getTranslationByKey('auth.session_continue') }}</span>
                </button>
                <button class="btn btn-light w-100 py-2 logout-btn" 
                    @click="logout"
                >
                    {{ getTranslationByKey('navbar.user_dropdown.logout') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex' 
    export default {
        data() {
            return {
                timer: 60,
                timeOut: null,
                loading: false,
                sendToContinue: false,

                minutes: 0,
                seconds: 0,
            }
        },
        mounted() {
            this.timer = this.getUserSessionTimeout;
            this.startTimer();
            // Watch session timeout when changes
            this.$watch('getUserSessionTimeout', (newVal) => {
                clearTimeout(this.timeOut);
                this.timer = newVal
                this.startTimer();
            })
        },
        computed: {
            ...mapGetters(["getTranslationByKey", "accessToken", "getUserSessionTimeout"]),
            transRedirectingToLogin() {
                let sentence;

                if (this.minutes > 0) {
                    sentence = this.getTranslationByKey('auth.redirect_to_login_min_sec')
                    .replace(':session_logout_timer_min', `<span>${this.minutes}</span>`)
                    .replace(':session_logout_timer_sec', `<span>${this.seconds}</span>`);

                } else {
                    sentence = this.getTranslationByKey('auth.redirect_to_login_sec')
                    .replace(':session_logout_timer_sec', `<span class='text-danger'>${this.seconds}</span>`);
                }

                return sentence;
            }
        },
        methods: {
            ...mapMutations(["SHOW_HIDE_SESSION_POPUP"]),
            ...mapActions(["logout", "checkUser", "killSession"]),
            startTimer() {
                const endTime = Date.now() + (this.timer * 1000);
                this.updateTimer(endTime);
            },
            updateTimer(endTime) {
                const remainingTime = Math.max(0, endTime - Date.now());
                this.minutes = Math.floor(remainingTime / 60000);
                this.seconds = Math.floor((remainingTime % 60000) / 1000);
        
                if (remainingTime > 0) {
                    this.timeOut = setTimeout(() => this.updateTimer(endTime), 1000)
                } else {
                    this.killSessionAndLogout() 
                }
            },
            async extendSessionLifeTime() {
                this.loading = true;
                await this.checkUser();

                clearTimeout(this.timeOut);
                this.sendToContinue = true

                setTimeout(() => {
                    this.SHOW_HIDE_SESSION_POPUP(false)
                }, 1000)
            },
            async killSessionAndLogout() {
                clearTimeout(this.timeOut)
                this.SHOW_HIDE_SESSION_POPUP(false)
                await this.logout()
            }
        }
    }
</script>

<style lang="scss" scoped>
.session-killer-modal {
    max-width: 100%;
    .content {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        padding: 30px;
        font-size: 17px;
        i {
            font-size: 30px;
        }
        p {
            line-height: 1.5;
        }
    }
    .logout-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
    }
}
</style>