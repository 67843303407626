<template>
  <div>
    <object-sidebar></object-sidebar>

    <history-block
      :object="object"
      :key="historyBlockKey"
      @historyBlockClose="historyBlockClose"
      v-if="showHistory"
    >
    </history-block>
  </div>
</template>

<script>
import ObjectSidebar from "../components/object/ObjectSidebar.vue";
import HistoryBlock from "../components/history/HistoryBlock.vue";
import { mapGetters } from "vuex";
import { EventBus } from "../bus";
export default {
  components: {
    ObjectSidebar,
    HistoryBlock,
  },
  data() {
    return {
      object: {},
      historyBlockKey: 0,
      showHistory: false,
    };
  },
  computed: {
    ...mapGetters(["getReportPeriod"]),
  },
  created() {
    EventBus.$emit("closeWhoWasThere");
    EventBus.$emit("close-history-block");
  },
  mounted() {
    EventBus.$on("historyBlockClose", () => {
      this.historyBlockClose();
    });
    this.openHistoryBlock();
  },
  methods: {
    openHistoryBlock() {
      // emited from main chart button side menu
      EventBus.$on("openHistoryBlock", (object) => {
        this.showHistory = true;
        this.object = object;
        this.historyBlockKey++;
        EventBus.$emit("map-show-chart", true);
      });
    },
    historyBlockClose() {
      this.showHistory = false;
      EventBus.$emit("map-show-chart", false);
    },
  },
};
</script>
