<template>
  <div>
    <transition name="fade-up">
      <reports-criterias
        :criterias="neededCriterias"
        :reportName="getTranslationByKey('reports.side_menu.over_speed.title')"
        :isLoading="loading"
        @collectedCriterias="getData"
        v-show="!hasDataArrived"
      >
      </reports-criterias>
    </transition>
    <transition name="report-fade">
      <div
        class="report-view-wrapper"
        :class="{ 'no-sidebar': reportFullscreen }"
        v-if="hasDataArrived"
      >
        <!-- action buttons -->
        <ReportViewActionBar 
          :noDataFound="noDataFound" 
          :reportFullscreen="reportFullscreen" 
          :excelDownloading="excelDownloading" 
          :pdfDownloading="pdfDownloading"
          :neededCriterisIcon="neededCriterias.icon"
          :reportTitle="getTranslationByKey('reports.side_menu.over_speed.title')"
          @onToggleFullscreen="toggleReportFullscreen"
          @onPrintReport="printReport"
          @onDownloadExcel="downloadExcel"
          @onDownloadPDF="downloadPdf"
          @onReportClose="closeReport"
        />
        <!-- content -->
        <div class="report-content" ref="printReportContent" :class="reportFullscreen ? 'full-screen': ''">
          <div v-if="!noDataFound">
            <div class="pdf-header">
              <span>
                {{
                  getTranslationByKey('reports.side_menu.over_speed.title') +
                  " " +
                  (objects.length > 1 ? ", " : objects[0].name + ", ") +
                  todayDateForPrint
                }}
              </span>
              <span>SmartMonitor v{{ settings.version }}</span>
            </div>
            <h4 class="pdf-report-name">
              {{ getTranslationByKey('reports.side_menu.over_speed.title') }}
            </h4>
            <!-- table header -->
            <div class="report-info d-flex justify-content-between">
              <div>
                <p class="company-name">{{ reportData.customer_name }}</p>
                <p class="report-period">{{ reportData.period }}</p>
              </div>
              <div>
                <p class="mb-0">
                  {{ getTranslationByKey('reports.table.speed') }} > {{ collectedData.maxSpeed }} km/h
                </p>
                <p class="mb-0">
                  {{ getTranslationByKey('reports.table.duration') }} >= {{ collectedData.duration }} min
                </p>
              </div>
            </div>
            <!-- Table -->
            <template v-for="object in objects">
              <div
                class="table-container"
                v-for="(day, dayDate) in object.days"
                :key="object.name + dayDate"
              >
                <div class="object-info">
                  <p class="obj-name-model">
                    {{ object.name }} -
                    <span class="model-make">{{ object.make_model }}</span>
                  </p>
                  <h5 class="report-date">{{ day.date_formatted }}</h5>
                </div>
                <table class="reports-table shadow-sm">
                  <thead>
                    <tr>
                      <th>{{ getTranslationByKey('reports.table.begin_time') }}</th>
                      <th>{{ getTranslationByKey('reports.table.from') }}</th>
                      <th>{{ getTranslationByKey('reports.table.end_time') }}</th>
                      <th>{{ getTranslationByKey('reports.table.to') }}</th>
                      <th>{{ getTranslationByKey('reports.table.duration') }}</th>
                      <th>{{ getTranslationByKey('reports.table.driven_km') }}</th>
                      <th>{{ getTranslationByKey('reports.table.max_speed') }}</th>
                      <th v-show="collectedData.colsOsDriver">
                        {{ getTranslationByKey('reports.table.driver') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in day.data"
                      :key="index"
                      @click="
                        goToHistoryChart(
                          day.date,
                          row.time_start,
                          row.time_end,
                          object.name
                        )
                      "
                    >
                      <td>{{ row.time_start }}</td>
                      <td>
                        <b v-if="row.location_start.geozone" class="d-block">{{
                          row.location_start.geozone.name
                        }}</b>
                        <span v-if="row.location_start.address">{{
                          row.location_start.address.address
                        }}</span>
                        <span v-else>{{ row.location_start }}</span>
                      </td>
                      <td>{{ row.time_end }}</td>
                      <td>
                        <b v-if="row.location_end.geozone" class="d-block">{{
                          row.location_end.geozone.name
                        }}</b>
                        <span v-if="row.location_end.address">{{
                          row.location_end.address.address
                        }}</span>
                        <span v-else>{{ row.location_end }}</span>
                      </td>
                      <td>{{ row.duration }}</td>
                      <td>
                        {{
                          mileageByCan
                            ? formatNum(row.odometer_distance, "kilometer")
                            : formatNum(row.gps_distance, "kilometer")
                        }}
                      </td>
                      <td>{{ formatNum(row.max_speed, "kilometer") }}</td>
                      <td v-show="collectedData.colsOsDriver">
                        {{ row.driver_name }}
                        <b class="d-block text-muted">{{
                          row.driver_ibutton
                        }}</b>
                      </td>
                    </tr>
                    <tr class="tfoot">
                      <!-- total count -->
                      <td colspan="3">
                        {{ getTranslationByKey('reports.table.number_of_rows') }}:
                        <span>{{ day.total_count }}</span>
                      </td>
                      <!-- total duration -->
                      <td>{{ getTranslationByKey('reports.table.total') }}:</td>
                      <td class="text-center">
                        <span>{{ day.total_duration }}</span>
                      </td>
                      <!-- total  GPS OR Odometer-->
                      <td class="text-center">
                        <span
                          >{{
                            mileageByCan
                              ? formatNum(
                                  day.total_odometer_distance,
                                  "kilometer"
                                )
                              : formatNum(day.total_gps_distance, "kilometer")
                          }}
                        </span>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
          <div v-else class="no-data-found">
            <h3>
              <i class="fab fa-searchengin mr-1"></i
              >{{ getTranslationByKey('reports.table.no_data_found') }}
            </h3>
            <p class="text-muted font-weight-600 small">
              {{ getTranslationByKey('reports.table.other_obj') }}
            </p>
            <button class="btn btn-light" @click="hasDataArrived = false">
              <i class="fas fa-long-arrow-alt-left mr-1"></i
              >{{ getTranslationByKey('reports.table.back') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportsCriterias from "./ReportsCriterias.vue";
import ReportViewActionBar from "./ReportViewActionBar.vue";
import helper from "../../helper";
import { EventBus } from "../../bus";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { Printd } from "printd";
import { css } from "./print-report-css";
import api from "../../api";

export default {
  components: {
    ReportsCriterias,
    ReportViewActionBar
  },
  data() {
    return {
      neededCriterias: {
        icon: "fas fa-tachometer-alt",
        period: true,
        objects: true,
        columnsOs: true,
        mileage: true,
        maxSpeed: true,
        duration: true,
        hideEmptyObj: true,
      },
      collectedData: null,
      loading: false,
      excelDownloading: false,
      pdfDownloading: false,
      hasDataArrived: false,
      reportData: [],
      objects: [],
      todayDateForPrint: moment().format("DD-MM-YYYY HH:mm:ss"),
      reportFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(["settings", "getTranslationByKey", "user"]),
    noDataFound() {
      return this.reportData.length == 0;
    },
    mileageByCan() {
      return this.collectedData.mileage === "1";
    },
  },
  methods: {
    ...mapActions(["setReportPeriod", "setReportRoute"]),
    goToHistoryChart(startDate, startTime, endTime, objName) {
      let period = [
        moment(`${startDate} ${startTime}`, "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        ),
        moment(`${startDate} ${endTime}`, "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        ),
      ];
      this.setReportPeriod(period);
      this.setReportRoute("over-speed");
      this.$router.push({ name: "objects" }).catch(() => {});
      EventBus.$emit("openHistoryBlock", {
        vid: this.collectedData.vehiclesIds[0],
        priority_name: objName,
      });
    },
    async getData(criterias) {
      this.collectedData = criterias;
      this.loading = true;
      await api
        .get("report/over-speed", {
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
            max_speed: this.collectedData.maxSpeed,
            duration: this.collectedData.duration,
            vehicle_ids: this.collectedData.vehiclesIds,
          },
        })
        .then((response) => {
          this.reportData = response.data.data;
          this.objects = response.data.data.objects;
          this.loading = false;
          this.hasDataArrived = true;

          this.$audit({
            action: "report_over_speed",
            value: {
              from: this.collectedData.periodFrom,
              to: this.collectedData.periodTill,
              mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
              max_speed: this.collectedData.maxSpeed,
              duration: this.collectedData.duration,
              vehicle_ids: this.collectedData.vehiclesIds,
            },
          });
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          console.error(error);
        });
    },
    downloadExcel() {
      this.excelDownloading = true;
      api
        .get("report/export-excel", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
            max_speed: this.collectedData.maxSpeed,
            duration: this.collectedData.duration,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "over-speed",
            // criteria
            driver_name: this.collectedData.colsOsDriver,
          },
        })
        .then((response) => {
          this.excelDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.objects.length > 1 ? '' : this.objects[0].name;
          link.download = `${this.getTranslationByKey('reports.side_menu.over_speed.title')} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.xlsx`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          this.excelDownloading = false;
          console.error(error);
        });
    },
    downloadPdf() {
      this.pdfDownloading = true;
      api
        .get("report/export-pdf", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            mileage_by: this.collectedData.mileage, // 1 - by CAN 2 - by GPS
            max_speed: this.collectedData.maxSpeed,
            duration: this.collectedData.duration,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "over-speed",
            // criteria
            driver_name: this.collectedData.colsOsDriver,
          },
        })
        .then((response) => {
          this.pdfDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.objects.length > 1 ? '' : this.objects[0].name;
          link.download = `${this.getTranslationByKey('reports.side_menu.over_speed.title')} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.pdf`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error);
          this.pdfDownloading = false;
          console.error(error);
        });
    },
    formatNum(value, unit) {
      return helper.formatNum(value, unit)
    },
    printReport() {
      const cssText = css;
      const d = new Printd();
      d.print(this.$refs.printReportContent, [cssText]);
    },
    closeReport() {
      this.hasDataArrived = false;
      this.reportFullscreen = false;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    toggleReportFullscreen() {
      this.reportFullscreen = !this.reportFullscreen;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
  },
};
</script>
