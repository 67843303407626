<template>
  <div class="login-container">
    <div class="login-form shadow" v-if="!isOffline">
      <img src="/img/smart_systems_logo.png" alt="" class="logo-intro mb-1 d-md-block align-self-start">
      <h4 class="text-primary text-center mb-1 smart-mon">SmartMonitor</h4>
      <span class="d-block text-center text-black-50 text-monospace mb-3 smallest font-weight-bold">
        {{ settings.version }}
      </span>
      <!-- flags -->
      <div class="language mb-3 text-center">
        <button v-for="flag in languagesAvailable" :key="flag"
                @click="languageSelect(flag)"
                class="btn language-button margin-0"
                :class="{'active': selectedLanguage === flag}">
          <img class="auto-height" :src="`/img/flags/${flag}.png`">
        </button>
      </div>
      <!-- Error login -->
      <transition name="slide-right">
        <p class="text-danger small font-weight-600 mb-1" v-if="error || $route.query.error">
          {{ $route.query.error ? $route.query.error.error : errorText }}
        </p>
      </transition>
      <!-- form -->
      <form class="w-100" method="post">
        <!-- user name -->
        <div class="user-login" :class="{'activated': userFieldActive}">
          <input class="form-control mb-3"
                 type="text" v-model="userName"
                 :placeholder="getTranslationByKey('auth.username')"
                 @focus="userFieldActive = true"
                 @blur="userFieldActive = false"
                 @keyup.enter="login">
          <i class="fa fa-times clear-fields"
             v-show="userName.length"
             @click="emptyFields">
          </i>
        </div>
        <!-- user pass -->
        <div class="user-pass" :class="{'activated': passFieldActive}">
          <input class="form-control mb-3"
                 :type="passTypeText ? 'text' : 'password'"
                 v-model="userPass"
                 :placeholder="getTranslationByKey('auth.password')"
                 @focus="passFieldActive = true"
                 @blur="passFieldActive = false"
                 @keyup.enter="login">
          <i class="far show-pass-icon"
             :class="[passTypeText ? 'fa-eye-slash' : 'fa-eye']"
             v-show="userPass.length"
             @click="passTypeText = !passTypeText"
          ></i>
        </div>
        <!-- login button -->
        <div class="d-flex justify-content-center">
          <button type="button"
                  class="btn btn-primary btn-block w-75 login-btn"
                  @click="login">
            <i class="fas fa-sm" :class="[loading ? 'fa-spinner fa-spin' : 'fa-check']"></i>
            {{ getTranslationByKey('auth.login') }}
          </button>
        </div>
      </form>
      <!-- forgot pass link -->
      <div class="col mt-2 text-center" v-if="!settings.is_vp || !settings.is_nmpd">
        <router-link :to="{name: 'forgot-password'}" class="forgot-pass">
          {{ getTranslationByKey('auth.forgot_pass') }}
        </router-link>
      </div>
    </div>
    <!-- No connection -->
    <NoConnection 
      v-else
      msg="No internet Connection" 
      :retryLoading="retryLoading"
      @retry="retry"
      :login_text="getTranslationByKey('auth.login_text')"
    /> 
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import NoConnection from '../components/global/NoConnection.vue'
import api from '../api';

export default {
  components: { NoConnection },
  data() {
    return {
      selectedLanguage: '',
      userName: '',
      userPass: '',
      userFieldActive: false,
      passFieldActive: false,
      passTypeText: false,
      loading: false,

      error: false,
      errorText: '',
      isOffline: false,
      retryLoading: false
    }
  },
  computed: {
    ...mapGetters(['settings', 'translations', 'getTranslationByKey']),
    trans() {
      return this.translations.auth;
    },
    languagesAvailable() {
      return this.settings.sm_language_available.split(',');
    },
    getSelectedLanguage() {
      let language;
      if (localStorage.getItem('selectedLanguage') === null) {
        language = this.settings.bo_language_default
      } else {
        language = localStorage.getItem('selectedLanguage');
      }
      return language
    },
  },
  created() {
    this.selectedLanguage = this.getSelectedLanguage;
  },
  mounted() {
    window.addEventListener('offline', (e) => {
      this.isOffline = true
    })
    window.addEventListener('online', (e) => {
      this.isOffline = false
      this.retryLoading = false
    })
  },
  methods: {
    ...mapMutations(["setUserTimezone"]),
    retry() {
      this.retryLoading = true
      setTimeout(() => {
        location.reload()
      }, 10000)
    },
    ...mapActions(["getTranslations", "setUser", "checkUser"]),
    emptyFields() {
      this.userName = '';
      this.userPass = '';
    },
    languageSelect(lang) {
      this.selectedLanguage = lang;
      localStorage.setItem('selectedLanguage', this.selectedLanguage);
      this.getTranslations(lang);
    },
    async login() {
      if (this.userName === '' || this.userPass === '') {
        return;
      }
      this.loading = true;
      try {
        const response = await api.post('login', {
          username: this.userName,
          password: this.userPass,
          language: this.selectedLanguage
        })

        if (response.data.data.error) {
          this.errorText = response.data.data.error
          this.error = true;
          this.loading = false;
          return;
        }
        const responseData = response.data.data;
        this.setUserTimezone(response.data.user.timezone);
        if (responseData.token) {
          this.loading = false;
          this.errorText = '';
          this.setUser({
            auth: true,
            token: responseData.token,
            user: responseData.user,
            uid: responseData.user.uid,
          });
          localStorage.setItem('selectedLanguage', responseData.user.language);
          await this.getTranslations(responseData.user.language);
          if (responseData.user.customer_module == 'subzero') {
            this.$router.push({name: "devices"});
          } else {
            this.$router.push({name: "objects"}).catch(()=>{});
          }
          await this.checkUser();
        }
      } catch (e) {
        this.handleErrorMixin(e);
        this.$audit({
          action: 'sm_login_fail',
          value: {username: this.userName}
        });
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
