import helper from "../../helper";
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { MarkerWithLabel } from '@googlemaps/markerwithlabel';
export default {
    state: {
        map: null,
        markers: {},
        markersCreated: false,
        markerCluster: {},
        clusterState: true,
        clusterStyle: {
            maxZoom: 14,
            zoomOnClick: true,
            averageCenter: true,
            enableRetinaIcons: true,
            gridSize: 60,
            minimumClusterSize: 2,
            imagePath: "/img/m",
        },
        priorityName: {
            name: true,
            regNo: true,
            trailer: true,
        },
        traceMap: {
            markers: {},
        },
        route: {
            routes: {},
            parkingMarkers: [],
            checkedMarkers: {},
            historyRoutes: null,
            runner: null,
        },
        position: null,
        addressMarkers: {},
    },
    actions: {
        createMap(context, data) {
            context.commit("map", data);
        },
        async setClusterState(context, data) {
            context.commit("clusterState", data);

            if (data) {
                let markers = context.getters.visible.map((object) => {
                    return context.getters.markers[object.vid];
                });
                context.commit(
                    "createCluster",
                    markers.filter((item) => item)
                );
            } else {
                context.commit("destroyCluster");
                for (let object of context.getters.visible) {
                    context.dispatch("addMarker", object);
                }
            }
        },

        async createMarker(context, data) {
            let object = data.object;
            if (object.lat && object.lon && object.lat != 0 && object.lon != 0) {
                const map = data.map || context.getters.map;
                const lat = parseFloat(object.lat);
                const lng = parseFloat(object.lon);
                const latLng = new google.maps.LatLng({ lat: lat, lng: lng });

                // MarkerWithLabel doc: https://googlemaps.github.io/js-markerwithlabel/index.html
                const marker = new MarkerWithLabel({
                    map: map,
                    position: latLng,
                    labelContent: object.priority_name,
                    labelVisible: !!object.priority_name,
                    labelAnchor: new google.maps.Point(-  (5 * object.priority_name.length / 2) - 10, - 35),
                    // labelAnchor: new google.maps.Point(5 * (object.priority_name.length / 2) + 10, 35),
                    labelClass: helper.getMarkerLabelClass(object),
                    icon: context.getters.getIcon(object),
                    clickable: true,
                });
                marker.label.labelDiv.style.background = object.status_color;
                // show object card when marker is clicked
                if (!data.trace) {
                    marker.addListener("click", () => {
                        this.dispatch("showInfoBlock", {
                            show: true,
                            id: object.vid,
                            containerWidth: map.getDiv().offsetWidth,
                            containerHeight: map.getDiv().offsetHeight,
                        });
                    });
                }
                context.commit("createMarker", {
                    objectId: object.vid,
                    marker: marker,
                    trace: data.trace,
                    isLast: data.isLast
                });
            
            }
        },
        async addMarker(context, object) {
            if (object.lat && object.lon && object.lat != 0 && object.lon != 0) {
                context.commit("addMarker", { objectId: object.vid });
            }
        },
        async addMarkers(context, objects) {
            for (let object of objects) {
                if (
                    context.state.markers[object.vid] &&
                    !context.state.markers[object.vid].getMap()
                ) {
                    context.dispatch("addMarker", object);
                }
            }
        },
        async updatePositions(context, data) {
            return Promise.resolve(
                google.maps.geometry.spherical.computeHeading(data.latLngPrev, data.latLngNext)
            );
        },
        animateMarker(context, { marker, latLng }) {
            const startPos = marker.getPosition();
            const endPos = new google.maps.LatLng(latLng);
            const duration = 1000; // Animation duration in milliseconds
            let startTime = null;

            function animateStep(timestamp) {
                if (!startTime) startTime = timestamp;
                const progress = (timestamp - startTime) / duration;
                if (progress < 1) {
                    const currentLat = startPos.lat() + (endPos.lat() - startPos.lat()) * progress;
                    const currentLng = startPos.lng() + (endPos.lng() - startPos.lng()) * progress;
                    marker.setPosition(new google.maps.LatLng(currentLat, currentLng));
                    requestAnimationFrame(animateStep);
                } else {
                    marker.setPosition(endPos); // Ensure the marker ends at the exact position
                }
            }
            requestAnimationFrame(animateStep);
        },
        async updateMarker(context, object) {
            if (object.lat && object.lon && object.lat != 0 && object.lon != 0) {
                const lat = parseFloat(object.lat);
                const lng = parseFloat(object.lon);
                const latLng = new google.maps.LatLng({ lat: lat, lng: lng });
                const map = context.getters.map;
                const currentZoomLevel = map.getZoom();

                if (undefined !== context.state.markers[object.vid] || undefined !== context.state.traceMap.markers[object.vid]) {
                    let marker = undefined !== context.state.markers[object.vid]
                        ? context.state.markers[object.vid]
                        : context.state.traceMap.markers[object.vid];
            
                    marker.label.labelDiv.style.background = object.status_color;
                    marker.setIcon(context.getters.getIcon(object));

                    let viewportBounds = map.getBounds();
                    let markerPosition = marker.getPosition();
                    let isMarkerInView = viewportBounds.contains(markerPosition);

                    // update marker position and view
                    if (isMarkerInView && currentZoomLevel >= 12) {
                        this.dispatch('animateMarker', {marker, latLng});
                    } else {
                        marker.setPosition(new google.maps.LatLng(latLng));
                    }

                    if (undefined !== marker.trace) {
                        this.dispatch("panTo", {
                            map: marker.getMap(),
                            lat: marker.getPosition().lat(),
                            lon: marker.getPosition().lng(),
                            course: marker.getIcon().rotation,
                        });
                    }
                }
            }
        },
        async updateMarkerLabel(context) {
            for (let object of context.getters.all) {
                if (object.lat && object.lon && object.lat != 0 && object.lon != 0) {
                    context.commit("updateMarkerLabel", {
                        objectId: object.vid,
                        labelContent: helper.getPriorityName(
                            object,
                            context.getters.priorityName
                        ),
                    });
                }
            }
            if (context.state.clusterState) {
                context.commit("updateCluster");
            }
        },
        updateSingleMarkerLabel(context, object) {
            if (object.lat && object.lon && object.lat != 0 && object.lon != 0) {
                context.commit("updateMarkerLabel", {
                    objectId: object.vid,
                    labelContent: helper.getPriorityName(
                        object,
                        context.getters.priorityName
                    ),
                });
            }
            if (context.state.clusterState) {
                context.commit("updateCluster");
            }
        },
        async removeMarker(context, object) {
            context.commit("removeMarker", { objectId: object.vid });
        },
        async removeMarkers(context, objects) {
            for (let object of objects) {
                if (
                    context.state.markers[object.vid] &&
                    context.state.markers[object.vid].getMap()
                ) {
                    context.dispatch("removeMarker", object);
                }
            }
        },

        async createCluster(context) {
            if (context.state.clusterState) {
                context.commit("createCluster", context.getters.markers);
            }
        },
        async addCluster(context, objects) {
            if (context.state.clusterState) {
                // console.log("addCluster");
                let addToCluster = [];
                for (let object of objects) {
                    if (context.state.markers[object.vid]) {
                        addToCluster.push(context.state.markers[object.vid]);
                    }
                }
                context.commit("addCluster", { markers: addToCluster });
                context.state.markerCluster.repaint();
            }
        },
        async updateCluster(context) {
            if (context.state.clusterState) {
                context.commit("updateCluster");
            }
        },
        async removeCluster(context) {
            if (context.state.clusterState) {
                // console.log("removeCluster");
                context.commit("removeCluster");
            }
        },

        async createRoute(context, data) {
            const route = new google.maps.Polyline({
                path: data.route,
                geodesic: true,
                strokeColor: "#0050ef",
                strokeOpacity: 1.0,
                strokeWeight: 4,
                icons: [{
                        fixedRotation: true,
                        icon: helper.routeStartIcon(),
                        offset: "0%",
                    },
                    {
                        icon: helper.routeDirectionIcon(),
                        offset: "30px",
                        repeat: "100px",
                    },
                    {
                        fixedRotation: true,
                        icon: helper.routeEndIcon(),
                        offset: "100%",
                    },
                ],
            });

            route.setMap(context.getters.map);
            context.dispatch("setRouteBounds", route);
            context.dispatch("createParkingMarkers", data);
            context.commit("createRoute", {
                objectId: data.objectId,
                route: route,
                update: data.update,
            });
        },
        async setRouteBounds(context, data) {
            const bounds = new google.maps.LatLngBounds();
            const path = data.getPath();
            if (path.getLength() > 1) {
                path.forEach((element, index) => {
                    bounds.extend(element);
                });
                context.getters.map.fitBounds(bounds);
            }
        },
        async updateRoute(context, data) {
            // console.log('updateRoute');
            let route = context.state.route.routes[data.vid];
            if (route) {
                const latLng = new google.maps.LatLng({
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lon),
                });
                let path = route.getPath();
                path.push(latLng);

                if (data.reason === "reason_stopped") {
                    console.log("reason_stopped");
                    context.dispatch("createParkingMarkers", {
                        lat: data.lat,
                        lon: data.lon,
                    });
                }
            }
        },
        async removeRoute(context, data) {
            if (context.state.route.routes[data.objectId]) {
                context.commit("removeRoute", data);
                context.dispatch("removeParkingMarkers");
            }
        },
        async removeHistoryRoute(context) {
            if (context.state.route.historyRoutes) {
                context.commit("removeHistoryRoute");
                context.dispatch("removeParkingMarkers");
                context.dispatch("removeCheckedMarkers");
            }
        },
        async createRouteRunner(context) {
            const runner = new google.maps.Marker({
                clickable: false,
                icon: "/img/icons/32x32/location.png",
                map: context.getters.map,
            });

            context.commit("createRouteRunner", { runner: runner });
        },
        async addRouteRunner(context) {
            context.commit("addRouteRunner");
        },
        async updateRouteRunner(context, data) {
            const lat = parseFloat(data.lat);
            const lon = parseFloat(data.lon);
            const latLon = new google.maps.LatLng({ lat: lat, lng: lon });

            context.commit("updateRouteRunner", { position: latLon });
        },
        async removeRouteRunner(context) {
            context.commit("removeRouteRunner");
        },

        async createParkingMarkers(context, data) {
            if (data.parking) {
                for (let i = 0; i < data.parking.length; i++) {
                    let parking = data.parking[i];

                    const marker = new google.maps.Marker({
                        clickable: false,
                        icon: {
                            url: "/img/icons/16x16/parking.png",
                        },
                        map: context.getters.map,
                        position: new google.maps.LatLng(
                            parseFloat(parking.lat),
                            parseFloat(parking.lon)
                        ),
                    });

                    context.commit("createParkingMarkers", { marker: marker });
                }

            }
        },
        async removeParkingMarkers(context) {
            context.commit("removeParkingMarkers");
        },

        async createCheckedMarker(context, data) {
            if (data.lat != 0 && data.lon != 0) {
                const marker = new google.maps.Marker({
                    clickable: false,
                    label: {
                        text: data.id.toString(),
                        className: "checked-marker",
                    },
                    map: context.getters.map,
                    position: new google.maps.LatLng(
                        parseFloat(data.lat),
                        parseFloat(data.lon)
                    ),
                });

                context.commit("createCheckedMarker", { id: data.id, marker: marker });
            }
        },
        async removeCheckedMarker(context, data) {
            context.commit("removeCheckedMarker", { id: data.id });
        },
        async removeCheckedMarkers(context) {
            context.commit("removeCheckedMarkers");
        },

        async createAddressMarker(context, data) {
            if (data.lat != 0 && data.lon != 0) {
                const lat = parseFloat(data.lat)
                const lng = parseFloat(data.lon)

                const marker = new google.maps.Marker({
                    map: context.getters.map,
                    position: {lat, lng}
                });

                const infoWindow = new google.maps.InfoWindow({
                    position: new google.maps.LatLng(lat, lng),
                    content: data.address,
                });
                infoWindow.open(context.getters.map, marker);

                infoWindow.addListener("closeclick", () => {
                    context.commit("removeAddressMarker", { id: data.id });
                });

                context.commit("createAddressMarker", { id: data.id, marker: marker });
            }
        },
        async panTo(context, data) {
            const map = data.map || context.getters.map;
            const lat = parseFloat(data.lat);
            const lon = parseFloat(data.lon);
            const latLon = new google.maps.LatLng({ lat: lat, lng: lon });
            await map.panTo(latLon);
            // Move on Street View
            const panorama = map.getStreetView();
            if (panorama) {
                panorama.setPosition(latLon);
                panorama.setPov({ heading: data.course || 0, pitch: 0 });
            }
        },
        async updatePriorityName(context, data) {
            context.commit("updatePriorityName", data);
        },
        async removeAddressMarker(context, data) {
            context.commit("removeAddressMarker", { id: data.id })
        }
    },
    mutations: {
        map(state, data) {
            state.map = data;
        },
        clusterState(state, data) {
            state.clusterState = data == 1 ? true : false;
        },
        createMarker(state, data) {
            if (data.trace) {
                state.traceMap.markers[data.objectId] = data.marker;
            } else {
                state.markers[data.objectId] = data.marker;
            }
            if (data.isLast && data.isLast == true) {
                state.markersCreated = true
            }
        },
        addMarker(state, data) {
            state.markers[data.objectId].setMap(state.map);
        },
        updateMarkerLabel(state, data) {
            let marker = state.markers[data.objectId];
            marker.setOptions({
                labelContent: data.labelContent,
                labelVisible: !!data.labelContent,
            });
        },
        removeMarker(state, data) {
            state.markers[data.objectId].setMap(null);
        },

        // Cluster doc: https://gmaps-marker-clusterer.github.io/gmaps-marker-clusterer/
        createCluster(state, data) {
            state.markerCluster = new MarkerClusterer(
                state.map,
                data,
                state.clusterStyle
            );
        },
        addCluster(state, data) {
            state.markerCluster.addMarkers(data.markers, true);
        },
        async updateCluster(state) {
            if (state.markerCluster && state.markerCluster.ready_) {
                state.markerCluster.redraw_();
            }
        },
        removeCluster(state, data) {
            // state.markerCluster.removeMarkers(data.markers, true);
            // Remove all markers - best performance
            state.markerCluster.clearMarkers();
        },
        destroyCluster(state) {
            state.markerCluster.clearMarkers();
        },

        createRoute(state, data) {
            if (data.update) {
                state.route.routes[data.objectId] = data.route;
            } else {
                state.route.historyRoutes = data.route;
            }
        },
        removeRoute(state, data) {
            state.route.routes[data.objectId].setMap(null);
            delete state.route.routes[data.objectId];
        },
        removeHistoryRoute(state) {
            state.route.historyRoutes.setMap(null);
            state.route.historyRoutes = null;
        },
        createRouteRunner(state, data) {
            state.route.runner = data.runner;
        },
        addRouteRunner(state) {
            state.route.runner.setMap(state.map);
        },
        updateRouteRunner(state, data) {
            state.route.runner.setPosition(data.position);
        },
        removeRouteRunner(state) {
            if (state.route.runner) {
                state.route.runner.setMap(null);
            }
        },

        createParkingMarkers(state, data) {
            state.route.parkingMarkers.push(data.marker);
        },
        removeParkingMarkers(state) {
            const parkingMarkers = state.route.parkingMarkers;
            for (let i = 0; i < parkingMarkers.length; i++) {
                parkingMarkers[i].setMap(null);
            }
            state.route.parkingMarkers = [];
        },
        createCheckedMarker(state, data) {
            state.route.checkedMarkers[data.id] = data.marker;
        },
        removeCheckedMarker(state, data) {
            if (state.route.checkedMarkers[data.id]) {
                state.route.checkedMarkers[data.id].setMap(null);
                delete state.route.checkedMarkers[data.id];
            }
        },
        removeCheckedMarkers(state) {
            for (let marker in state.route.checkedMarkers) {
                state.route.checkedMarkers[marker].setMap(null);
            }
            state.route.checkedMarkers = {};
        },

        createAddressMarker(state, data) {
            state.addressMarkers[data.id] = data.marker;
        },
        removeAddressMarker(state, data) {
            if (state.addressMarkers[data.id]) {
                state.addressMarkers[data.id].setMap(null);
                delete state.addressMarkers[data.id];
            }
        },

        updatePriorityName(state, data) {
            state.priorityName = data;
        },
    },
    getters: {
        map(state) {
            return state.map;
        },
        clusterState(state) {
            return state.clusterState;
        },
        getMarkerCluster(state) {
            return state.markerCluster;
        },
        markers(state) {
            return state.markers;
        },
        isMarkersCreated(state) {
            return state.markersCreated;
        },
        priorityName(state) {
            return state.priorityName;
        },
        traceMap(state) {
            return state.traceMap;
        },
        // TODO: bad practice with params - need to refactor
        getIcon(state) {
            return function(object) {
                if (object.status_offline) {
                    return {
                        fillColor: object.status_color,
                        fillOpacity: 1.0,
                        strokeWeight: 2,
                        strokeColor: "#333333",
                        strokeOpacity: 1.0,
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 10,
                        rotation: 0,
                        anchor: new google.maps.Point(0, 0),
                    };
                } else {
                    return {
                        fillColor: object.status_color,
                        fillOpacity: 1.0,
                        strokeWeight: 2,
                        strokeColor: "#333333",
                        strokeOpacity: 0.7,
                        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        scale: 6,
                        rotation: object.course,
                        anchor: new google.maps.Point(0, 2),
                    };
                }
            };
        }
    },
};
