<template>
    <div class="mb-2 ml-4">
        <!-- Guided -->
        <div v-if="isEMSGuided" class="py-2">
            <div v-if="getGuidedShowProgress">
                <StepProgress
                    :steps="guidedProgressSteps"
                    :current-step="getGuidedProgressCurrentStep"
                    active-color="#0072c6"
                    passive-color="#dee2e6"
                    :active-thickness="2"
                    :passive-thickness="2"
                    :line-thickness="4"
                />
                <!-- Retry -->
                <Transition name="fade-up">
                    <div  v-if="getVideoListRetry" class="d-flex flex-column align-items-center">
                        <p class="text-black-50 small">{{ getTranslationByKey('ems.error_message') }}</p>
                        <button @click="videoListRetry" class="btn btn-sm btn-primary w-25">
                            <i class="fas fa-sync-alt mr-2"></i>
                            <span>{{ getTranslationByKey('ems.action.retry') }}</span>
                        </button>
                    </div>
                </Transition>
            </div>
            <div v-else>
                <Calendar
                    show-weeknumbers="left-outside"
                    :attributes="guidedCalendarAttrs"
                    @dayclick="handleGuidedClickedDay"
                    :available-dates="guidedCalendarAttrs[0].dates"
                    :locale="user.language"
                    class="shadow-sm"
                ></Calendar>
                <!-- source -->
                <div class="row flex-nowrap minor-text align-items-center mt-4">
                    <div class="col-md-auto">
                        <span class="minor-text criteria-header">
                            {{ getTranslationByKey('ems.video_sources') }}:
                        </span>
                    </div>
                    <div class="col-md-10">
                        <div class="d-flex align-items-center gap">
                            <div
                                v-for="source in getGuidedAvailableCameras" :key="source.id"
                                class="form-check d-flex align-items-end"
                            >
                                <input
                                    type="checkbox"
                                    class="form-check-input custom-input"
                                    :value="source.id"
                                    :id="source.id"
                                    v-model="guidedValues.sourceValues"
                                />
                                <label
                                    class="form-check-label"
                                    :for="source.id"
                                >
                                    {{ source.name}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Audio sources -->
                <AudioSource
                    :microphones="getGuidedAvailableMicrophones"
                    :sourceValues="guidedValues.sourceValues"
                    v-model="guidedValues.micId"
                    v-if="getGuidedAvailableMicrophones.length > 0"
                />
            </div>
            <Transition name="fade-up">
                <div v-show="showChart">
                    <CameraChart
                        :selectedDate="guidedSelectedDate"
                        @guidedChartData="onGuidedChartSetValues"
                    ></CameraChart>
                    <div class="mt-3">
                        <button class="btn btn-primary btn-sm px-5" @click="setGuideValues" :disabled="isGuidedBtnDisabled">
                            <i class="fa fa-check mr-1 text-sm"></i>
                            <span>{{ getTranslationByKey('ems.action.ok') }}</span>
                        </button>
                    </div>
                </div>
            </Transition>
        </div>
        <!-- Blind -->
        <div v-if="isEMSBlind" class="py-2">
            <DatePicker
                show-weeknumbers="left-outside"
                @input="handleHoursDate"
                @dayclick="handleTillPeriod"
                :max-date="new Date()"
                :locale="user.language"
                v-model="blindValues.periodRange"
                is-range
                :first-day-of-week="2"
                class="shadow-sm"
            ></DatePicker>
            <div class="custom-time-picker">
                <CalendarRangeWarning 
                    :activeTimeSection="activeTimeSection" 
                    :firstDayClicked="firstDayClicked"
                />
                <div>
                    <date-picker mode="time"
                    is24hr v-model="blindTimeFrom"
                    :validHours="timeStartValidHours"
                    :locale="user.language"
                    />
                </div>
                <div>
                    <date-picker mode="time"
                    is24hr v-model="blindTimeTill"
                    :validHours="timeEndValidHours"
                    :locale="user.language"
                    />
                </div>
            </div>
            <!-- source -->
            <div class="row flex-nowrap minor-text align-items-center mt-4">
                <div class="col-md-auto">
                    <span class="minor-text criteria-header">
                        {{ getTranslationByKey('ems.video_sources') }}:
                    </span>
                </div>
                <div class="col-md-10">
                    <div class="d-flex align-items-center gap">
                        <div
                            v-for="source in sources" :key="source.id"
                            class="form-check d-flex align-items-end"
                        >
                            <input
                                type="checkbox"
                                class="form-check-input custom-input"
                                :value="source.value"
                                :id="source.value"
                                v-model="blindValues.sourceValues"
                            />
                            <label
                                class="form-check-label"
                                :for="source.value"
                            >
                                {{ getTranslationByKey(`ems.source_type.${source.value}`)}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Audio Sources -->
            <AudioSource
                :microphones="microphones"
                :sourceValues="blindValues.sourceValues"
                v-model="blindValues.micId"
            />
            <div class="mt-3">
                <button class="btn btn-primary btn-sm px-5" @click="setBlindValues" :disabled="isBlindBtnDisabled">
                    <i class="fa fa-check mr-1 text-sm"></i>
                    <span>{{ getTranslationByKey('ems.action.ok') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import CalendarRangeWarning from '../global/CalendarRangeWarning.vue';
import CameraChart from '../global/CameraChart.vue';
import AudioSource from './AudioSource.vue';
import StepProgress from 'vue-step-progress';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
    export default {
        props: {
            downloadType: {
                type: String // 1 = guided 2 = blind
            },
            index: {
                type: Number
            },
            videoList: {
                type: Array,
                default: undefined
            },
            collectedData: {
                type: Object
            }
        },
        components: {
            Calendar,
            DatePicker,
            CameraChart,
            CalendarRangeWarning,
            StepProgress,
            AudioSource
        },
        data() {
            return {//Days with dots Y-m-d
                guidedCalendarAttrs: [
                    {
                        key: 'special-dates',
                        dot: true,
                        dates: this.getDatesFromVideoList(this.getVideoLists),
                    }
                ],
                guidedSelectedDate: new Date(),
                guidedValues: {
                    index: this.index,
                    sourceValues: [],
                    micId: "",
                },

                blindTimeFrom: moment().startOf("day").toDate(),
                blindTimeTill: moment().toString(),
                blindValues: {
                    sourceValues: [],
                    index: this.index,
                    periodRange: {
                        start: moment().startOf("day").toDate(),
                        end: moment().toDate(),
                    },
                    downloadType: this.downloadType,
                    micId: "",
                },
                sources: [
                    {id: 1, value: 'front'},
                    {id: 2, value: 'rear'},
                    {id: 3, value: 'left'},
                    {id: 4, value: 'right'},
                    {id: 5, value: 'inner1'},
                    {id: 6, value: 'inner2'},
                ],
                microphones: ["mic1"],

                showChart: false,
                firstDayClicked: false,
                activeTimeSection: true
            }
        },
        computed: {
            ...mapGetters([
                'getTranslationByKey',
                'user',
                'getVideoLists',
                'getVideoListRetry',
                'getGuidedProgressCurrentStep',
                'getGuidedShowProgress',
                'getGuidedAvailableCameras',
                'getGuidedAvailableMicrophones',
                'user'
            ]),
            guidedProgressSteps() {
                return [
                    this.getTranslationByKey('ems.advanced_request_progress_steps.request_sent'),
                    this.getTranslationByKey('ems.advanced_request_progress_steps.request_delivered'),
                    this.getTranslationByKey('ems.advanced_request_progress_steps.data_received'),
                    this.getTranslationByKey('ems.advanced_request_progress_steps.data_processed'),
                ]
            },
            isEMSGuided() {
                return this.downloadType === '1';
            },
            isEMSBlind() {
                return this.downloadType === '2';
            },
            isBlindBtnDisabled() {
                return this.blindValues.sourceValues.length == 0;
            },
            isGuidedBtnDisabled() {
                return this.guidedValues.sourceValues && this.guidedValues.sourceValues.length == 0;
            },
            timeStartValidHours() {
                let isCurrentDate = moment().isSame(this.blindValues.periodRange.start, 'day')
                let obj;
                if (isCurrentDate) {
                    obj = {min: 0, max: moment().hour()}
                } else {
                    obj = {min: 0, max: 24}
                }
                return obj
            },
            timeEndValidHours() {
                let isCurrentDate = moment().isSame(this.blindValues.periodRange.end, 'day')
                let obj;
                if (isCurrentDate) {
                    obj = {min: 0, max: moment().hour()}
                } else {
                    obj = {min: 0, max: 24}
                }
                return obj
            },
            formattedStartDate() {
                return `${moment(this.blindValues.periodRange.start).format("DD-MM-YYYY")} ${moment(this.blindTimeFrom).format("HH:mm:ss")}`
            },
            formattedEndDate() {
                return `${moment(this.blindValues.periodRange.end).format("DD-MM-YYYY")} ${moment(this.blindTimeTill).format("HH:mm:ss")}`
            },
        },
        watch: {
            getVideoLists(updatedVideoList) {
                this.guidedCalendarAttrs[0].dates = this.getDatesFromVideoList(updatedVideoList)
            },
            "blindValues.periodRange"(newVale) {
                this.firstDayClicked = false;
                this.activeTimeSection = true;
            }
        },
        methods: {
            ...mapActions(['requestVideoList']),
            getDatesFromVideoList(list) {
                if (list === undefined) {
                  return [];
                }
               const availableDates = list.map(item => [...item.daysList])
                                    .flat()
                                    .filter(date => !isNaN(new Date(date).getTime()));
               return availableDates;
            },
            handleGuidedClickedDay(day) {
                this.guidedSelectedDate = day.date;
                this.showChart = true
            },
            setBlindValues() {
                const dateRange = `${this.formattedStartDate} - ${this.formattedEndDate}`;
                this.blindValues.name = dateRange;

                this.blindValues.periodRange.startTime = moment(this.blindTimeFrom);
                this.blindValues.periodRange.endTime = moment(this.blindTimeTill);
                this.blindValues.periodRange.start = this.blindValues.periodRange.start;
                this.blindValues.periodRange.end = this.blindValues.periodRange.end;

                this.blindValues.valueToTranslate = this.blindValues.sourceValues
                this.$emit('onSelectedBlind', this.blindValues);
            },
            onGuidedChartSetValues(payload) {
                const { minMaxDatetime } = payload;
                this.guidedValues.minMaxDatetime = minMaxDatetime;
                this.guidedValues.name = `${minMaxDatetime.start} - ${minMaxDatetime.end}`;
            },
            setGuideValues() {
                if (this.guidedValues.sourceValues && this.guidedValues.sourceValues.length > 0) {
                    this.guidedValues.valueToTranslate = this.guidedValues.sourceValues;
                } else {
                    return;
                }

                if (this.guidedValues.minMaxDatetime != undefined && this.guidedValues.sourceValues.length != 0) {
                    this.$emit('onSelectedGuide', this.guidedValues);
                }
            },
            handleHoursDate() {
                let isCurrentDate = moment().isSame(this.blindValues.periodRange.end, 'day')
                if (isCurrentDate) {
                    this.blindTimeTill = moment().format()
                } else {
                    this.blindTimeTill = moment(this.blindValues.periodRange.end).endOf('day').toDate()
                }
                this.blindTimeFrom = this.blindValues.periodRange.start
            },
            handleTillPeriod() {
                // show the message to inform user to select till
                this.firstDayClicked = true;
                this.activeTimeSection = false;
            },
            async videoListRetry() {

                await this.requestVideoList(this.collectedData.objectId);

            }
        }
    }
</script>

<style lang="scss" scoped>
    .gap {
        gap: 20px;
    } 
    .custom-input {
        bottom: 2px;
    }
</style>