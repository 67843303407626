<template>
    <div>
        <textarea cols="30" rows="10" 
            style="resize: none; width: 100%"
            class="border"
            v-model="descriptionText"
        >
            {{ descriptionText }}
        </textarea>
        <button class="btn btn-sm btn-primary px-5" 
            @click="addDescription" 
        >
            <i class="fa fa-check mr-1"></i>
            <span>{{ getTranslationByKey('ems.action.ok') }}</span>
        </button>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        props: {
            index: {
                type: Number
            }
        },
        data() {
            return {
                descriptionText: '',
            }
        },
        computed: {
            ...mapGetters(["getTranslationByKey"])
        },
        methods: {
            addDescription() {
                const data = {
                    index: this.index,
                    description: this.descriptionText
                };
               
                this.$emit('onDescriptionAdded', data); 
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>