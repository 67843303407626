<template>
  <div>
    <transition name="fade-up">
      <reports-criterias
        :criterias="neededCriterias"
        :reportName="getTranslationByKey('reports.side_menu.temperature.title')"
        :isLoading="loading"
        @collectedCriterias="getData"
        v-show="!hasDataArrived"
      >
      </reports-criterias>
    </transition>
    <transition name="report-fade">
      <div
        class="report-view-wrapper"
        :class="{ 'no-sidebar': reportFullscreen }"
        v-if="hasDataArrived"
      >
        <!-- action buttons -->
        <ReportViewActionBar 
          :noDataFound="noDataFound" 
          :reportFullscreen="reportFullscreen" 
          :excelDownloading="excelDownloading" 
          :pdfDownloading="pdfDownloading"
          :neededCriterisIcon="neededCriterias.icon"
          :reportTitle="getTranslationByKey('reports.side_menu.temperature.title')"
          @onToggleFullscreen="toggleReportFullscreen"
          @onPrintReport="printReport"
          @onDownloadExcel="downloadExcel"
          @onDownloadPDF="downloadPdf"
          @onReportClose="closeReport"
        />
        <div class="report-content" ref="printReportContent" :class="reportFullscreen ? 'full-screen': ''">
          <div v-if="!noDataFound">
            <div class="pdf-header">
              <span>
                {{
                  getTranslationByKey('reports.side_menu.temperature.title') +
                  " " +
                  (objects.length > 1 ? ", " : objects[0].name + ", ") +
                  todayDateForPrint
                }}
              </span>
              <span>SmartMonitor v{{ settings.version }}</span>
            </div>
            <h4 class="pdf-report-name">
              {{ getTranslationByKey('reports.side_menu.temperature.title') }}
            </h4>
            <div class="report-info">
              <div>
                <p class="company-name">{{ reportData.customer_name }}</p>
                <p class="report-period">{{ reportData.period }}</p>
              </div>
            </div>
            <!-- Table -->
            <template v-for="object in objects">
              <div
                class="table-container"
                v-for="(day, dayDate) in object.days"
                :key="object.name + dayDate"
              >
                <div class="object-info">
                  <p class="obj-name-model">
                    {{ object.name }} -
                    <span class="model-make">{{ object.make_model }}</span>
                  </p>
                  <h5 class="report-date">{{ day.date_formatted }}</h5>
                </div>
                <table class="reports-table shadow-sm">
                  <thead>
                    <tr>
                      <th>{{ getTranslationByKey('reports.table.time') }}</th>
                      <th>{{ getTranslationByKey('reports.table.sensor_0') }}(<sup>&#176;</sup>C)</th>
                      <th>{{ getTranslationByKey('reports.table.sensor_1') }}(<sup>&#176;</sup>C)</th>
                      <th>{{ getTranslationByKey('reports.table.sensor_2') }}(<sup>&#176;</sup>C)</th>
                      <th>{{ getTranslationByKey('reports.table.sensor_3') }}(<sup>&#176;</sup>C)</th>
                      <th>{{ getTranslationByKey('reports.table.sensor_4') }}(<sup>&#176;</sup>C)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in day.data" :key="index">
                      <td>{{ row.time }}</td>
                      <td>{{ row.temp0 }}</td>
                      <td>{{ row.temp1 }}</td>
                      <td>{{ row.temp2 }}</td>
                      <td>{{ row.temp3 }}</td>
                      <td>{{ row.temp4 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
          <div v-else class="no-data-found">
            <h3>
              <i class="fab fa-searchengin mr-1"></i
              >{{ getTranslationByKey('reports.table.no_data_found') }}
            </h3>
            <p class="text-muted font-weight-600 small">
              {{ getTranslationByKey('reports.table.other_obj') }}
            </p>
            <button class="btn btn-light" @click="hasDataArrived = false">
              <i class="fas fa-long-arrow-alt-left mr-1"></i
              >{{ getTranslationByKey('reports.table.back') }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportsCriterias from "./ReportsCriterias.vue";
import ReportViewActionBar from "./ReportViewActionBar.vue";
import moment from "moment";
import api from "../../api";
import { Printd } from "printd";
import { EventBus } from "../../bus";
import { css } from "./print-report-css";
import { mapGetters } from "vuex";

export default {
  components: {
    ReportsCriterias,
    ReportViewActionBar
  },
  data() {
    return {
      neededCriterias: {
        icon: "fas fa-temperature-high",
        period: true,
        objects: true,
        hideEmptyObj: true,
      },
      collectedData: null,
      loading: false,
      excelDownloading: false,
      pdfDownloading: false,
      reportData: [],
      objects: [],
      hasDataArrived: false,
      todayDateForPrint: moment().format("DD-MM-YYYY HH:mm:ss"),
      reportFullscreen: false,
    };
  },
  computed: {
    ...mapGetters(["settings", "getTranslationByKey"]),
    noDataFound() {
      return this.reportData.length == 0;
    },
  },
  created() {},
  methods: {
    getData(criterias) {
      this.collectedData = criterias;
      this.loading = true;
      api
        .get("report/temperature", {
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
          },
        })
        .then((response) => {
          this.reportData = response.data.data;
          this.objects = response.data.data.objects;
          this.loading = false;
          this.hasDataArrived = true;

          this.$audit({
            action: 'report_temperature',
            value: {
              from: this.collectedData.periodFrom,
              to: this.collectedData.periodTill,
              vehicle_ids: this.collectedData.vehiclesIds,
            }
          });
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          console.error(error);
        });
    },
    printReport() {
      const cssText = css;
      const d = new Printd();
      d.print(this.$refs.printReportContent, [cssText]);
    },
    downloadExcel() {
      this.excelDownloading = true;
      api
        .get("report/export-excel", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "temperature",
          },
        })
        .then((response) => {
          this.excelDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.objects.length > 1 ? '' : this.objects[0].name;
          link.download = `${this.getTranslationByKey('reports.side_menu.temperature.title')} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.xlsx`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error)
          this.excelDownloading = false;
          console.error(error);
        });
    },
    downloadPdf() {
      this.pdfDownloading = true;
      api
        .get("report/export-pdf", {
          responseType: "blob", // arraybuffer
          params: {
            from: this.collectedData.periodFrom,
            to: this.collectedData.periodTill,
            vehicle_ids: this.collectedData.vehiclesIds,
            report: "temperature",
          },
        })
        .then((response) => {
          this.pdfDownloading = false;
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const objectName = this.objects.length > 1 ? '' : this.objects[0].name;
          link.download = `${this.getTranslationByKey('reports.side_menu.temperature.title')} ${objectName} ${moment(this.collectedData.periodFrom, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")} - ${moment(this.collectedData.periodTill, 'DD-MM-YYYY HH:mm:ss')
              .format("YYYYMMDD HHmmss")}.pdf`;

          link.click();
          link.remove();
        })
        .catch((error) => {
          this.handleErrorMixin(error);
          this.pdfDownloading = false;
          console.error(error);
        });
    },
    closeReport() {
      this.hasDataArrived = false;
      this.reportFullscreen = false;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
    toggleReportFullscreen() {
      this.reportFullscreen = !this.reportFullscreen;
      EventBus.$emit("report-fullscreen", this.reportFullscreen);
    },
  },
};
</script>
