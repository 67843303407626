import api from "../../api";
import errorMixin from '../../mixins/handleErrorMixin';

export default {
    state: {
        firstLoad: true,
        vehicles: [],
        vehiclesLastData: [],
        vehiclesSubscriptionCount: 0,
        searchWord: [],
        filteredVehicles: null,
        sortAsc: true,
        loading: true,

        streams: [],
        streamPIP: [],
        pipX: 0,
        pipY: 0
    },
    actions: {
        async getVehicles(context) {
            let params = context.state.firstLoad ?  {with: 'location'} : '';
            try {
                const response = await api.get("objects", {params});
                await context.commit("update", { objects: response.data.data });
                // Update info for filtered vehicles
                if (context.state.searchWord != "") {
                    context.commit("filter", {
                        search: context.state.searchWord,
                        markers: context.getters.markers,
                    });
                }

                if (response.data.data.length) {
                    context.state.firstLoad = false;
                }
                return response.data.data;
            } catch (e) {
                errorMixin.methods.handleErrorMixin(e)
                context.dispatch('killSession')  
            } finally {
                context.state.loading = false;
            }
        },
        async getVehicleLocations(context, ids) {
            try {
                const response = await api.post("vehicle-locations", {
                    ids: ids
                });

                context.commit("updateLocations", response.data.data);
            } catch (e) {
                errorMixin.methods.handleErrorMixin(e)
            }
        },
        filter(context, search) {
            context.commit("filter", {
                search: search,
                markers: context.getters.markers,
            });
        },
        sort(context) {
            context.commit("sort", []);
        },
        WSStreamSubscription({ commit }, stream) {
            commit("setStream", stream);
            let pusher = this._vm.pusher.pusher;
            let chanel = pusher.subscribe(`private-stream.${stream.id}`);

            chanel.bind('player', stream => {
                commit("setStream", stream);
            })
        },
        WSStreamUnsubscribe({}, streamId) {
            let pusher = this._vm.pusher.pusher;
            pusher.unsubscribe(`private-stream.${streamId}`);
        },
        replaceStream({ commit, dispatch }, { oldStreamID, newStream }) {
            dispatch("WSStreamUnsubscribe", oldStreamID);
            commit("REPLACE_STREAM", newStream);
            dispatch("WSStreamSubscription", newStream);
        },
        WSObjectsSubscription({ commit }, ids) {
            let pusher = this._vm.pusher.pusher
            for (let vid of ids) {
                let chanel = pusher.subscribe(`private-unit.${vid}`)
                chanel.bind('data', data => {
                    commit('UPDATE_OBJ_UNIT', data)
                })
                chanel.bind('location', location => {
                    commit('UPDATE_OBJ_LOCATION',{location, vid})
                })
            }

            commit("SET_SUBSCRIPTIONS_COUNT", ids.length);
        },
        WSObjectsUnsubscribe({}, ids) {
            let pusher = this._vm.pusher.pusher;
            for (let vid of ids) {
                if (vid !== null) {
                    pusher.unsubscribe(`private-unit.${vid}`);
                }
            }
        }
    },
    mutations: {
        update(state, data) {
            let objectIdsForLocationRequest = [];
            let lastEl = 0;
            for (let vehicle of data.objects) {
                lastEl++
                const vehicleLastData = state.vehiclesLastData[vehicle.vid];
                let stateVehicle = state.vehicles.find((stateVehicle) => {
                    if (stateVehicle.vid == vehicle.vid) {
                        return stateVehicle;
                    }
                });
                vehicle.showCard = false;
                vehicle.traceButtonActive = false;
                vehicle.trackOnMainMap = false;
                vehicle.routeButtonActive = false;
                vehicle.historyButtonActive = false;
                vehicle.sendPosActive = false;

                if (stateVehicle && stateVehicle.showCard) {
                    vehicle.showCard = true;
                }
                // Trace button active status to be shared between object list and object card
                if (stateVehicle && stateVehicle.traceButtonActive) {
                    vehicle.traceButtonActive = true;
                }
                if (stateVehicle && stateVehicle.trackOnMainMap) {
                    vehicle.trackOnMainMap = true;
                }
                // Route button active status to be shared between object list and object card
                if (stateVehicle && stateVehicle.routeButtonActive) {
                    vehicle.routeButtonActive = true;
                }
                // History button active status to be shared between object list and object card
                if (stateVehicle && stateVehicle.historyButtonActive) {
                    vehicle.historyButtonActive = true;
                }

                if (vehicle.temperature0 && stateVehicle) {
                    vehicle.temperature0 = stateVehicle.temperature0;
                }
                if (vehicle.temperature && stateVehicle) {
                    vehicle.temperature = stateVehicle.temperature;
                }
                if (vehicle.seal && stateVehicle) {
                    vehicle.seal = stateVehicle.seal;
                }

                if ((!vehicle.location) && stateVehicle) {
                    vehicle.location = stateVehicle.location;
                }
                if (vehicleLastData) {
                    if (vehicleLastData["temp0"]) {
                        vehicle.temperature0 = vehicleLastData["temp0"];
                    }
                    for (let tempKey in vehicleLastData["temp"]) {
                        vehicle["temperature"][tempKey] = vehicleLastData["temp"][tempKey];
                    }
                    for (let sealKey in vehicleLastData["seal"]) {
                        vehicle["seal"][sealKey] = vehicleLastData["seal"][sealKey];
                    }
                }

                // add marker to the map
                // if first load after login/refresh or object not saved in store state
                if (state.firstLoad || !stateVehicle) {
                    this.dispatch("createMarker", {object: vehicle, isLast: lastEl === data.objects.length });
                }

                if (stateVehicle && (stateVehicle.lat !== vehicle.lat || stateVehicle.lon !== vehicle.lon)) {
                    objectIdsForLocationRequest.push(vehicle.vid)
                }
                
                // track object on main map
                if (vehicle.trackOnMainMap) {
                    this.dispatch('panTo', vehicle)
                }

            }

            if (!state.firstLoad && state.vehicles.length !== data.objects.length) {
                const difference = state.vehicles.filter(
                    ({ vid: id1 }) => !data.objects.some(({ vid: id2 }) => id2 === id1)
                );
                this.dispatch("removeMarkers", difference);
                this.dispatch("removeCluster");
                this.dispatch("addCluster", data.objects);
            }

            if (state.firstLoad) {
                this.dispatch("createCluster");
            }

            // TODO: find best practice
            this.commit("sort", data.objects);
            state.vehicles = data.objects;

            // this.dispatch("getVehicleLocations", objectIdsForLocationRequest);
        },
        UPDATE_OBJ_UNIT(state, unit) {
            let object = state.vehicles.find(stateVehicle => stateVehicle.vid === unit.vid)
            if (object) {
                unit.showCard = object.showCard ? unit.showCard = true : false 
                unit.traceButtonActive = object.traceButtonActive ? unit.traceButtonActive = true : false;
                unit.trackOnMainMap = object.trackOnMainMap ? object.trackOnMainMap = true : false;
                unit.routeButtonActive = object.routeButtonActive ? object.routeButtonActive = true : false 
                unit.historyButtonActive = object.historyButtonActive ? object.historyButtonActive = true : false
                unit.sendPosActive = false
                if (unit.trackOnMainMap) {
                    this.dispatch('panTo', unit)
                }

                if (object.priority_name !== unit.priority_name) {
                    this.dispatch('updateSingleMarkerLabel', unit);
                }
                
                this.dispatch("updateRoute", unit);
                this.dispatch("updateMarker", unit);
                this.dispatch("updateCluster");

                Object.assign(object, unit)
            }
        },
        UPDATE_OBJ_LOCATION(state, { location, vid }) {
            let object = state.vehicles.find(stateVehicle => stateVehicle.vid === vid)
            if (object) {
                object.location = location;
            }
        },
        SET_SUBSCRIPTIONS_COUNT(state, count) {
            state.vehiclesSubscriptionCount = count
        },
        updateLocations(state, data) {
            for (let objectId in data) {
                let object = state.vehicles.find((stateVehicle) => {
                    if (stateVehicle.vid == objectId) {
                        return stateVehicle;
                    }
                });

                if (object) {
                    object.location = data[objectId];
                }
            }
        },
        filter(state, params) {
            const settings = this.state.settings;
            const oldSearch = state.searchWord;
            let search = (state.searchWord = params.search);

            let objectsArray = [];
            if (!search || search === {} || search == "") {
                state.filteredVehicles = null;
                this.dispatch("addMarkers", state.vehicles);
                this.dispatch("addCluster", state.vehicles);
            } else {
                let data;
                let multiSearch = search.split(" ");
                for (let search of multiSearch) {
                    if (search !== "") {
                        let word = search.toString().toUpperCase();
                        data = state.vehicles.filter((object) => {
                            if (settings.sm_object_filter_by == 1) {
                                return object.reg_no.toUpperCase().includes(word)
                            } else if (settings.sm_object_filter_by == 2) {
                                return object.name.toUpperCase().includes(word)
                            } else {
                                return object.priority_name.toUpperCase().includes(word)
                            }
                        });
                        if (data.length) {
                            objectsArray = new Set([...objectsArray, ...data]);
                        }
                    }
                }

                let objects = Array.from(objectsArray);
                // TODO: need to fix online/offline bug
                if (oldSearch !== params.search) {
                    let markers = state.vehicles.filter((object) =>
                        objects.every((objectFilter) => object.vid !== objectFilter.vid)
                    );
                    this.dispatch("removeMarkers", markers);
                    this.dispatch("addMarkers", objects);
                    this.dispatch("removeCluster");
                    this.dispatch("addCluster", objects);
                }

                // TODO: find best practice
                this.commit("sort", objects);

                state.filteredVehicles = objects;
            }
        },
        sort(state, vehicles) {
            let sortVehicles = !vehicles.length ?
                state.filteredVehicles || state.vehicles :
                vehicles;

            if (!vehicles.length) {
                state.sortAsc = !state.sortAsc;
            }

            sortVehicles.sort((a, b) => {
                let nameA = a.priority_name.toUpperCase();
                let nameB = b.priority_name.toUpperCase();
                let sortState = state.sortAsc === false ? nameA > nameB : nameA < nameB;
                if (sortState) {
                    return -1;
                }
                if (!sortState) {
                    return 1;
                }
                return 0;
            });
        },
        setStream(state, stream) {
            let stateStream = state.streams.find(stateStream => stateStream.id === stream.id);
            if (stateStream) {
                Object.assign(stateStream, stream);
            } else {
                state.streams.push(stream);    
            }
        },
        REPLACE_STREAM(state, stream) {
            let streamFound = state.streams.find(stateStream => {
                return stateStream.source_type == stream.source_type && stateStream.title == stream.title;
            });

            if (streamFound) {
                Object.assign(streamFound, stream);
            }
        },
        resetStream(state) {
            state.streams = []
        },
        removeStream(state, id) {
            state.streams = state.streams.filter(item => item.id !== id);
            this.dispatch("WSStreamUnsubscribe", id);
        },
        setCustomPIP(state, pip) {
            pip.x = state.pipX
            pip.y = state.pipY
            state.streamPIP.push(pip)
            state.pipX += 100
            state.pipY += 100
        },
        removeSinglePIP(state, id) {
            state.streamPIP = state.streamPIP.filter(item => item.id !== id)
        },
    },
    getters: {
        all(state) {
            return state.vehicles;
        },
        filteredVehicles(state) {
            return state.filteredVehicles;
        },
        visible(state) {
            return state.filteredVehicles || state.vehicles;
        },
        loadingStatus(state) {
            return state.loading;
        },
        storeStream(state) {
            state.streams.forEach(stream => {
                if (localStorage.getItem(stream.id + "_isAudioMuted")) {
                    stream.is_audio_muted = JSON.parse(localStorage.getItem(stream.id + "_isAudioMuted"))
                } else {
                    localStorage.setItem(stream.id + "_isAudioMuted", true);
                }
            })
            return state.streams
        },
        streamPIP(state) {
            return  Array.from(new Set([...state.streamPIP]))   
        },
        getVehiclesIds(state) {
            return state.vehicles.map(vehicle => vehicle.vid)
        },
        getSubscriptionsCount(state) {
            return state.vehiclesSubscriptionCount
        }
    },
};