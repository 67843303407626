<template>
    <div 
        class="online-offline-connection" 
        :class="[show ? 'show': '', isUserOnline ? 'bg-success': '']"
    >
        {{ message }}
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    export default {
        data() {
            return {
                show: false,
                message: ''
            }
        },
        mounted() {
            this.WSCheckConnection();
        },
        computed: {
            ...mapGetters(['isUserOnline', 'getTranslationByKey']),
        },
        methods: {
            ...mapActions(['setUserOnlineStatus']),
            WSCheckConnection() {
                this.$pusher.connection.bind('state_change', (states) => {
                    if (states.current === "connecting") {
                        return;
                    }
                    if (states.current === 'connected') {
                        this.message = this.getTranslationByKey('navbar.online_offline.online');
                        this.setUserOnlineStatus(true);
                        setTimeout(() => { 
                            this.show = false;
                            this.message = ''; 
                        }, 3000);
                    } else {
                        this.message = this.getTranslationByKey('navbar.online_offline.offline');
                        this.setUserOnlineStatus(false);
                        this.show = true;
                    }
                })
            }
        }
    }
</script>
